import { Arabic } from './arabic';
import { Czech } from './czech';
import { German } from './german';
import { French } from './french';
import { Dutch } from './dutch';
import { English } from './english';
import { Italian } from './italian';
import { Japanese } from './japanese';
import { Portuguese } from './portuguese';
import { Polish } from './polish';
import { Romanian } from './romanian';
import { Danish } from './danish';
import { Finnish } from './finnish';
import { Norwegian } from './norwegian';
import { Swedish } from './swedish';


const languages = {};
languages.ar = Arabic;
languages.nl = Dutch;
languages.cs = Czech;
languages.en = English;
languages.it = Italian;
languages.de = German;
languages.fr = French;
languages.jp = Japanese;
languages.pt = Portuguese;
languages.pl = Polish;
languages.ro = Romanian;
languages.dk = Danish;
languages.fi = Finnish;
languages.no = Norwegian;
languages.se = Swedish;

const I18N = {
    getBrowserLanguageWithFallbackTo(fallback) {
        // get language from browser (needs testing of edge cases)
        // dutch if no fallback specified
        const locale = window.navigator.userLanguage || window.navigator.language || fallback || 'nl';
        // locale found?
        if (languages[locale]) {
            return languages[locale];
        }
        // dialect?
        const dialect = locale.slice(0, 2);
        if (languages[dialect]) return languages[dialect];
        // fallback
        return languages[fallback];
    },
    setLanguage(language) {
        return languages[language];
    },
    getLanguages() {
        return languages;
    }
};

export { I18N };
