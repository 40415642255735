const Storage = {
    storageType: 'localStorage',
    use(type) {
        this.storageType = type;
    },
    getStorage(minutes) {
        try {
            if (this.storageType in window
                && window[this.storageType] !== null
                && window[this.storageType].setItem('teststorage', 'test succeeded') === undefined
                && window[this.storageType].getItem('teststorage') === 'test succeeded'
                && window[this.storageType].removeItem('teststorage') === undefined
            ) {
                return window[this.storageType];
            }
        } catch (e) {
            // not allowed to access window.localStorage, use cookie fallback
        }
        console.log('access to localStorage denied. using cookies'); //eslint-disable-line
        return {
            setItem(key, item) {
                try {
                    let expires = '';
                    if (minutes) {
                        const date = new Date();
                        date.setTime(date.getTime() + Math.round(minutes * 60 * 1000));
                        expires = `; expires=${date.toUTCString()}`;
                    }
                    document.cookie = `${key}=${item || ''}${expires}; path=/`;
                } catch (e) {
                    // no access to cookies either, do nothing
                }
            },
            getItem(name) {
                try {
                    const nameEQ = `${name}=`;
                    const ca = document.cookie.split(';');
                    for (let i = 0; i < ca.length; i++) { // eslint-disable-line
                        let c = ca[i];
                        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
                    }
                } catch (e) {
                    // no access to cookies either, do nothing
                }
                return null;
            },
            removeItem(name) {
                try {
                    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
                } catch (e) {
                    // no access to cookies either, do nothing
                }
            }
        };
    },
    set(name, value, minutes) {
        const myStorage = this.getStorage(minutes);
        const storageObject = { name, value };
        if (minutes) {
            const date = new Date();
            date.setTime(date.getTime() + Math.round(minutes * 60 * 1000));
            storageObject.expires = date.getTime();
        }
        try {
            myStorage.setItem(name, JSON.stringify(storageObject));
        } catch (e) {
            // The quota has been exceeded.
            console.log('problem while trying to store some local storage data: ', e); //eslint-disable-line
        }
    },
    get(name) {
        const myStorage = this.getStorage();
        const storageValue = myStorage.getItem(name);
        if (storageValue) {
            let data;
            try {
                data = JSON.parse(storageValue);
            } catch (e) {
                // corrupted, remove and leave
                myStorage.removeItem(name);
                return null;
            }
            // no expires mean it will not expire by adding 1000 ms to now.
            let expDate = parseInt(data.expires || new Date().getTime() + 1000, 10);
            // to fix old string dates like '10 Dec 2019 EST....', will expire to fix them.
            if (Number.isNaN(expDate)) expDate = new Date().getTime() - 1000;
            const now = new Date().getTime();
            // compare the expiry time of the item with the current time
            if (now > expDate) {
                // If the item is expired, delete the item from storage
                // and return null
                myStorage.removeItem(name);
                return null;
            }
            return data.value;
        }
        return null;
    },
    delete(name) {
        const myStorage = this.getStorage();
        myStorage.removeItem(name);
    }
};
export { Storage };
