/*global TARGET_DOMAIN*/
import get from "lodash/get";
import { ErrorHandler, Helpers } from "../helpers";
import OpenByHandler from "./openby-handler";
import { DEFAULTCOOKIELIVETIME, createElementFromHTML, log } from "./utils";
import SettingsHandler from "./settings-handler";
import MessageHandler from "./message-handler";
import ShoutoutHandler from "./shoutout-handler";
import AnalyticsHandler from "./analytics-handler";

const DEFAULTOPENAFTER = 30; // 30 seconds
let oldHistoryPushState;
let lastSPAUrl = document.location.href;

class DialogHandler {
    // and some important dom nodes that we will work with later
    static get frame() { return DialogHandler.getWebmessengerFrameDocument(); }
    static get header() { return DialogHandler.getElement('header'); }
    static get footer() { return DialogHandler.getElement('footer'); }
    static get wrapper() { return DialogHandler.getElement('wrapper'); }
    static get container() { return DialogHandler.getElement('container'); }
    static get conversation() { return DialogHandler.getElement('conversation'); }
    static get button() { return DialogHandler.getElement('messenger-button'); }

    static getElement(elementId) {
        const { frame } = DialogHandler;
        if (frame) {
            return frame.getElementById(elementId);
        }
        return null;
    }
    // get the web-messenger-container that holds the widget
    static getWebmessengerFrame() {
        return document.getElementById('web-messenger-container');
    }

    // get the frame document that holds the widget
    static getWebmessengerFrameDocument() {
        const frame = DialogHandler.getWebmessengerFrame();
        if (frame) {
            return frame.contentDocument;
        }
        return null;
    }

    // hide widget animation done, remove event and hide
    static aniCShideDone() {
        const fr = DialogHandler.getWebmessengerFrame();
        fr.removeEventListener('animationend', DialogHandler.aniCShideDone);
        Helpers.removeClass(fr, 'animated fadeOutDown');
        Helpers.addClass(fr, `${TARGET_DOMAIN}_hide`);
        ShoutoutHandler.closeShoutout();
    }

    // remove class that hides the chatwindow until we are ready
    static hideWidget(animated = false) {
        if (animated) {
            const fr = DialogHandler.getWebmessengerFrame();
            fr.addEventListener('animationend', DialogHandler.aniCShideDone);
            Helpers.addClass(fr, 'animated fadeOutDown');
        } else {
            DialogHandler.aniCShideDone();
        }
    }

    // show the widget after loading. this is a special hiding that does not
    // hide the widget with display block, but inside the iframe the widget div is hidden
    // this way all resize actions and animations stay ok while we make changes to the wrapper
    static showWidgetAfterLoading() {
        const widget = DialogHandler.getWidget();
        Helpers.removeClass(widget, `${TARGET_DOMAIN}_hide_while_loading`);
    }

    // show widget animation done
    static aniCSshowDone() {
        const fr = DialogHandler.getWebmessengerFrame();
        const widget = DialogHandler.getWidget();
        fr.removeEventListener('animationend', DialogHandler.aniCSshowDone);
        Helpers.removeClass(fr, 'animated fadeInUp');
        Helpers.removeClass(fr, `${TARGET_DOMAIN}_hide`);
        const fullscreen = SettingsHandler.getSetting('fullscreen');
        if (widget && fullscreen) {
            SettingsHandler.sunshine.open({ fullscreen: true });
            Helpers.removeClass(fr, `${TARGET_DOMAIN}_close`);
            Helpers.addClass(fr, 'fullscreen');
            Helpers.removeClass(widget, 'widget-sm');
            Helpers.removeClass(widget, 'widget-md');
            Helpers.removeClass(widget, 'widget-lg');
            Helpers.addClass(widget, 'widget-xs');
        }
        if (SettingsHandler.getSetting('shoutout') && SettingsHandler.Platform.platform() !== 'mobile' && !SettingsHandler.getSetting('embedded')) {
            // give close animation time to close
            setTimeout(ShoutoutHandler.openShoutout, 1000);
        }
    }

    // remove class that hides the chatwindow until we are ready
    static showWidget(animated = true) {
        log('showWidget', animated);
        if (animated) {
            const fr = DialogHandler.getWebmessengerFrame();
            fr.addEventListener('animationend', this.aniCSshowDone);
            Helpers.removeClass(fr, `${TARGET_DOMAIN}_hide`);
            Helpers.addClass(fr, 'animated fadeInUp');
        } else {
            DialogHandler.aniCSshowDone();
        }
    }

    // set the chosen client language on the widget-md, so we can use the correct css content strings
    static setLanguageOnWidget() {
        const widget = DialogHandler.getWidget();
        if (widget && SettingsHandler.language && SettingsHandler.language._language) {
            Helpers.addClass(widget, SettingsHandler.language._language);
        }
    }

    // fullscreen embedded has a textarea with rows=5
    // this should be fixed
    static fixEmbeddedStartScreen() {
        const textarea = Array.csFrom(DialogHandler.footer.getElementsByTagName('textarea'))[0];
        log('textarea', textarea);
        if (textarea && textarea.hasAttribute('disabled') && textarea.getAttribute('rows') === '5') {
            // fix rows
            textarea.setAttribute('rows', '1');
        }
    }

    // add disclaimer
    static addDisclaimerIfConfigured() {
        const disclaimerUrl = SettingsHandler.getSetting('disclaimerUrl');
        const disclaimerText = SettingsHandler.getSetting('disclaimerText');
        if (disclaimerText && disclaimerUrl) {
            const logo = DialogHandler.conversation.getElementsByClassName('logo')[0];
            const a = logo.getElementsByTagName('a')[0];
            const clone = a.cloneNode(true);
            const span = clone.getElementsByTagName('span')[0];
            if (span) span.innerHTML = disclaimerText;
            const link = disclaimerUrl;
            const linkWithLanguage = link.replace('{language}', SettingsHandler.language._language_short);
            clone.setAttribute('href', linkWithLanguage);
            Helpers.removeClass(clone, 'by');
            Helpers.addClass(clone, 'disclaimer');
            if (logo) {
                logo.appendChild(createElementFromHTML('<br/>'));
                logo.appendChild(clone);
            }
        }
    }

    // add a link to web1on1 from logo
    static addLinkToLogo() {
        const logo = DialogHandler.conversation.getElementsByClassName('logo')[0];
        if (logo) {
            const a = logo.getElementsByTagName('a')[0];
            const img = logo.getElementsByTagName('img')[0];
            const poweredByText = SettingsHandler.getSetting('poweredByText', `Messaging by ${TARGET_DOMAIN === 'web1on1' ? 'Web1on1' : 'Chatshipper'}`);
            const poweredByUrl = SettingsHandler.getSetting(
                'poweredByUrl',
                `https://${TARGET_DOMAIN === 'web1on1' ? 'web1on1.chat' : 'chatshipper.com'}/`
            );
            const localizedPoweredByUrl = poweredByUrl
                ? poweredByUrl.replace('{language}', SettingsHandler.language._language_short) : `https://${TARGET_DOMAIN === 'web1on1' ? 'web1on1.chat' : 'chatshipper.com'}/`;
            // remove sunshine img
            if (img) img.parentNode.removeChild(img);
            // add correct Text
            if (a) {
                a.appendChild(createElementFromHTML(`<span>${poweredByText}</span>`));
            }
            // set class o a for styling
            if (a) {
                Helpers.addClass(a, 'by');
                // and correct link
                a.setAttribute('href', localizedPoweredByUrl);
            }
        }
    }

    static emitOpenWidget() {
        const event = new CustomEvent('openWidget', window[TARGET_DOMAIN]);
        window.dispatchEvent(event);
    }

    static openWidget(opts = {}, e) {
        log('openWidget: %j', opts);
        const isClosedByCustomer = !!SettingsHandler.Storage.get('widget_closed_by_customer');
        if (SettingsHandler.getSetting('hide') || (SettingsHandler.getSetting('rehide') && isClosedByCustomer)) {
            if (SettingsHandler.getSetting('rehide') && isClosedByCustomer) {
                SettingsHandler.Storage.delete('widget_closed_by_customer');
            }
            log('openWidget: show it first');
            DialogHandler.showWidget();
        }
        if (!SettingsHandler.getSetting('embedded')) {
            if (SettingsHandler.sunshine && SettingsHandler.sunshine.open && typeof SettingsHandler.sunshine.open === 'function') {
                SettingsHandler.sunshine.open();
            }
        }
        if (opts.preventDefault && e && typeof e.preventDefault === 'function') {
            e.preventDefault();
            e.stopPropagation();
        }
        MessageHandler.checkBotStartSettings();
        if (opts.domNode) MessageHandler.sendMessagesFromDataAttributes(opts.domNode);

        if (SettingsHandler.analyticsEnabled && opts.label && !SettingsHandler.getSetting('embedded')) {
            const { label, oncePerSession = false } = opts;
            const rest = Object.csAssign({}, opts);
            if (rest.label) delete rest.label;
            if (rest.oncePerSession) delete rest.oncePerSession;
            if (rest.domNode) delete rest.domNode;
            AnalyticsHandler.feedbackAnalytics(label, rest, oncePerSession);
        }
    }

    // get widget element in all layouts xs, sm, md and lg
    static getWidget() {
        if (DialogHandler && DialogHandler.frame) {
            const widget = DialogHandler.frame.getElementsByClassName('widget-embedded')[0]
                || DialogHandler.frame.getElementsByClassName('widget-xs')[0]
                || DialogHandler.frame.getElementsByClassName('widget-sm')[0]
                || DialogHandler.frame.getElementsByClassName('widget-md')[0]
                || DialogHandler.frame.getElementsByClassName('widget-lg')[0];
            return widget;
        }
        return null;
    }

    static checkOpenState() {
        log('checkOpenState');
        let openAfter = SettingsHandler.getSetting('openAfter', DEFAULTOPENAFTER);
        if (typeof openAfter === 'string') {
            openAfter = parseInt(openAfter, 10);
        }
        // openAfter set to null or false disables. 0 will immidiately open,
        // not defining it means use DEFAULTOPENAFTER
        if (openAfter !== -1 && openAfter !== null && openAfter !== false && openAfter !== 'false') {
            DialogHandler.openAfterIfNoConversationYet(openAfter, SettingsHandler.language.agentWelkomMessage);
        }
        // on desktop we always show shoutout if true

        if (SettingsHandler.getSetting('shoutout') && SettingsHandler.Platform.platform() !== 'mobile' && !SettingsHandler.getSetting('embedded')) {
            setTimeout(ShoutoutHandler.openShoutout, 1000);
        }

        // if customer opened the widget and goes to other page, widget should stay open
        const isOpenedByCustomer = !!SettingsHandler.Storage.get('widget_opened_by_customer');
        const openedCookie = SettingsHandler.Storage.get('widget_auto_opened');
        const isClosedByCustomer = !!SettingsHandler.Storage.get('widget_closed_by_customer');
        const isOpenedByButton = !!SettingsHandler.Storage.get('widget_opened_by_id')
            || !!SettingsHandler.Storage.get('widget_opened_by_class');
        if ((isOpenedByCustomer || openedCookie || isOpenedByButton) && !isClosedByCustomer) {
            if (SettingsHandler.getSetting('spa') && SettingsHandler.getSetting('hide')) {
                log('checkOpenState spa and hide');
                return;
            }
            if (openedCookie && SettingsHandler.getSetting('hide')) {
                log('checkOpenState openedCookie and hide');
                return;
            }
            log('checkOpenState openWidget');
            DialogHandler.openWidget();
        }
    }

    // open the chatwindow after amount seconds and show agent message if provided
    static openAfterIfNoConversationYet(amount, message) {
        log('openAfterIfNoConversationYet', amount, message);
        const widgetLoaded = SettingsHandler.Storage.get('widget_loaded');
        let openedCookie = SettingsHandler.Storage.get('widget_auto_opened');
        let isClosedByCustomer = !!SettingsHandler.Storage.get('widget_closed_by_customer');
        let isOpenedByCustomer = !!SettingsHandler.Storage.get('widget_opened_by_customer');
        let isOpenedByButton = !!SettingsHandler.Storage.get('widget_opened_by_id')
            || !!SettingsHandler.Storage.get('widget_opened_by_class');
        const date = new Date();
        let timePassed = 0;
        if (widgetLoaded) {
            timePassed = (date - (widgetLoaded || 0)) / 1000; // seconds passed
        }
        log('openAfterIfNoConversationYet date: %s, widgetLoaded: %s', date, widgetLoaded);
        log('openAfterIfNoConversationYet timePassed: %s, waiting: %s', timePassed, (amount - timePassed) * 1000);
        if (SettingsHandler.sunshine && SettingsHandler.sunshine.isOpened && !DialogHandler.conversationStarted()
            && !isOpenedByCustomer && !isOpenedByButton && !isClosedByCustomer && !openedCookie) {
            if (SettingsHandler.sunshine && typeof SettingsHandler.sunshine.isOpened === 'function' && !SettingsHandler.sunshine.isOpened()) {
                log('openAfterIfNoConversationYet: settings timer to %s', (amount - timePassed) * 1000);
                setTimeout(() => {
                    try {
                        log('openAfterIfNoConversationYet: time passed...', amount, message);
                        if (SettingsHandler.getSetting('spa') && SettingsHandler.getSetting('hide')) {
                            log('spa and hide true. not opening widget');
                            return;
                        }
                        openedCookie = SettingsHandler.Storage.get('widget_auto_opened');
                        isClosedByCustomer = !!SettingsHandler.Storage.get('widget_closed_by_customer');
                        isOpenedByCustomer = !!SettingsHandler.Storage.get('widget_opened_by_customer');
                        isOpenedByButton = !!SettingsHandler.Storage.get('widget_opened_by_id')
                            || !!SettingsHandler.Storage.get('widget_opened_by_class');
                        if (SettingsHandler.sunshine && typeof SettingsHandler.sunshine.isOpened === 'function'
                            && !SettingsHandler.sunshine.isOpened() && !isOpenedByCustomer
                            && !isOpenedByButton && !isClosedByCustomer && !openedCookie) {
                            if (SettingsHandler.Platform.platform() === 'mobile') {
                                ShoutoutHandler.openShoutout();
                                if (SettingsHandler.analyticsEnabled) AnalyticsHandler.feedbackAnalytics('widget_shoutout_opened', {}, true);
                            } else {
                                DialogHandler.openWidget({
                                    label: 'widget_auto_opened',
                                    after: amount * 1000,
                                    oncePerSession: true
                                });
                                if (typeof (message) !== 'undefined'
                                    && message !== ''
                                    && !DialogHandler.conversationStarted()
                                    && !SettingsHandler.getSetting('prechatCapture')) {
                                    MessageHandler.showFakeAgentMessage(message);
                                }
                            }
                        }
                    } catch (e) {
                        ErrorHandler.handleError(e);
                    }
                }, (amount - timePassed) * 1000);
            }
        }
    }

    // check if we are already in a conversation with the client
    static conversationStarted() {
        if (SettingsHandler.sunshine.getConversation) {
            const messages = Array.csFrom(SettingsHandler.sunshine.getConversation().messages);
            if (messages.length > 0) {
                const now = Math.round(new Date().getTime() / 1000 / 60);
                // Last message younger then cookie live time
                if (now - messages[messages.length - 1] < DEFAULTCOOKIELIVETIME) {
                    return true;
                }
            }
        }
        return false;
    }

    static fixConnectLink(listener) {
        setTimeout(() => {
            const linking = DialogHandler.frame.getElementsByClassName('channel-visible')[0];
            if (linking) {
                const connect = linking.getElementsByTagName('a')[0];
                if (connect) {
                    connect.setAttribute('href', connect.getAttribute('href').split('?')[0]);
                    listener.removeEventListener('click', DialogHandler.fixConnectLink);
                } else {
                    DialogHandler.fixConnectLink(listener);
                }
            } else {
                DialogHandler.fixConnectLink(listener);
            }
        }, 100);
    }

    static patchChannelConnectLink() {
        const waIcon = DialogHandler.frame.getElementById('whatsapp');
        if (waIcon) {
            waIcon.addEventListener('click', DialogHandler.fixConnectLink.bind(this, waIcon));
        }
        const messengerIcon = DialogHandler.frame.getElementById('messenger');
        if (messengerIcon) {
            messengerIcon.addEventListener('click', DialogHandler.fixConnectLink.bind(this, messengerIcon));
        }
    }

    static addCustomerDefinedTriggers() {
        const notTriggeredYet = Array.csFrom(document
            .querySelectorAll(`[data-${TARGET_DOMAIN}-open],[data-${TARGET_DOMAIN}-bot],[data-${TARGET_DOMAIN}-agent-text],`
                + `[data-${TARGET_DOMAIN}-agent-name],[data-${TARGET_DOMAIN}-client-text]`))
            .filter(el => el.getAttribute(`data-${TARGET_DOMAIN}-patched`) !== 'true');
        // all that are not yet trigger are set hard in html (by customer), lets patch those too
        notTriggeredYet.forEach(el => {
            OpenByHandler.addOpenByClickEvent(
                el,
                el.getAttribute(`data-${TARGET_DOMAIN}-preventdefault`) === 'true',
                Array.csFrom(['true', true]).includes(el.getAttribute(`data-${TARGET_DOMAIN}-startbot`))
            );
        });
    }

    static destroy() {
        log('destroy');
        if (typeof oldHistoryPushState === 'function') {
            window.history.pushState = oldHistoryPushState;
        }
        // remove click events
        OpenByHandler.removeOpenByIdEvents();
        OpenByHandler.removeOpenByClassEvents();
        // destroy events
        if (window[TARGET_DOMAIN].api) {
            const event = new CustomEvent('destroyWidget', window[TARGET_DOMAIN]);
            window.dispatchEvent(event);
            if (get(window, `${TARGET_DOMAIN}.api.feedbackAnalytics`)
                && get(window, `${TARGET_DOMAIN}.settings.url`)
                && window[TARGET_DOMAIN].settings.url
                    // eslint-disable-next-line no-useless-escape
                    .match(new RegExp(`${TARGET_DOMAIN === 'web1on1' ? 'web1on1\.chat' : 'chatshipper\.com'}\/organizations`))) {
                log('destroy: remove event listener');
                const leaveEvent = new CustomEvent('destroyWidgetLeave', window[TARGET_DOMAIN]);
                window.dispatchEvent(leaveEvent);
            }
        }
        // destroy the sunshine widget
        if (window[`${TARGET_DOMAIN}Loader`]) {
            try {
                window[`${TARGET_DOMAIN}Loader`].destroy();
            } catch (e) {
                // dont care if that fails
            }
            delete window[TARGET_DOMAIN].initPromise;
            delete window[TARGET_DOMAIN].settings;
        }
    }

    /*
     * Single Page Application do not refresh the page when switching to a different path
     * we need to check changes to the path and reload the widget ourselves
     */
    static checkSPAPageChange() {
        log('checkSPAPageChange');
        if (lastSPAUrl !== document.location.href) {
            log('checkSPAPageChange: page changed');
            lastSPAUrl = window.document.href;

            // remember open state
            const fr = DialogHandler.getWebmessengerFrame();
            const wasOpen = Helpers.hasClass(fr, `${TARGET_DOMAIN}_appear`);
            const wasHidden = Helpers.hasClass(fr, `${TARGET_DOMAIN}_hide`);
            // remerge the settings knowing the new url
            const newMergedSettings = SettingsHandler.mergeSettings( // eslint-disable-line
                SettingsHandler.spaConfig.baseConfig,
                SettingsHandler.spaConfig.appConfig,
                SettingsHandler.spaConfig.initConfig,
                Object.csAssign({}, SettingsHandler.spaConfig.urlAndPlatform, { url: document.location.href }), // new url
                SettingsHandler.spaConfig.testOptions
            );
            // act on it
            SettingsHandler.spaConfig.merged = newMergedSettings;
            window[TARGET_DOMAIN].settings = newMergedSettings;
            SettingsHandler.updateSettings(newMergedSettings);
            // remove old button click events
            OpenByHandler.removeOpenByIdEvents();
            OpenByHandler.removeOpenByClassEvents();
            // add new click events
            OpenByHandler.addOpenByIdEvents();
            OpenByHandler.addOpenByClassEvents();
            DialogHandler.addCustomerDefinedTriggers();
            // removeAllCookies();
            AnalyticsHandler.checkAnalytics();
            DialogHandler.checkOpenState();
            // reset widget state
            log('settings', SettingsHandler.settings, SettingsHandler.spaConfig);
            log('checkSPAPageChange', SettingsHandler.getSetting('hide'), wasOpen, wasHidden);
            if (SettingsHandler.getSetting('hide') && (wasOpen || !wasHidden)) {
                log('checkSPAPageChange: hide widget');
                DialogHandler.hideWidget();
                ShoutoutHandler.closeShoutout();
            } else if (!(SettingsHandler.getSetting('hide')) && (!wasOpen || wasHidden)) {
                log('checkSPAPageChange: show widget');
                DialogHandler.showWidget();
            }
        }
    }

    static enableSPAWidgetReloading() {
        log('widget spa active, binding pushState if possible');
        if (window.history && window.history.pushState && typeof window.history.pushState === 'function') {
            oldHistoryPushState = window.history.pushState.bind(window.history);
        } else {
            log('widget spa active, binding pushState failed');
        }
        // pressing back or forward in browser triggers popstate
        window.addEventListener('popstate', () => {
            setTimeout(DialogHandler.checkSPAPageChange, 0);
        });
        // any normal link triggers history.pushState
        window.history.pushState = (...args) => {
            if (oldHistoryPushState) oldHistoryPushState(...args);
            DialogHandler.checkSPAPageChange();
        };
    }
}

export default DialogHandler;
