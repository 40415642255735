/* eslint max-len:0 */
const Danish = {
    _language: 'danish',
    _language_short: 'da',
    agentWelkomMessage: 'Velkommen. Stil dine spørgsmål her.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Kunne ikke oprette forbindelse. Du kan {retry}',
    couldNotConnectInner: 'prøv at oprette forbindelse igen nu',
    couldNotConnectWithRetry: 'Kunne ikke oprette forbindelse. Vi fortsætter med gentagne forsøg, eller så kan du {retry}.',
    couldNotConnectWithRetryInner: 'prøv nu',
    headerText: 'Hvordan kan vi hjælpe dig?',
    imageClickToReload: 'Klik for at genindlæse billedet.',
    imageClickToView: 'Klik for at se billede {size}.',
    imagePreviewNotAvailable: 'Forhåndsvisning er ikke tilgængelig.',
    inputPlaceholder: 'Skriv her og tryk på <enter> for at sende.',
    inputPlaceholderBlocked: 'Udfyld formularen nedenfor...',
    introAppText: 'Send din besked nedenfor eller fra din foretrukne kanal.',
    introductionText: 'Stil dine spørgsmål her.',
    actionPaymentCompleted: 'Betaling modtaget.',
    actionPaymentError: 'Der opstod en fejl under behandling af betalingen. <br> Prøv igen eller brug et andet kort.',
    actionPostbackError: 'Der opstod en fejl under behandlingen af din handling. Prøv igen.',
    clickToRetry: 'Beskeden kunne ikke leveres. Prøv igen.',
    clickToRetryForm: 'Formularen blev ikke sendt. Klik hvor som helst på formularen for at prøve igen.',
    connectNotificationText: 'Vent på svar eller vælg, hvilken app du vil fortsætte med.',
    connectNotificationSingleText: 'Vent på svar eller vælg, hvilken app du vil fortsætte med.',
    connectNotificationSingleButtonText: 'Aktivér meddelelser fra <name>',
    connectNotificationOthersText: 'andre',
    emailChangeAddress: 'Ændr min e-mailadresse',
    emailDescription: 'For at modtage e-mailnotifikationer om svar skal du indtaste din e-mailadresse.',
    emailFieldLabel: 'Din e-mailadresse',
    emailFieldPlaceholder: 'Din e-mailadresse',
    emailFormButton: 'Fortsæt',
    fetchHistory: 'Hent mere',
    fetchingHistory: 'Indlæser historik...',
    frontendEmailChannelDescription: 'Send os en e-mail, og vi svarer så hurtigt som muligt.',
    invalidFileError: 'Kun billeder understøttes. Vælg en fil med et understøttet format (jpg, jpeg, png, gif eller bmp).',
    lineChannelDescription: 'For at kommunikere med os skal du scanne QR-koden med LINE-appen.',
    locationNotSupported: 'Din browser understøtter ikke placeringstjenester eller de er slået fra. Indtast din placering manuelt.',
    locationSecurityRestriction: 'Denne hjemmeside har ikke adgang til din placering. Indtast din placering manuelt.',
    locationSendingFailed: 'Placering kunne ikke sendes',
    locationServicesDenied: 'Denne hjemmeside har ikke adgang til din placering. Tillad adgang i dine indstillinger eller indtast din placering manuelt.',
    messageError: 'Der opstod en fejl under afsendelse af din besked. Prøv igen.',
    messageIndicatorTitlePlural: '({count}) Nye beskeder',
    messageIndicatorTitleSingular: '({count}) Ny besked',
    messageRelativeTimeDay: 'For {value} dage siden',
    messageRelativeTimeHour: 'For {value} timer siden',
    messageRelativeTimeJustNow: 'Lige nu',
    messageRelativeTimeMinute: 'For {value} minutter siden',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Sender...',
    messageDelivered: 'Leveret',
    messengerChannelDescription: 'For at fortsætte med Facebook Messenger skal du tilslutte din konto til Facebook.',
    whatsappChannelDescriptionDesktop: 'Start eller fortsæt din samtale via WhatsApp. Klik på linket nedenfor.',
    whatsappChannelDescriptionMobile: 'Start eller fortsæt din samtale via WhatsApp. Klik på linket nedenfor.',
    whatsappLinkingError: 'Der opstod en fejl under tilslutningen. Prøv igen.',
    notificationSettingsChannelsDescription: 'Du kan også chatte fra din foretrukne app eller service.',
    notificationSettingsChannelsTitle: 'Andre apps',
    notificationSettingsConnected: 'Tilsluttet',
    notificationSettingsConnectedAs: 'Tilsluttet som {username}',
    prechatCaptureGreetingText: 'Hej 👋\nVi vil gerne vide lidt mere om dig for at komme i gang:',
    prechatCaptureNameLabel: 'Dit navn',
    prechatCaptureNamePlaceholder: 'Indtast dit navn...',
    prechatCaptureEmailLabel: 'E-mail',
    prechatCaptureEmailPlaceholder: 'name@company.com',
    prechatCaptureConfirmationText: 'Tak! Hvordan kan vi hjælpe dig?',
    prechatCaptureMailgunLinkingConfirmation: 'Du vil blive informeret her og via e-mail på {email}, når vi svarer. ',
    sendButtonText: 'Send',
    settingsHeaderText: 'Indstillinger',
    smsBadRequestError: 'Kunne ikke oprette forbindelse til dette nummer. Prøv igen eller brug et andet nummer. ',
    smsCancel: 'Annuller',
    smsChangeNumber: 'Ændr mit nummer',
    smsChannelDescription: 'For at fortsætte via SMS skal du tilslutte dit SMS-nummer.',
    smsChannelPendingDescription: 'Tjek dine beskeder på {number} for at bekræfte dit telefonnummer.',
    smsContinue: 'Fortsæt',
    smsInvalidNumberError: 'Dit telefonnummer er ugyldigt. Prøv igen.',
    smsLinkCancelled: 'Forbindelsen til {appUserNumber} blev annulleret.',
    smsLinkPending: 'Afventer',
    smsPingChannelError: 'Der opstod en fejl under afsendelse af besked til dit nummer.',
    smsSendText: 'Send mig en SMS',
    smsStartTexting: 'Begynd at skrive SMS',
    smsTooManyRequestsError: 'En anmodning om tilslutning til dette nummer blev for nylig foretaget. Prøv igen om {minutes} minutter.',
    smsTooManyRequestsOneMinuteError: 'En anmodning om tilslutning til dette nummer blev for nylig foretaget. Prøv igen om 1 minut.',
    smsUnhandledError: 'Noget gik galt. Prøv igen.',
    tapToRetry: 'Beskeden kunne ikke leveres. Tryk på enter for at prøve igen.',
    tapToRetryForm: 'Formularen blev ikke sendt. Tryk på formularen for at prøve igen.',
    telegramChannelDescription: 'For at fortsætte via Telegram skal du tilslutte din konto til Telegram.',
    unsupportedMessageType: 'Ikke understøttet beskedtype.',
    unsupportedActionType: 'Ikke understøttet aktionstype.',
    linkError: 'Der opstod en fejl under forsøget på at oprette et link til denne kanal. Prøv igen.',
    viberChannelDescription: 'For at fortsætte via Viber skal du tilslutte din Viber-konto.',
    viberChannelDescriptionMobile: 'For at fortsætte via Viber skal du tilslutte din Viber-konto, og du vil blive underrettet, når vi svarer. Installer Viber-appen og tryk på Tilslut.',
    viberQRCodeError: 'Der opstod en fejl under hentning af din Viber QR-kode. Prøv igen.',
    wechatChannelDescription: 'For at fortsætte via WeChat skal du tilslutte din WeChat-konto, og du vil blive underrettet, når vi svarer. Scan QR-koden med WeChat-appen for at komme i gang.',
    wechatChannelDescriptionMobile: 'For at fortsætte via WeChat skal du tilslutte din WeChat-konto, og du vil blive underrettet, når vi svarer. Gem QR-koden og upload den i <a href="weixin://dl/scan">QR-kodescanneren</a>.',
    wechatQRCodeError: 'Der opstod en fejl under hentning af din WeChat QR-kode. Prøv igen.',
    fileTooLargeError: 'Filen overskrider maksimal størrelsesgrænse ({size})',
    shareLocation: 'Del placering',
    uploadDocument: 'Upload dokument',
    uploadInvalidError: 'Ugyldig fil',
    fileTypeError: 'Ikke understøttet filtype',
    formErrorInvalidEmail: 'E-mailadressen er ugyldig',
    formErrorNoLongerThan: 'Må højst være ({characters}) tegn',
    formErrorNoShorterThan: 'Skal være mindst ({characters}) tegn',
    formErrorUnknown: 'Det ser ikke helt korrekt ud',
    formFieldSelectPlaceholderFallback: 'Vælg...',
    uploadPhoto: 'Upload foto',
    uploadVirusError: 'Der blev fundet en virus i din fil, og den blev afvist',
    shoutoutText: 'Start chat'
};
export { Danish };
