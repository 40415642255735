/*global TARGET_DOMAIN*/
const timers = []; // function delay timers
const TRIGGER = '>cs';
const DEFAULTCOOKIELIVETIME = 30; // 30 mins

// manage postponed function triggers
const after = (ms, func, ...rest) => {
    const name = func.name || (func.toString().match(/^function\s*([^\s(]+)/) || [])[1];
    if (name) {
        if (timers[name]) {
            clearTimeout(timers[name]);
        }
        timers[name] = setTimeout(func.bind(this, ...rest), ms);
    } else {
        func();
    }
};

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

// create html dom done from text
const createElementFromHTML = htmlString => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
};

const log = (message, par1, par2, par3, par4) => {
    if (window &&
        (!window[TARGET_DOMAIN] ||
            window[TARGET_DOMAIN].settings &&
            window[TARGET_DOMAIN].settings.log) &&
        console &&
        console.log && // eslint-disable-line
        typeof console.log === 'function' // eslint-disable-line
    ) {
        console.log(`-----------chat widget -------------->${message}`, par1, par2, par3, par4); // eslint-disable-line
    }
};

const isIE = () => {
    const ua = navigator.userAgent;
    // MSIE used to detect old browsers and Trident used to newer ones
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
};

// transform any JSON to css
const transformJSONToCSS = node => Object.keys(node)
    .map(key => {
        if (typeof node[key] === 'string') {
            return `${key.trim()}: ${node[key].trim()};`;
        }
        return `${key.trim()} {
        ${transformJSONToCSS(node[key]).trim()}
    }`;
    }).join('\n').replace(/^\s/g, '');

const hashFromDocumentUrl = () => {
    const input = document.location.href;
    let hash = 0;
    let i;
    let chr;
    for (i = 0; i < input.length; i += 1) {
        chr = input.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    return Math.abs(hash);
};

// find a function on the window object
const findFunctionOnWindow = (name, w) => {
    const splitted = name.split('.');
    const next = splitted.shift();
    if (next && w[next]) {
        if (splitted.length) {
            return findFunctionOnWindow(splitted.join('.'), w[next]);
        }
        return w[next];
    }
    return false;
};

// generate some pseudo random agents shifting each minute
const randomAgents = () => {
    // change new agents every minute
    const start = ((new Date()).getMinutes() % 39) + 1; // 1-39 as there are max 39 images of agents male and female
    let out = '';
    [1, 2, 3].forEach(c => {
        const offline = (c + 1) % 2 === 0;
        const startmale = (c + 1) % 2 === 0;
        out += `
            <div class="agent ${startmale ? 'male' : 'female'} smiling-${start + c}">
                <div class="status"><div class="${offline ? 'offline' : 'online'}"></div></div>
            </div>`;
        if (c !== 3) {
            out += `
                <div class="agent ${startmale ? 'female' : 'male'} smiling-${start + c}">
                    <div class="status"><div class="${offline ? 'offline' : 'online'}"></div></div>
                </div>`;
        }
    });
    return out;
};

export { after, createElementFromHTML, log, wait, isIE, transformJSONToCSS, hashFromDocumentUrl, findFunctionOnWindow, TRIGGER, randomAgents, DEFAULTCOOKIELIVETIME };
