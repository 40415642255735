/*global TARGET_DOMAIN*/
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import { DEFAULTCOOKIELIVETIME, log, wait } from './utils';
import { ErrorHandler, Storage } from '../helpers';
import { I18N } from '../languages';

// eslint-disable-next-line prefer-const
let settings = {};
let spaConfig = {};
let initConfig = {};
let sunshine;
let Platform;
const analyticsEnabled = true;
const DEFAULTLANGUAGE = 'en';
class SettingsHandler {

    static get settings() {
        return settings;
    }

    static get cookieLivetime() {
        return parseInt(SettingsHandler.getSetting('cookieLivetime'), 10) || DEFAULTCOOKIELIVETIME;
    }

    static loadCustomerScriptIfFound(initSettings = {}) {
        return new Promise(resolve => {
            try {
                if (typeof initSettings.customer === 'undefined' || typeof initSettings.src === 'undefined') {
                    resolve();
                } else {
                    log(`loading ${initSettings.src.replace('bundle', initSettings.customer)}`);
                    const head = document.getElementsByTagName('head')[0];
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = initSettings.src.replace('bundle', initSettings.customer);
                    script.onload = () => {
                        resolve(true);
                    };
                    script.onerror = () => {
                        resolve(false);
                    };
                    if (head) head.appendChild(script);
                }
            } catch (e) {
                log('error while loading customer script', e);
                resolve(false);
            }
        });
    }

    static async init({
        initConfig: baseInitConfig, Platform: BasePlatform
    }) {
        // getting both mcc base config and appid specific config from bucket
        initConfig = baseInitConfig;
        Platform = BasePlatform;
        let testOptions = {};
        if (window[`${TARGET_DOMAIN}TestOptions`]) {
            log('using test options', window[`${TARGET_DOMAIN}TestOptions`]);
            testOptions = window[`${TARGET_DOMAIN}TestOptions`];
        }
        const [baseConfig, globalConfig, appConfig] = await Promise.all([
            SettingsHandler.getAppSettings('base'),
            SettingsHandler.getAppSettings('global'),
            SettingsHandler.getAppSettings(initConfig.appId)
        ]);

        const mergedSettings = SettingsHandler.mergeSettings(
            baseConfig,
            appConfig,
            initConfig,
            { url: document.location.href, platform: Platform.platform() },
            testOptions,
            globalConfig
        );

        await wait(+(mergedSettings.initAfter || 100));

        // initAfter can be used to wait with loading/executing the script to improve website
        // performance. even with defer on the script https://developers.google.com/speed/pagespeed/insights
        // will show 250 kb being loaded by us (smoooch). When you set initAfter to 5000,
        // this disappears. consequence is that the widget is shows only after 5 seconds +loading

        if (mergedSettings.spa) {
            spaConfig = {
                baseConfig: Object.csAssign({}, baseConfig),
                appConfig: Object.csAssign({}, appConfig),
                initConfig: Object.csAssign({}, initConfig),
                urlAndPlatform: { platform: Platform.platform() },
                testOptions: Object.csAssign({}, testOptions),
                merged: Object.csAssign({}, mergedSettings)
            };
        }

        // you can disable error handling completely by setting this to true in base.json
        // you can disable error handling for certain appid by setting this to [ appid, appid, etc]
        if (mergedSettings.disableErrorHandling) ErrorHandler.disable(mergedSettings.disableErrorHandling);
        // you can enable error handling for certain appid by setting this to [ appid, appid, etc]
        // so combining by setting all to disabled and enable one is possible
        if (mergedSettings.enableErrorHandling) ErrorHandler.enable(mergedSettings.enableErrorHandling);
        if (
            mergedSettings.touchpoints
                                && mergedSettings.touchpoints.indexOf('mobile') === -1
                                && Platform.isOnMobile()
        ) {
            // touchpoints is used and mobile is not in it, so let get out of here
            return null;
        }

        const customerLoaded = await SettingsHandler.loadCustomerScriptIfFound(mergedSettings);

        if (customerLoaded) mergedSettings.customerLoaded = customerLoaded;
        if (SettingsHandler.getSettingsItem(mergedSettings, 'shoutout')
                                    && SettingsHandler.getSettingsItem(mergedSettings, 'displayStyle') !== 'button') {
            const oldHeaderText = SettingsHandler.getSettingsItem(mergedSettings, 'customText.headerText');
            const shoutoutText = SettingsHandler.getSettingsItem(mergedSettings, 'customText.shoutoutText');
            if (oldHeaderText && oldHeaderText !== '' && !SettingsHandler.getSettingsItem(mergedSettings, 'embedded')) {
                mergedSettings.customText.headerText = shoutoutText;
                mergedSettings.customText.shoutoutText = oldHeaderText;
            }
        }
        settings = mergedSettings;

        log('widget init promise start');
        const storageType = SettingsHandler.getSetting('browserStorage', 'localStorage');
        Storage.use(storageType);
        return null;
    }

    static updateSettings(initSettings) {
        settings = initSettings;
    }
    // local getsettings version
    static get getSetting() {
        return SettingsHandler.getSettingsItem;
    }

    static get language() {
        return settings.customText;
    }

    static get sunshine() {
        return sunshine;
    }

    static get spaConfig() {
        return spaConfig;
    }

    static get DEFAULTLANGUAGE() {
        return DEFAULTLANGUAGE;
    }

    static get analyticsEnabled() {
        return analyticsEnabled;
    }

    static get Platform() {
        return Platform;
    }

    static get Storage() {
        return Storage;
    }

    static get orientation() {
        // orientation
        return SettingsHandler.getSetting('orientation', 'bottom right');
    }

    static setSunshine(baseSunshine) {
        sunshine = baseSunshine;
    }

    // get the settings taking params into account
    // preference order: params, setting, savedSetting, alternative
    static getSettingsItem(setting, alternative) {
        const paramSetting = get(settings.params, setting);
        if (paramSetting) return paramSetting;
        // saved settings come from widget config and are the settings
        // that are sent to smooch directly, but we need them here as well

        // depr warning: this should go away soon
        const savedSetting = get(settings.saved, setting);
        const foundSetting = get(settings, setting);
        if (savedSetting && savedSetting !== foundSetting) {
        // we make sure if saved is there and different we
            return savedSetting;
        }
        // until here

        return get(settings, setting, alternative);
    }


    static getAppSettings(appid) {
        return new Promise(resolve => {
            try {
                const client = new XMLHttpRequest();
                client.onreadystatechange = () => {
                    if (client.readyState === 4) {
                        if (client.status === 200) {
                            try {
                                resolve(JSON.parse(client.responseText));
                            } catch (e) {
                                log(`Problem in config file of app ${appid}`, e);
                                resolve({});
                            }
                        } else {
                            resolve({});
                        }
                    }
                };
        log(`getting ${BUCKET}/config/${appid}.json`); // eslint-disable-line
        client.open("GET", `${BUCKET}/config/${appid}.json`); // eslint-disable-line
                client.send();
            } catch (e) {
                resolve({});
            }
        });
    }

    // matchUrl will find the url that matches the best with the url where the widget running
    static matchUrl(urls, url) {
        const items = Array.csFrom(Object.keys(urls));
        return items.csReduce((answer, next) => {
            if (url.match(new RegExp(urls[next].url)) && (urls[next].url || '').length > (answer.url || '').length) {
                return urls[next];
            }
            return answer;
        }, { url: '' });
    }

    // merge and mergeHelper make sure the urls-arrays are concatenated instead of merged
    static mergeHelper(objValue, srcValue, key) { // eslint-disable-line
        if (objValue && Array.isArray(objValue) && key === 'urls') return objValue.concat(srcValue);
    }
    static merge(...args) {
        return mergeWith(...args, SettingsHandler.mergeHelper);
    }

    static mergeAdvanaced(globalConfig, appConfig, advanced, urlAndPlatform, profiles) {
        if (profiles) {
            const configs = [...globalConfig, ...profiles].filter(config =>
                (!config.environment || config.environment === urlAndPlatform.platform) &&
            (!config.urls || !config.urls.length || config.urls.indexOf(urlAndPlatform.url) !== -1));
            return mergeWith(...configs, SettingsHandler.mergeHelper);
        }
        return SettingsHandler.merge(appConfig, advanced);
    }

    // mergeUrls merges all objects that have the same url key. This can happen
    // when the same key is in base,json, app id json, init setting or testOptions
    static mergeUrls(arr) {
        const out = {};
        arr.forEach(item => {
            const oldItem = out[item.url];
            if (oldItem) {
                out[item.url] = Object.assign({}, oldItem, item);
            } else {
                out[item.url] = item;
            }
        });
        return out;
    }


    static mergeSettings(baseConfig, appConfig = {}, baseInitConfig = {}, urlAndPlatform = {}, testOptions = {}, globalConfig = []) {
        // appConfig can have advanced tree that should be merged first
        const appConfigWithMergedAdvanced = appConfig.advanced ? SettingsHandler.mergeAdvanaced(globalConfig, appConfig, appConfig.advanced, urlAndPlatform, appConfig.profiles) : appConfig;
        delete appConfigWithMergedAdvanced.advanced;
        // start with merge of all settings
        let mergedSettings = SettingsHandler.merge(
            Object.csAssign({}, baseConfig),
            Object.csAssign({}, appConfigWithMergedAdvanced),
            Object.csAssign({}, baseInitConfig),
            Object.csAssign({}, testOptions),
            Object.csAssign({}, urlAndPlatform)
        );
        // merge advanced settings
        if (mergedSettings.advanced || mergedSettings.profiles) {
            mergedSettings = SettingsHandler.mergeAdvanaced(globalConfig, mergedSettings, mergedSettings.advanced, urlAndPlatform, appConfig.profiles);
            delete mergedSettings.advanced;
        }
        // merge platform specific overrules
        if (Platform.platform() === 'desktop' && mergedSettings.desktop) {
            mergedSettings = SettingsHandler.merge(mergedSettings, mergedSettings.desktop);
            delete mergedSettings.desktop;
        }
        if (Platform.isOnTablet() && mergedSettings.tablet) {
            mergedSettings = SettingsHandler.merge(mergedSettings, mergedSettings.tablet);
            delete mergedSettings.tablet;
        }
        if (Platform.isOnMobile() && mergedSettings.mobile) {
            mergedSettings = SettingsHandler.merge(mergedSettings, mergedSettings.mobile);
            delete mergedSettings.mobile;
        }

        // specific page overrules
        if (mergedSettings.urls) {
            if (Array.isArray(mergedSettings.urls)) {
                const validUrls = mergedSettings.urls.filter(u => u.url); // remove objects that do not have an url
                const mergedUrls = SettingsHandler.mergeUrls(validUrls); // merge same urls
                mergedSettings.mergedUrls = mergedUrls; // show in settings
                const matchedSettings = SettingsHandler.matchUrl(mergedUrls, mergedSettings.url);
                if (matchedSettings && matchedSettings.url) {
                    matchedSettings.matchedUrlRule = matchedSettings.url;
                    mergedSettings = SettingsHandler.merge(mergedSettings, matchedSettings);
                    if (matchedSettings.desktop || matchedSettings.tablet || matchedSettings.mobile) {
                        // eslint-disable-next-line
                        console.warn('place desktop, tablet or mobile settings at the top in advanced config, not in urls');
                    }
                }
            } else {
                // old depricated object way
                // eslint-disable-next-line
                console.warn('deprication warning: please update adv settings to use url array, not urls object.');
                Object.keys(mergedSettings.urls).forEach(urlKey => {
                    if (mergedSettings.url.match(urlKey)) {
                        mergedSettings = SettingsHandler.merge(mergedSettings, mergedSettings.urls[urlKey]);
                    }
                });
            }
        }
        // getting language from url
        if (mergedSettings.languageFromUrl) {
            const lang = document.location.href.match(/\/(nl|de|en|pt|fr|it|pt|pl)\//);
            if (lang && lang[1]) {
                mergedSettings.language = lang[1]; // eslint-disable-line
            }
        }
        // get language from I18N
        const orgLanguage = (mergedSettings.organization || {}).locale;
        const language = (mergedSettings.language || orgLanguage)
            ? I18N.setLanguage(mergedSettings.language || orgLanguage) // language set hard
            : I18N.getBrowserLanguageWithFallbackTo(mergedSettings.fallbackLanguage // else fallbackLanguage
                || DEFAULTLANGUAGE); // else DEFAULTLANGUAGE
        // keep copy of global language strings that have no language tag, which means there used for
        // each language
        const globalCustomText = Object.csAssign({}, mergedSettings.customText);
        // update customText with language from I18N
        mergedSettings = SettingsHandler.merge(
            mergedSettings,
            { customText: language },
            { customText: (mergedSettings.customText || {})[language._language_short] || {} }
        );
        // clean up language overrules from customText
        Object.keys(I18N.getLanguages()).forEach(lang => {
            delete mergedSettings.customText[lang];
            delete globalCustomText[lang];
        });
        // update the global language overrules
        mergedSettings = SettingsHandler.merge(
            mergedSettings,
            { customText: globalCustomText || {} }
        );
        return mergedSettings;
    }
}

export default SettingsHandler;
