/* eslint-disable */
const SmoochLoader = {
    load(widgetWindow, widgetDocument, smoochFieldName, appId) {
        return new Promise((resolve, reject) => {
            // Function to handle the response from the webloader
            function handleResponse() {
                try {
                    let e;
                    if (typeof this.response === 'string') {
                        e = JSON.parse(this.response);
                    } else {
                        e = this.response;
                    }
                    if (e.url) {
                        const t = widgetDocument.getElementsByTagName('script')[0];
                        const r = widgetDocument.createElement('script');
                        r.async = true;
                        r.src = e.url;
                        t.parentNode.insertBefore(r, t);
                    }
                    resolve(e);
                } catch (e) {
                    reject(e);
                }
            }

            let s,
                p,
                a,
                i = [],
                c = [];
            widgetWindow[smoochFieldName] = {
                init() {
                    s = arguments;
                    var e = {
                        then(n) {
                            return c.push({ type: 't', next: n }), e;
                        },
                        catch(n) {
                            return c.push({ type: 'c', next: n }), e;
                        }
                    };
                    return e;
                },
                on() {
                    i.push(arguments);
                },
                render() {
                    p = arguments;
                },
                destroy() {
                    a = arguments;
                }
            };

            // Function to handle the initialization of Smooch
            widgetWindow.__onWebMessengerHostReady__ = function (n) {
                if (delete widgetWindow.__onWebMessengerHostReady__, widgetWindow[smoochFieldName] = n, s) {
                    let r = n.init(...s);
                    for (var o = 0; o < c.length; o++) {
                        const u = c[o];
                        r = u.type === 't' ? r.then(u.next) : r.catch(u.next);
                    }
                }
                if (p) {
                    n.render(...p);
                }
                if (a) {
                    n.destroy(...a);
                }
                for (var o = 0; o < i.length; o++) {
                    n.on(...i[o]);
                }
            };

            // Make an XMLHttpRequest to fetch the Smooch Web Messenger script
            const u = new XMLHttpRequest();
            u.addEventListener('load', handleResponse);
            u.addEventListener('error', e => reject(e));
            u.open('GET', 'https://' + appId + '.webloader.smooch.io/', true);
            u.responseType = 'json';
            u.send();
        })
    }
};

export { SmoochLoader };
