/* eslint max-len:0 */
const Italian = {
    _language: 'italian',
    _language_short: 'it',
    agentWelkomMessage: 'Benvenuto. Fai la tua domanda qui.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Impossibile connettersi. Puoi {retry}',
    couldNotConnectInner: 'riprova a connetterti ora',
    couldNotConnectWithRetry: 'Impossibile connettersi. Continueremo a provare, oppure puoi {retry}.',
    couldNotConnectWithRetryInner: 'prova ora',
    headerText: 'Come possiamo aiutarti?',
    imageClickToReload: 'Clicca per ricaricare l\'immagine.',
    imageClickToView: 'Clicca per visualizzare l\'immagine {size}.',
    imagePreviewNotAvailable: 'Anteprima non disponibile.',
    inputPlaceholder: 'Digita qui, premi <invio> per inviare.',
    inputPlaceholderBlocked: 'Completa il modulo sopra...',
    introAppText: 'Invia il tuo messaggio di seguito o dal tuo canale preferito.',
    introductionText: 'Fai la tua domanda qui.',
    actionPaymentCompleted: 'Pagamento ricevuto.',
    actionPaymentError: 'Si è verificato un errore durante l\'elaborazione del pagamento. <br> Riprova o utilizza una carta diversa.',
    actionPostbackError: 'Si è verificato un errore durante l\'elaborazione dell\'azione. Riprova.',
    clickToRetry: 'Messaggio non consegnato. Riprova.',
    clickToRetryForm: 'Modulo non inviato. Fai clic ovunque sul modulo per riprovare.',
    connectNotificationText: 'Attendi una risposta o scegli l\'app con cui desideri continuare.',
    connectNotificationSingleText: 'Attendi una risposta o scegli l\'app con cui desideri continuare.',
    connectNotificationSingleButtonText: 'Attiva le notifiche di <name>',
    connectNotificationOthersText: 'altre app',
    emailChangeAddress: 'Cambia la mia email',
    emailDescription: 'Per essere informato tramite email quando ricevi una risposta, inserisci il tuo indirizzo email.',
    emailFieldLabel: 'La tua email',
    emailFieldPlaceholder: 'Il tuo indirizzo email',
    emailFormButton: 'Continua',
    fetchHistory: 'Carica altro',
    fetchingHistory: 'Recupero cronologia...',
    frontendEmailChannelDescription: 'Invia una email e ti risponderemo il prima possibile.',
    invalidFileError: 'Sono supportate solo immagini. Scegli un file con un\'estensione supportata (jpg, jpeg, png, gif o bmp).',
    lineChannelDescription: 'Scansiona il codice QR per parlare con noi tramite LINE.',
    locationNotSupported: 'Il tuo browser non supporta i servizi di localizzazione o sono stati disabilitati. Digita la tua posizione invece.',
    locationSecurityRestriction: 'Questo sito web non può accedere alla tua posizione. Digita la tua posizione invece.',
    locationSendingFailed: 'Impossibile inviare la posizione',
    locationServicesDenied: 'Questo sito web non può accedere alla tua posizione. Consenti l\'accesso nelle impostazioni o inserisci invece la tua posizione.',
    messageError: 'Si è verificato un errore durante l\'invio del messaggio. Riprova.',
    messageIndicatorTitlePlural: '({count}) Nuovi messaggi',
    messageIndicatorTitleSingular: '({count}) Nuovo messaggio',
    messageRelativeTimeDay: '{value}g fa',
    messageRelativeTimeHour: '{value}h fa',
    messageRelativeTimeJustNow: 'Proprio adesso',
    messageRelativeTimeMinute: '{value}m fa',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Invio...',
    messageDelivered: 'Consegnato',
    messengerChannelDescription: 'Collega il tuo account Facebook per continuare tramite Facebook Messenger.',
    whatsappChannelDescriptionDesktop: 'Inizia o continua la conversazione tramite WhatsApp. Clicca sul link qui sotto.',
    whatsappChannelDescriptionMobile: 'Inizia o continua la conversazione tramite WhatsApp. Clicca sul link qui sotto.',
    whatsappLinkingError: 'Si è verificato un errore durante la connessione. Riprova.',
    notificationSettingsChannelsDescription: 'Puoi anche contattarci tramite la tua app o servizio preferito.',
    notificationSettingsChannelsTitle: 'Altre app',
    notificationSettingsConnected: 'Collegato',
    notificationSettingsConnectedAs: 'Collegato come {username}',
    prechatCaptureGreetingText: 'Ciao 👋\nPer iniziare, vorremmo saperne un po\' di più su di te:',
    prechatCaptureNameLabel: 'Il tuo nome',
    prechatCaptureNamePlaceholder: 'Inserisci il tuo nome...',
    prechatCaptureEmailLabel: 'Email',
    prechatCaptureEmailPlaceholder: 'nome@azienda.com',
    prechatCaptureConfirmationText: 'Grazie! In cosa possiamo aiutarti?',
    prechatCaptureMailgunLinkingConfirmation: 'Verrai notificato qui e via email all\'indirizzo {email} una volta che ti risponderemo.',
    sendButtonText: 'Invia',
    settingsHeaderText: 'Impostazioni',
    smsBadRequestError: 'Impossibile connettersi a questo numero. Riprova o utilizza un numero diverso.',
    smsCancel: 'Annulla',
    smsChangeNumber: 'Cambia il mio numero',
    smsChannelDescription: 'Collega il tuo numero SMS per continuare tramite SMS.',
    smsChannelPendingDescription: 'Controlla i tuoi messaggi al numero {number} per confermare il tuo numero di telefono.',
    smsContinue: 'Continua',
    smsInvalidNumberError: 'Il tuo numero di telefono non è valido. Riprova.',
    smsLinkCancelled: 'Il collegamento a {appUserNumber} è stato annullato.',
    smsLinkPending: 'In sospeso',
    smsPingChannelError: 'Si è verificato un errore durante l\'invio di un messaggio al tuo numero.',
    smsSendText: 'Inviami un messaggio',
    smsStartTexting: 'Inizia a inviare messaggi',
    smsTooManyRequestsError: 'È stata richiesta di recente una connessione per quel numero. Riprova tra {minutes} minuti.',
    smsTooManyRequestsOneMinuteError: 'È stata richiesta di recente una connessione per quel numero. Riprova tra 1 minuto.',
    smsUnhandledError: 'Si è verificato un errore. Riprova.',
    tapToRetry: 'Messaggio non consegnato. Premi invio per riprovare.',
    tapToRetryForm: 'Modulo non inviato. Tocca ovunque sul modulo per riprovare.',
    telegramChannelDescription: 'Collega il tuo account Telegram per continuare tramite Telegram.',
    unsupportedMessageType: 'Tipo di messaggio non supportato.',
    unsupportedActionType: 'Tipo di azione non supportato.',
    linkError: 'Si è verificato un errore durante la generazione di un link per questo canale. Riprova.',
    viberChannelDescription: 'Collega il tuo account Viber per continuare tramite Viber.',
    viberChannelDescriptionMobile: 'Collega il tuo account Viber per ricevere una notifica quando ricevi una risposta e continuare la conversazione su Viber. Per iniziare, installa l\'app Viber e tocca Connessione.',
    viberQRCodeError: 'Si è verificato un errore durante il recupero del codice QR di Viber. Riprova.',
    wechatChannelDescription: 'Collega il tuo account WeChat per ricevere una notifica quando ricevi una risposta e continuare la conversazione su WeChat. Per iniziare, scannerizza questo codice QR utilizzando l\'app WeChat.',
    wechatChannelDescriptionMobile: 'Collega il tuo account WeChat per ricevere una notifica quando ricevi una risposta e continuare la conversazione su WeChat. Per iniziare, salva questa immagine del codice QR e caricala su <a href="weixin://dl/scan">lettore di codice QR</a> di WeChat.',
    wechatQRCodeError: 'Si è verificato un errore durante il recupero del codice QR di WeChat. Riprova.',
    fileTooLargeError: 'Superato il limite di dimensione massima del file ({size})',
    shareLocation: 'Condividi posizione',
    uploadDocument: 'Carica documento',
    uploadInvalidError: 'File non valido',
    fileTypeError: 'Tipo di file non supportato',
    formErrorInvalidEmail: 'Email non valida',
    formErrorNoLongerThan: 'Deve contenere al massimo ({characters}) caratteri',
    formErrorNoShorterThan: 'Deve contenere almeno ({characters}) caratteri',
    formErrorUnknown: 'Qualcosa non sembra corretto',
    formFieldSelectPlaceholderFallback: 'Scegli uno...',
    uploadPhoto: 'Carica foto',
    uploadVirusError: 'È stato rilevato un virus nel tuo file ed è stato rifiutato',
    shoutoutText: 'Avvia una chat'
};
export { Italian };
