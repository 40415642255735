/*global TARGET_DOMAIN*/
import {
    init,
    destroy,
    getLanguage,
    Platform
} from './base';

// they are assinged individually, because the customer module is added later;
window[TARGET_DOMAIN] = window[TARGET_DOMAIN] || {};
window[TARGET_DOMAIN].init = init;
window[TARGET_DOMAIN].getLanguage = getLanguage;
window[TARGET_DOMAIN].destroy = destroy;
window[TARGET_DOMAIN].version = VERSION; // eslint-disable-line

// fallback
window.csWidget = window[TARGET_DOMAIN];
// loading via script data-web1on1-appid
// development script loaded via chrome ext 'User Javascript ans CSS' gets prio.
const scriptQ = `script[data-${TARGET_DOMAIN}-appid][data-${TARGET_DOMAIN}-development],script[data-${TARGET_DOMAIN}-appid]`;
const scripts = document.querySelectorAll(scriptQ);
const scriptTag = scripts[0];
if (scriptTag) {
    const appId = scriptTag.getAttribute(`data-${TARGET_DOMAIN}-appid`);
    const language = scriptTag.getAttribute(`data-${TARGET_DOMAIN}-language`);
    const bot = scriptTag.getAttribute(`data-${TARGET_DOMAIN}-bot`);
    // we add the script source, so we can use its base for loading customer script
    const src = scriptTag.getAttribute('src');
    const embedded = scriptTag.getAttribute(`data-${TARGET_DOMAIN}-embedded`) !== 'false'
        ? scriptTag.getAttribute(`data-${TARGET_DOMAIN}-embedded`) : false;
    // disable sound for auto starting bot and preventing sound errors
    const nosound = scriptTag.getAttribute(`data-${TARGET_DOMAIN}-nosound`) === 'true';
    const optionVar = scriptTag.getAttribute(`data-${TARGET_DOMAIN}-options`);
    const callbackName = scriptTag.getAttribute(`data-${TARGET_DOMAIN}-callback`);
    const disableUnload = scriptTag.getAttribute(`data-${TARGET_DOMAIN}-disableunload`) === 'true';
    if (window[optionVar] && typeof window[optionVar] !== 'object') console.log(`passed options var ${optionVar} is not an object`); //eslint-disable-line
    if (callbackName && window[callbackName] && typeof window[callbackName] !== 'function') console.log(`passed callback ${callbackName} is not a function`); //eslint-disable-line

    const callback = (window[callbackName] && typeof window[callbackName] === 'function') ? window[callbackName] : {};

    const options = Object.csAssign(
        {},
        { appId },
        window[optionVar] || {},
        { callback },
        { src }
    );
    if (bot) {
        options.bot = bot;
    }
    if (language) {
        options.language = language;
    }
    if (embedded) {
        if (Platform.platform() === 'desktop') {
            options.embedded = embedded;
        } else {
            options.embedded = embedded;
            options.fullscreen = true;
        }
    }
    if (nosound) {
        options.soundNotificationEnabled = false;
    }
    if (document.readyState === 'complete') {
        console.log('DOM load was already trigged, call init immidiately'); //eslint-disable-line
        init(options);
    } else if (window.addEventListener) {
        window.addEventListener('load', init.bind(this, options));
        if (!disableUnload) window.addEventListener('beforeunload', destroy);
    } else if (window.attachEvent) {
        window.attachEvent('onload', init.bind(this, options));
        if (!disableUnload) window.attachEvent('beforeunload', destroy);
    } else {
        if (!PRODUCTION) console.log(`could not init. init manually by calling window.${TARGET_DOMAIN}.init({ appId: '<your app id>'})`); //eslint-disable-line
    }
} else {
    if (!PRODUCTION) console.log(`data-${TARGET_DOMAIN}-appid not found. call init manually by calling window.${TARGET_DOMAIN}.init({ appId: '<your app id>'})`); //eslint-disable-line
}
