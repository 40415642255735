import { Helpers } from "../helpers";
import DialogHandler from "./dialog-handler";
import SettingsHandler from "./settings-handler";
import StyleHandler from "./style-handler";
import { createElementFromHTML } from "./utils";

class EyeCatcherHandler {

    // animate any element using https://daneden.github.io/animate.css/
    static animate(element, animations, cb) {
        if (element) {
            const anis = animations.split(/\s+/);
            anis.unshift('animated');
            anis.forEach(a => {
                Helpers.addClass(element, a);
            });
            const animationDone = () => {
                if (cb && typeof cb === 'function') {
                    cb(element, this);
                }
                anis.forEach(a => {
                    Helpers.removeClass(element, a);
                });
                element.removeEventListener('animationend', animationDone);
            };
            element.addEventListener('animationend', animationDone);
        }
    }

    static getWindowMeasurements() {
        const fr = DialogHandler.getWebmessengerFrame();
        return [fr.clientWidth, fr.offsetTop, fr.offsetLeft];
    }

    // hide it the moment the widget is opened
    static csEyeCatcherClose(event, animated = true) {
        if (event && typeof event.stopPropagation === 'function') event.stopPropagation();
        SettingsHandler.Storage.set(
            'widget_eyecatcher_closed',
            true,
            SettingsHandler.cookieLivetime
        );
        const catcher = document.getElementById('csEyeCatcher');
        const remove = () => {
            if (catcher) catcher.parentNode.removeChild(catcher);
        };
        const eyecatcherCloseAnimation = SettingsHandler.getSetting('eyecatcherCloseAnimation');
        if (eyecatcherCloseAnimation && animated) {
            EyeCatcherHandler.animate(catcher, eyecatcherCloseAnimation, remove);
        } else {
            remove();
        }
        return false;
    }

    // add eyecather if configured
    static addEyeCatherIfConfigured(force = false) {
        const closed = !!SettingsHandler.Storage.get('widget_eyecatcher_closed');
        const eyecatcher = SettingsHandler.getSetting('eyeCatcher');
        if (eyecatcher && (!closed || force) && SettingsHandler.sunshine && typeof SettingsHandler.sunshine.isOpened === 'function' && !SettingsHandler.sunshine.isOpened()) {
            const eyecatcherOffsetTop = SettingsHandler.getSetting('eyecatcherOffsetTop', 0);
            const eyecatcherOffsetLeft = SettingsHandler.getSetting('eyecatcherOffsetLeft', 0);
            const eyecatcherAnimation = SettingsHandler.getSetting('eyecatcherAnimation', 'fadeIn');
            setTimeout(() => {
                if (SettingsHandler.sunshine && typeof SettingsHandler.sunshine.isOpened === 'function' && !SettingsHandler.sunshine.isOpened()) {
                    const mouseover = "document.getElementById('csEyeCatcherClose').style.display='block'";
                    const mouseout = "document.getElementById('csEyeCatcherClose').style.display='none'";
                    const div = createElementFromHTML(`
                        <div id="csEyeCatcher" onmouseover="${mouseover}" onmouseout="${mouseout}" class="csEyeCatcher">
                            <a id="csEyeCatcherClose" href="#">x</a>
                            <img id="csEyeCatcherImage" src="${eyecatcher}">
                        </div>
                    `);
                    const oldOverFlow = document.body.style.overFlow;
                    document.body.style.overflow = 'hidden';
                    document.body.appendChild(div);
                    const [width, top, left] = EyeCatcherHandler.getWindowMeasurements();
                    const mobile = Array.csFrom(['mobile', 'tablet']).includes(SettingsHandler.Platform.platform());
                    const correctPosition = (opacity = 1) => {
                        const img = document.getElementById('csEyeCatcherImage');
                        const catcher = document.getElementById('csEyeCatcher');
                        const catcherClose = document.getElementById('csEyeCatcherClose');
                        if (img) {
                            if (img.clientWidth > width) {
                                // img.setAttribute('style', `width: ${width - Math.round(width / 10)}px`);
                            }
                            const newTop = top - img.clientHeight + (StyleHandler.displayStyle === 'bar' ? 12 : 0)
                                + (parseInt(eyecatcherOffsetTop, 10) || 0);
                            const newLeft = left + Math.round(width / 2) - Math.round(img.clientWidth / 2)
                                + (parseInt(eyecatcherOffsetLeft, 10) || 0);
                            const catcherStyle = `
                                opacity: ${opacity};
                                display: block;
                                width: ${img.clientWidth > width ? width : img.clientWidth}px;
                                position: absolute;
                                top: ${newTop}px;
                                left: ${newLeft}px;
                            `;
                            catcher.setAttribute(
                                'style',
                                catcherStyle
                            );
                            const closeStyle = `
                                position: absolute;
                                display: ${mobile ? 'block' : 'none'};
                                top: -5px;
                                left: ${img.clientWidth - 5}px;
                                text-decoration: none;
                                color: #000;
                                font-size: 20px;
                                font-family: Arial,
                                sans-serif;
                            `;
                            catcherClose.setAttribute('style', closeStyle);
                        }
                    };
                    correctPosition(0);
                    // add window movement corrections
                    if (!mobile && !SettingsHandler.getSetting('hide')) {
                        window.addEventListener('resize', correctPosition);
                    }
                    setTimeout(() => {
                        correctPosition();
                        if (eyecatcherAnimation) {
                            const animationDone = () => {
                                // reset body overflow
                                if (oldOverFlow) {
                                    document.body.style.overflow = oldOverFlow;
                                }
                            };
                            EyeCatcherHandler.animate(div, eyecatcherAnimation, animationDone);
                            div.addEventListener('animationend', animationDone);
                        }
                        const csEyeCatcherCloseCross = document.getElementById('csEyeCatcherClose');
                        csEyeCatcherCloseCross.addEventListener('click', EyeCatcherHandler.csEyeCatcherClose);
                        const csEyeCatcher = document.getElementById('csEyeCatcher');
                        csEyeCatcher.addEventListener('click', openWidget.bind(this, {})); // eslint-disable-line
                    }, 100);
                }
            }, 100);
        }
    }
}

export default EyeCatcherHandler;
