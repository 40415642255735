import { Platform } from './platform';
import { SmoochLoader } from './smooch';
import { StackDriver } from './stackdriver';
import { Storage } from './storage';

const Helpers = {
    hasClass(element, classname) {
        if (!element) return false;
        const className = ` ${classname} `;
        return (` ${element.className} `).replace(/[\n\t]/g, ' ').indexOf(className) > -1;
    },
    addClass(element, classnames) {
        if (!element) return false;
        classnames.split(/\s+/).forEach(classname => {
            if (!this.hasClass(element, classname)) {
                element.className = (element.className + ` ${classname}`).trim(); //eslint-disable-line
            }
        });
        return element;
    },
    removeClass(element, classnames) {
        if (!element) return false;
        classnames.split(/\s+/).forEach(classname => {
            if (this.hasClass(element, classname)) {
                element.className = (' ' + element.className + ' ').replace(` ${classname} `, ' ').trim(); //eslint-disable-line
            }
        });
        return element;
    },
    toggleClass(element, classname) {
        if (this.hasClass(element, classname)) {
            this.removeClass(element, classname);
        } else {
            this.addClass(element, classname);
        }
    },
    findParentClass(element, classname, max) {
        if (this.hasClass(element.parentNode, classname)) {
            return element.parentNode;
        }
        const maxInc = max ? max - 1 : max;
        if (element.parentNode && (!maxInc || maxInc < 0)) {
            return this.findParentClass(element.parentNode, classname, maxInc);
        }
        return undefined;
    }
};

export {
    Platform,
    Storage,
    Helpers,
    SmoochLoader,
    StackDriver as ErrorHandler
};
