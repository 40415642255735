/*global TARGET_DOMAIN,gtag*/
import SettingsHandler from "./settings-handler";
import { log } from "./utils";

class AnalyticsHandler {

    static guid() {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
    }

    static removeAllCookies() {
        SettingsHandler.Storage.delete('widget_loaded');
        SettingsHandler.Storage.delete('widget_customer_clicked_header');
        SettingsHandler.Storage.delete('widget_uuid');
        SettingsHandler.Storage.delete('widget_auto_opened');
        SettingsHandler.Storage.delete('widget_closed_by_customer');
        SettingsHandler.Storage.delete('widget_eyecatcher_closed');
        SettingsHandler.Storage.delete('widget_opened_by_customer');
        SettingsHandler.Storage.delete('widget_opened_by_id');
        SettingsHandler.Storage.delete('widget_opened_by_class');
        SettingsHandler.Storage.delete('widget_shoutout_opened');
        SettingsHandler.Storage.delete('widget_shoutout_closed');
        SettingsHandler.Storage.delete('widget_send_csat');
    }

    // this function send feedback to our cloud function that stores it in the sunshine analytics db
    static feedbackAnalytics(event  = 'unknown', state, oncePerSession = false) { // eslint-disable-line
        try {
            log('feedbackAnalytics', event);
            log(`feedbackAnalytics ${event} ${oncePerSession}`);
            const eventName = event.replace(/\s+/, '_');
            if (oncePerSession) {
                const eventInCookie = SettingsHandler.Storage.get(eventName);
                if (eventInCookie) {
                    log(`feedbackAnalytics ${event} ${oncePerSession} no feedback`);
                    return; // is still active, don't send
                }
            }
            if (!SettingsHandler.Storage.get('widget_loaded')) {
                // widget_loaded is reset by browser,
                // also reset all other cookies to get a fresh start
                AnalyticsHandler.removeAllCookies();
            }
            // store a unique user id in cookie and try to keep it as long as possible
            let uniqueUid = SettingsHandler.Storage.get('widget_uuid');
            if (!uniqueUid) {
                uniqueUid = AnalyticsHandler.guid();
                SettingsHandler.Storage.set('widget_uuid', uniqueUid, SettingsHandler.cookieLivetime);
            }
            const date = new Date();
            SettingsHandler.Storage.set(
                eventName,
                date.getTime(),
                SettingsHandler.cookieLivetime
            );

            if (SettingsHandler.getSetting('hide') && !SettingsHandler.getSetting('rehide')) {
                // page is hidden. no analytics should be send.
                return;
            }
            // time with timezoneoffset and daylight saving included
            // const tzDate = date.getTime() + (date.getTimezoneOffset() * 60 * 1000);
            const appId = SettingsHandler.getSetting('appId');
            const data = Object.csAssign({}, {
                appId,
                event,
                uniqueUid,
                timestamp: date.toISOString(),
                platform: SettingsHandler.Platform.platform(),
                offset: date.getTimezoneOffset(),
                url: document.URL
            }, state);
            // eslint-disable-next-line
            const url = `https://europe-west2-cs-microservices.cloudfunctions.net/webhook-smoochanalytics2bq${PRODUCTION ? '' : '_testing'}`;
            // eslint-disable-next-line
            if (!PRODUCTION) log(`sending analytics event '${event}' to ${url} for appId ${appId}`, data);
            navigator.sendBeacon(url, JSON.stringify(data));
        } catch (e) {
            log('send analytics failed', e);
        }
    }

    static checkAnalytics() {
        log('checkAnalytics', SettingsHandler.analyticsEnabled);
        if (SettingsHandler.analyticsEnabled) {
            AnalyticsHandler.feedbackAnalytics('widget_loaded', {}, true);
            // record entering and leaving of pages
            // feedbackAnalytics('page_enter', {});
            window.addEventListener(
                'beforeunload',
                AnalyticsHandler.feedbackAnalytics.bind(AnalyticsHandler.feedbackAnalytics, 'page_leave', {})
            );
        }
    }

    static initGoogleAnalytics() {

        window[TARGET_DOMAIN].on('widget:opened', () => {
            if (typeof gtag === 'function') {
                gtag('event', 'widget:opened');
                gtag('get', SettingsHandler.getSetting('googleAnalyticsMeasurementId'), 'client_id', (gaClientId) => {
                    window[TARGET_DOMAIN].updateUser({
                        properties: {
                            gaClientId,
                            gaMeasurementId: SettingsHandler.getSetting('googleAnalyticsMeasurementId')
                        }
                    });
                });
            }
        });

        window[TARGET_DOMAIN].on('widget:closed', () => {
            if (typeof gtag === 'function') {
                gtag('event', 'widget:closed');
            }
        });

        // The moment a message is received from an agent or bot
        window[TARGET_DOMAIN].on('message:received', (message) => {
            if (message.metadata.conversation) {
                if (typeof gtag === 'function') {
                    gtag('event', 'message:received', {
                        conversation: message.metadata.conversation,
                        contact: message.metadata.contact,
                        organization: message.metadata.organization
                    });
                }
            }
        });

        // The moment a message is send by the contact
        window[TARGET_DOMAIN].on('message:sent', () => {
            console.log('message:sent');
            const meta = window[TARGET_DOMAIN].getUser().properties;
            if (meta.conversation) {
                if (typeof gtag === 'function') {
                    gtag('event', 'message:sent', {
                        conversation: meta.conversation,
                        contact: meta.contact,
                        organization: meta.organization
                    });
                }
            }
        });
    }
}

export default AnalyticsHandler;
