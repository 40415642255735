import StackdriverErrorReporter from 'stackdriver-errors-js';
// we can disable error handling for certain apps that are mostly removed
// intergration that are stil active on a customers website

const StackDriver = {
    // sane defaults
    errorHandler: {},
    disabledAppIds: [],
    enabledAppIds: [],
    disabled: false,

    // methods
    load(appId) {
        this.appId = appId;
        this.errorHandler = new StackdriverErrorReporter();
        this.errorHandler.start({
            targetUrl: 'http://europe-west2-cs-microservices.cloudfunctions.net/webhook-widget2log',

            // The following optional arguments can also be provided:

            service: 'web-widget',
            // Name of the service reporting the error, defaults to 'web'.

            version: VERSION, // eslint-disable-line
            // Version identifier of the service reporting the error.

            reportUncaughtExceptions: false,
            // Set to false to prevent reporting unhandled exceptions, default: `true`.

            reportUnhandledPromiseRejections: false,
            // Set to false to prevent reporting unhandled promise rejections, default: `true`.

            customReportingFunction: this.payloadOfStackDriver.bind(this)
        });
    },

    enable(settings) {
        const _this = this;
        if (settings && Array.isArray(settings)) {
            settings.forEach(appid => { _this.enabledAppIds.push(appid); });
            if (_this.enabledAppIds.includes && _this.enabledAppIds.includes(_this.appId)) {
                console.log('errorhandling enabled'); //eslint-disable-line
                _this.disabled = false;
            }
        } else if (settings && _this.enabledAppIds.includes && !_this.disabledAppIds.includes(_this.appId)) {
            _this.disabled = false;
        }
    },

    disable(settings) {
        const _this = this;
        if (settings && Array.isArray(settings)) {
            settings.forEach(appid => { _this.disabledAppIds.push(appid); });
            if (_this.disabledAppIds.includes && _this.disabledAppIds.includes(_this.appId)) {
                console.log('errorhandling disabled'); //eslint-disable-line
                _this.disabled = true;
            }
        } else if (settings && !_this.enabledAppIds.includes && !_this.enabledAppIds.includes(_this.appId)) {
            _this.disabled = true;
        }
    },

    payloadOfStackDriver(payload) {
        if (this.disabled) return Promise.resolve();
        if (payload && payload.message && payload.message.match(/\.smooch\.io/)) {
            // forget about smooch errors
            return Promise.resolve();
        }
        const data = Object.assign({}, payload, {
            serviceContext: Object.assign({}, payload.serviceContext, { appid: this.appId, production: PRODUCTION }) // eslint-disable-line
        });
        const strData = (JSON.stringify(data) || '').trim();
        if (!this.disabled && strData) {
            const xhttp = new XMLHttpRequest();
            const url = 'https://europe-west2-cs-microservices.cloudfunctions.net/webhook-widget2log';
            xhttp.open('POST', url, true);
            xhttp.setRequestHeader('Content-Type', 'application/json');
            xhttp.send(strData);
        } else {
            console.log('errorHandler disabled', payload); //eslint-disable-line
        }
        return Promise.resolve();
    },

    handleError(e) {
        if (this.errorHandler.report && !this.disabled) {
            this.errorHandler.report(typeof e === 'string' ? new Error(e) : e);
        } else {
            console.log('error in widget:', e); //eslint-disable-line
        }
    }
};

export { StackDriver };
