/* eslint max-len:0 */
const Finnish = {
    _language: 'finnish',
    _language_short: 'fi',
    agentWelkomMessage: 'Tervetuloa. Kysy kysymyksesi täällä.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Yhteys ei onnistunut. Voit {retry}',
    couldNotConnectInner: 'yritä liittyä uudelleen nyt',
    couldNotConnectWithRetry: 'Yhteys ei onnistunut. Jatkamme toistamista tai voit {retry}.',
    couldNotConnectWithRetryInner: 'kokeile nyt',
    headerText: 'Kuinka voimme auttaa sinua?',
    imageClickToReload: 'Klikkaa ladataksesi kuvan uudelleen.',
    imageClickToView: 'Klikkaa nähdäksesi kuvan {size}.',
    imagePreviewNotAvailable: 'Esikatselu ei ole saatavilla.',
    inputPlaceholder: 'Kirjoita tähän ja paina <enter> lähettääksesi.',
    inputPlaceholderBlocked: 'Täytä alla oleva lomake...',
    introAppText: 'Lähetä viestisi alla tai suosikkikanavastasi.',
    introductionText: 'Kysy kysymyksesi täällä.',
    actionPaymentCompleted: 'Maksu vastaanotettu.',
    actionPaymentError: 'Maksun käsittelyssä tapahtui virhe. <br> Yritä uudelleen tai käytä toista korttia.',
    actionPostbackError: 'Toiminnossa tapahtui virhe. Yritä uudelleen.',
    clickToRetry: 'Viestiä ei voitu toimittaa. Yritä uudelleen.',
    clickToRetryForm: 'Lomaketta ei lähetetty. Klikkaa missä tahansa lomakkeella yrittääksesi uudelleen.',
    connectNotificationText: 'Odota vastausta tai valitse sovellus, jolla haluat jatkaa.',
    connectNotificationSingleText: 'Odota vastausta tai valitse sovellus, jolla haluat jatkaa.',
    connectNotificationSingleButtonText: 'Ota käyttöön ilmoitukset käyttäjältä <name>',
    connectNotificationOthersText: 'muut',
    emailChangeAddress: 'Vaihda sähköpostiosoitettani',
    emailDescription: 'Saadaksesi sähköposti-ilmoituksen vastauksesta, anna sähköpostiosoitteesi.',
    emailFieldLabel: 'Sähköpostisi',
    emailFieldPlaceholder: 'Sähköpostiosoitteesi',
    emailFormButton: 'Jatka',
    fetchHistory: 'Hae lisää',
    fetchingHistory: 'Haetaan historiaa...',
    frontendEmailChannelDescription: 'Lähetä meille sähköposti, ja vastaamme sinulle mahdollisimman pian.',
    invalidFileError: 'Vain kuvat ovat tuettuja. Valitse tuettu tiedostomuoto (jpg, jpeg, png, gif tai bmp).',
    lineChannelDescription: 'Keskustellaksesi kanssamme skannaa QR-koodi LINE-sovelluksella.',
    locationNotSupported: 'Selaimessasi ei ole sijaintipalvelujen tukea tai ne ovat poistettu käytöstä. Anna sijaintisi manuaalisesti.',
    locationSecurityRestriction: 'Tällä verkkosivustolla ei ole pääsyä sijaintiisi. Anna sijaintisi manuaalisesti.',
    locationSendingFailed: 'Sijaintia ei voitu lähettää',
    locationServicesDenied: 'Tällä verkkosivustolla ei ole pääsyä sijaintiisi. Ota pääsy käyttöön asetuksissasi tai anna sijaintisi manuaalisesti.',
    messageError: 'Viestisi lähetyksessä tapahtui virhe. Yritä uudelleen.',
    messageIndicatorTitlePlural: '({count}) Uutta viestiä',
    messageIndicatorTitleSingular: '({count}) Uusi viesti',
    messageRelativeTimeDay: '{value} päivää sitten',
    messageRelativeTimeHour: '{value} tuntia sitten',
    messageRelativeTimeJustNow: 'Juuri nyt',
    messageRelativeTimeMinute: '{value} minuuttia sitten',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Lähetetään...',
    messageDelivered: 'Toimitettu',
    messengerChannelDescription: 'Jatkaaksesi Facebook Messengerin avulla liitä tilisi Facebookiin.',
    whatsappChannelDescriptionDesktop: 'Aloita tai jatka keskustelua WhatsAppin avulla. Klikkaa alla olevaa linkkiä.',
    whatsappChannelDescriptionMobile: 'Aloita tai jatka keskustelua WhatsAppin avulla. Klikkaa alla olevaa linkkiä.',
    whatsappLinkingError: 'Liitettäessä tapahtui virhe. Yritä uudelleen.',
    notificationSettingsChannelsDescription: 'Voit myös keskustella suosikkiappissasi tai -palvelussasi.',
    notificationSettingsChannelsTitle: 'Muut sovellukset',
    notificationSettingsConnected: 'Yhdistetty',
    notificationSettingsConnectedAs: 'Yhdistetty käyttäjänä {username}',
    prechatCaptureGreetingText: 'Hei 👋\nHaluamme tietää sinusta hieman lisää aloittaaksemme:',
    prechatCaptureNameLabel: 'Nimesi',
    prechatCaptureNamePlaceholder: 'Kirjoita nimesi...',
    prechatCaptureEmailLabel: 'Sähköposti',
    prechatCaptureEmailPlaceholder: 'name@company.com',
    prechatCaptureConfirmationText: 'Kiitos! Kuinka voimme auttaa sinua?',
    prechatCaptureMailgunLinkingConfirmation: 'Sinulle ilmoitetaan täällä ja sähköpostitse osoitteeseen {email}, kun vastaamme sinulle. ',
    sendButtonText: 'Lähetä',
    settingsHeaderText: 'Asetukset',
    smsBadRequestError: 'Yhteyden muodostaminen tähän numeroon ei onnistunut. Yritä uudelleen tai käytä toista numeroa. ',
    smsCancel: 'Peruuta',
    smsChangeNumber: 'Vaihda numerosi',
    smsChannelDescription: 'Jatkaaksesi tekstiviestien avulla liitä tekstiviestinumerosi.',
    smsChannelPendingDescription: 'Tarkista viestisi numerosta {number} vahvistaaksesi puhelinnumerosi.',
    smsContinue: 'Jatka',
    smsInvalidNumberError: 'Puhelinnumerosi ei ole kelvollinen. Yritä uudelleen.',
    smsLinkCancelled: 'Yhteys käyttäjän {appUserNumber} kanssa peruutettiin.',
    smsLinkPending: 'Odottaa',
    smsPingChannelError: 'Viestin lähetyksessä numerollesi tapahtui virhe.',
    smsSendText: 'Lähetä minulle tekstiviesti',
    smsStartTexting: 'Aloita tekstiviestien kirjoittaminen',
    smsTooManyRequestsError: 'Pyyntö yhteyden muodostamiseksi tähän numeroon on tehty äskettäin. Yritä uudelleen {minutes} minuutin kuluttua.',
    smsTooManyRequestsOneMinuteError: 'Pyyntö yhteyden muodostamiseksi tähän numeroon on tehty äskettäin. Yritä uudelleen 1 minuutin kuluttua.',
    smsUnhandledError: 'Jotain meni pieleen. Yritä uudelleen.',
    tapToRetry: 'Viestiä ei voitu toimittaa. Paina enteriä yrittääksesi uudelleen.',
    tapToRetryForm: 'Lomaketta ei lähetetty. Paina lomaketta yrittääksesi uudelleen.',
    telegramChannelDescription: 'Jatkaaksesi liitä tilisi Telegramiin.',
    unsupportedMessageType: 'Ei tuettu viestityyppi.',
    unsupportedActionType: 'Ei tuettu toimintatyyppi.',
    linkError: 'Virhe yritettäessä luoda linkkiä tälle kanavalle. Yritä uudelleen.',
    viberChannelDescription: 'Jatkaaksesi liitä Viber-tilisi.',
    viberChannelDescriptionMobile: 'Jatkaaksesi liitä Viber-tilisi, ja saat ilmoituksen vastauksesta. Asenna Viber-sovellus ja napauta Liitä.',
    viberQRCodeError: 'Viber QR-koodin haku ei onnistunut. Yritä uudelleen.',
    wechatChannelDescription: 'Jatkaaksesi liitä WeChat-tilisi, ja saat ilmoituksen vastauksesta. Aloita skannaamalla tämä QR-koodi WeChat-sovelluksella.',
    wechatChannelDescriptionMobile: 'Jatkaaksesi liitä WeChat-tilisi, ja saat ilmoituksen vastauksesta. Tallenna tämä QR-koodi ja lataa se <a href="weixin://dl/scan">QR-koodinlukijaan</a>.',
    wechatQRCodeError: 'WeChat QR-koodin haku ei onnistunut. Yritä uudelleen.',
    fileTooLargeError: 'Tiedosto on liian suuri ({size})',
    shareLocation: 'Jaa sijainti',
    uploadDocument: 'Lataa asiakirja',
    uploadInvalidError: 'Virheellinen tiedosto',
    fileTypeError: 'Ei tuettu tiedostotyyppi',
    formErrorInvalidEmail: 'Sähköpostiosoite ei ole kelvollinen',
    formErrorNoLongerThan: 'Ei saa olla pidempi kuin ({characters}) merkkiä',
    formErrorNoShorterThan: 'On oltava vähintään ({characters}) merkkiä',
    formErrorUnknown: 'Jotain näyttää olevan pielessä',
    formFieldSelectPlaceholderFallback: 'Valitse...',
    uploadPhoto: 'Lataa valokuva',
    uploadVirusError: 'Tiedostossasi havaittiin virus, ja se hylättiin',
    shoutoutText: 'Aloita keskustelu'
};
export { Finnish };
