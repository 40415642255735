/*global TARGET_DOMAIN*/
import { log } from "./utils";
import { after, TRIGGER } from "./utils";
import DOMPurify from 'dompurify';
import { ErrorHandler } from "../helpers";
import { Helpers } from "../helpers";
import DialogHandler from "./dialog-handler";
import SettingsHandler from "./settings-handler";
import MessageHandler from "./message-handler";
import ShoutoutHandler from "./shoutout-handler";
import EyeCatcherHandler from "./eyecatcher-handler";


class SunshineHandler {
    constructor({
        sunshine, postBackHandler, styleHandler, preChatHandler, customerSatisfactionHandler, Platform
    }) {
        this.sunshine = sunshine;
        this.postBackHandler = postBackHandler;
        this.styleHandler = styleHandler;
        this.preChatHandler = preChatHandler;
        this.header = DialogHandler.header;
        this.customerSatisfactionHandler = customerSatisfactionHandler;
        this.Platform = Platform;
        this.init = this.init.bind(this);
        this.useBotWFTriggers = this.useBotWFTriggers.bind(this);
    }
    // since 29 jan 2021 bots can be triggered by the workflow. we send a >cs-meta=bot:botname,command:restart
    // that is picked up by cs backend and converted to conv.meta.bot=botname and conv.meta.command=restart
    // in the wf of the customer you add a wf rule to start the bot by matching on the meta vars and assigning to bot.
    // botmock bots use >cs botname command type messages. they should be transformed to the new setup
    // but only if botmockBotsViaWorkflow is set in adv config editor so we can gradually migrate bots.
    useBotWFTriggers(message) {
        if (!message.role === 'appUser') {
            return message;
        }
        log('useBotWFTriggers: botmockBotsViaWorkflow', SettingsHandler.getSetting('botmockBotsViaWorkflow'));
        if (SettingsHandler.getSetting('botmockBotsViaWorkflow', false) === true) {
            // transformation examples:
            // >cs botname reset                                =>      >cs-meta=bot:botname,command=reset
            // >cs botname start                                =>      >cs-meta=bot:botname,command=start
            // >cs botname stepreset                            =>      >cs-meta=bot:botname,command=reset,stepreset:true
            // >cs botname reset meta=prechatreset:true         =>      >cs-meta=bot:botname,command=reset,prechatreset:true
            // >cs allBots stop; >cs botname reset              =>      >cs-meta=bot:botname,command=reset,stopallbots:true
            let extraMetas;
            // first check if we have an allBots stop command
            // and make and meta for it
            if (message.text.match(/>cs allBots stop/)) {
                extraMetas = 'stopallbots:true';
                // eslint-disable-next-line
                message.text = message.text.replace(/>cs allBots stop\s*;\s*/, '');
            }
            // the deal with rest
            const matches = message.text.match(/>cs\s+(\S+)\s+(\S+)(\s+meta=\S+)?/);
            log('useBotWFTriggers: matches', message.text, matches);
            if (matches) {
                const bot = matches[1];
                let command = matches[2];
                let meta = matches[3];
                if (meta) {
                    meta = meta.replace(/\s*meta\s*=\s*/, '');
                    extraMetas = extraMetas ? `${extraMetas},${meta}` : meta;
                }
                if (command === 'stepreset') {
                    // stepreset becomes a normal reset with a stepreset param
                    extraMetas = extraMetas ? `${extraMetas},stepreset:true` : 'stepreset:true';
                    command = 'reset';
                }
                const commandText = `>cs-meta=bot:${bot},command:${command}${extraMetas ? `,${extraMetas}` : ''}`;
                log(`useBotWFTriggers: changed text from '${message.text}' to '${commandText}'`);
                // eslint-disable-next-line
                message.text = commandText;
            }
        }
        return message;
    }

    init() {
        // don't show the bot command messages in sunshine, but send them to the processor
        if (this.sunshine.setDelegate) {

            // If a threadId is provided in the url queryparams, we only want to
            // show the messages that have this threadId in message.metadata
            //const thread = (settings.params || {}).threadId;
            let thread;
            let debug; // setting debug also shows out-of-thread messages
            try {
                const params = new URLSearchParams(window.location.search);
                thread = params.get('threadId');
                debug = params.get('debug');
            } catch (e) {
                log('param error', e.toString());
            }

            this.sunshine.setDelegate({
                beforeSend: message => {
                    if (message.text && message.text.csStartsWith(`${TRIGGER} `) && message.role === 'appUser') {
                        // eslint-disable-next-line
                        message = this.useBotWFTriggers(message);
                        log('beforeSend: new message:', message.text);
                    }
                    // If a threadId is provided in the url queryparams,
                    // we attach it to the contact message metadata
                    if (thread) {
                        const metadata = Object.csAssign(message.metadata || {}, { thread });
                        return Object.assign(message, { metadata });
                    }
                    return message;
                },
                beforePostbackSend: (postback) => {
                    // If a threadId is provided in the url queryparams,
                    // we attach it to the contact message metadata
                    if (thread) {
                        const metadata = Object.assign(postback.metadata || {}, { thread });
                        return Object.assign(postback, { metadata });
                    }
                    return postback;
                },
                beforeDisplay: message => {
                    // If a threadId is provided in the url queryparams, we only want to
                    // show the messages that have this threadId in message.metadata
                    const metaThread = (message.metadata || {}).thread;
                    log('beforeDisplay - compare thread to meta', thread, metaThread);
                    if (thread && (!metaThread || metaThread !== thread)) {
                        if (debug) {
                            // eslint-disable-next-line no-param-reassign
                            message.text = `OUT-OF-THREAD ${message.text}`;
                        } else {
                            log('beforeDisplay - EXCLUDE', message.text);
                            return null;
                        }
                    }
                    log('beforeDisplay - INCLUDE', message.text);

                    // eslint-disable-next-line no-param-reassign
                    message.text = DOMPurify.sanitize(message.text);
                    try {
                        if (message && message.text
                            && (message.text.substr(0, 3) === TRIGGER)) {
                            return null; // but don't show the message in the interface of sunshine
                        }
                    } catch (e) {
                        ErrorHandler.handleError(e);
                    }
                    if (message.name && MessageHandler.agentRealnameToAlias[message.name]
                        && message.role === 'appMaker'
                    ) {
                        message.name = MessageHandler.agentRealnameToAlias[message.name]; // eslint-disable-line
                    }
                    this.postBackHandler.run();
                    after(100, this.styleHandler.fixMessageStyle);
                    after(100, MessageHandler.fixCardDoubleClick);
                    // after(100, fixOptionListAnswers);
                    if (SettingsHandler.getSetting('customText.messageSeen')) {
                        after(100, MessageHandler.fixSeen);
                    }
                    after(100, this.preChatHandler.fixPreChatCaptureLinks);

                    // we hide meta text from client but it will be send to server
                    if (message.text && message.text.match(' meta=')) {
                        const newMessage = Object.csAssign(
                            {},
                            message,
                            { text: message.text.replace(/ meta=.*/, '') }
                        );
                        return newMessage;
                    }
                    return message;
                }
            });
        }

        // add appropriate classes to the chatwindow
        // when openingen adn opened. at the moment with a simple timeout
        // as sunshine does not provide an event for opened
        this.sunshine.on('destroy', () => {
            log('widget is destroyed'); // eslint-disable-line
        });

        this.sunshine.on('message:received', message => {
            if (message && message.text
            && (message.text.substr(0, 3) === TRIGGER)) {
                MessageHandler.processServerCommand(message);
            }
        });

        this.sunshine.on('widget:opened', () => {
            try {
                const customerClicked = !!SettingsHandler.Storage.get('widget_customer_clicked_header');
                if (customerClicked) {
                    SettingsHandler.Storage.set(
                        'widget_customer_clicked_header',
                        false,
                        SettingsHandler.cookieLivetime
                    );
                    SettingsHandler.Storage.set(
                        'widget_opened_by_customer',
                        true,
                        SettingsHandler.cookieLivetime
                    );
                    SettingsHandler.Storage.set(
                        'widget_closed_by_customer',
                        false,
                        SettingsHandler.cookieLivetime
                    );
                    SettingsHandler.Storage.set(
                        'widget_show_first_message',
                        true,
                        SettingsHandler.cookieLivetime
                    );
                }
                MessageHandler.checkBotStartSettings();
                EyeCatcherHandler.csEyeCatcherClose(undefined, false);
                ShoutoutHandler.closeShoutout();
                const fr = DialogHandler.getWebmessengerFrame();
                Helpers.removeClass(fr, `${TARGET_DOMAIN}_close`);
                Helpers.addClass(fr, `${TARGET_DOMAIN}_appear`);

                // csat (consumer satisfaction)
                if (SettingsHandler.getSetting('csat')) {
                    this.header.addEventListener('click', this.customerSatisfactionHandler.enableCSAT); // eslint-disable-line
                }

                this.preChatHandler.init();
            } catch (e) {
                ErrorHandler.handleError(e);
            }
        });

        this.sunshine.on('widget:closed', () => {
            try {
                const customerClicked = !!SettingsHandler.Storage.get('widget_customer_clicked_header');
                if (customerClicked) {
                    SettingsHandler.Storage.set(
                        'widget_customer_clicked_header',
                        false,
                        SettingsHandler.cookieLivetime
                    );
                    SettingsHandler.Storage.set(
                        'widget_closed_by_customer',
                        true,
                        SettingsHandler.cookieLivetime
                    );
                    SettingsHandler.Storage.set(
                        'widget_opened_by_customer',
                        false,
                        SettingsHandler.cookieLivetime
                    );
                }
                // nothing yet
                const fr = DialogHandler.getWebmessengerFrame();
                Helpers.removeClass(fr, `${TARGET_DOMAIN}_appear`);
                Helpers.addClass(fr, `${TARGET_DOMAIN}_close`);
                if (SettingsHandler.getSetting('rehide')) {
                    DialogHandler.hideWidget(true);
                } else {
                    // on desktop we always show shoutout if true
                    // eslint-disable-next-line
                    if (SettingsHandler.getSetting('shoutout') && this.Platform.platform() !== 'mobile' && !SettingsHandler.getSetting('embedded')) {
                        // give close animation time to close
                        setTimeout(ShoutoutHandler.openShoutout, 1000);
                    }
                }
            } catch (e) {
                ErrorHandler.handleError(e);
            }
        });
    }
}

export default SunshineHandler;
