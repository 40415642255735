/*global TARGET_DOMAIN*/
import { log, createElementFromHTML } from "./utils";
import get from "lodash/get";
import { Helpers } from "../helpers";
import DialogHandler from "./dialog-handler";
import SettingsHandler from "./settings-handler";

class CustomerSatisfactionHandler {
    constructor({
        getSetting, sunshine, Storage
    }) {
        this.getSetting = getSetting;
        this.frame = DialogHandler.frame;
        this.sunshine = sunshine;
        this.wrapper = DialogHandler.wrapper;
        this.Storage = Storage;
        this.getCSATHost = this.getCSATHost.bind(this);
        this.sendWebviewMessage = this.sendWebviewMessage.bind(this);
        this.receiveWebviewMessage = this.receiveWebviewMessage.bind(this);
        this.enableCSAT = this.enableCSAT.bind(this);
        this.tellWebviewToSentCloseMessageWithStatus = this.sendWebviewMessage.bind(this, 'close');
        this.tellWebviewIAmReady = this.sendWebviewMessage.bind(this, 'ready');
    }

    getCSATHost() {
        const csat = this.getSetting('csat');
        if (csat.env) {
            if (csat.env.match(/^http/)) {
                return csat.env;
            }
            return CSATHOST.replace('ENV', `${csat.env}-`); // eslint-disable-line
        }
        return CSATHOST.replace('ENV', PRODUCTION ? '' : 'uat-'); // eslint-disable-line
    }

    sendWebviewMessage(message) {
        const webviewiframe = this.frame.getElementById('webview-iframe');
        const csathost = this.getCSATHost();
        try {
            webviewiframe.contentWindow.postMessage(message, csathost);
        } catch (e) {
            // never mind if it fails
        }
    }

    cleanupCSATAndCose() {
        try {
            const webview = this.frame.getElementById('webview');
            const webviewiframe = this.frame.getElementById('webview-iframe');
            webviewiframe.removeEventListener('load', this.tellWebviewIAmReady);
            webview.parentNode.removeChild(webview);
            this.sunshine.close();
        } catch (err) {
            log('error clean / close csat', err);
        }
    }

    receiveWebviewMessage(e) {
        try {
            if (e.data) {
                const message = typeof e.data === 'object' ? e.data : JSON.parse(e.data);
                // log('received a message from webview', message);
                if (message.status === 'ready') {
                    // webview is fully loaded, lets add a close event to the widgets close button
                    const webview = this.frame.getElementById('webview');
                    const close = webview.getElementsByClassName('close-handle')[0];
                    close.removeEventListener('click', this.tellWebviewToSentCloseMessageWithStatus); // eslint-disable-line
                    close.addEventListener('click', this.tellWebviewToSentCloseMessageWithStatus); // eslint-disable-line
                } else if (message.status === 'close') {
                    // send report to csat level0 bot
                    this.Storage.set(
                        'widget_send_csat',
                        true,
                        SettingsHandler.cookieLivetime
                    );
                    try {
                        const csathost = this.getCSATHost();
                        const messages = Array.csFrom(this.sunshine.getConversation().messages);
                        const endpoint = this.getSetting('csat.reportendpoint');
                        const rating = get(message, 'data.data.rating');
                        const remark = get(message, 'data.data.remark');
                        const messageWithConvID = messages.csFind(m => m.metadata && m.metadata.conversation);
                        if (messageWithConvID) {
                            const conversationID = messageWithConvID.metadata
                                && messageWithConvID.metadata.conversation;
                            const body = Object.assign({}, {
                                rating: { rating, remark },
                                conversation: conversationID,
                                endpoint
                            });
                            const xhttp = new XMLHttpRequest();
                            const url = `${csathost}/report`;
                            xhttp.open('POST', url, true);
                            xhttp.setRequestHeader('Content-Type', 'application/json');
                            xhttp.send(JSON.stringify(body));
                        } else {
                            log('csat disabled: no conversation id yet');
                        }
                    } catch (err) {
                        log('error sending report to csat bot', err);
                    }
                    this.cleanupCSATAndCose();
                }
            }
        } catch (em) {
            log('error processing a message from webview', e.data, em);
        }
    }

    enableCSAT(e) {
        try {
            const messages = Array.csFrom(this.sunshine.getConversation().messages);
            const messageWithConvID = messages.csFind(m => m.metadata && m.metadata.conversation);
            const messageByContact = messages.csFind(m => m.role && m.role === 'appUser' && m.text && !m.text.match(/^>cs/));
            if (messageWithConvID && messageByContact) {
                const conversationID = messageWithConvID.metadata && messageWithConvID.metadata.conversation;
                const csat = this.getSetting('csat');
                const fr = DialogHandler.getWebmessengerFrame();
                const csathost = this.getCSATHost();
                const csatAlreadyDone = !!this.Storage.get('widget_send_csat');
                if (csat && e && !csatAlreadyDone && !Helpers.hasClass(fr, `${TARGET_DOMAIN}_close`)) {
                    // only open csat if there was a conversation going on and it has had at least one
                    // non contact message as only the messages that came from CS contain the conversation
                    // id in its meta that we need to send the csat report.
                    if (conversationID) {
                        // stop closing the widget
                        e.preventDefault();
                        e.stopPropagation();
                        // create and open csat webwidget
                        const instant = typeof csat.instant === 'undefined' ? true : csat.instant; // defaults to true
                        const { googleReviewURL, googleReviewButton } = csat;
                        const node = createElementFromHTML(`<div><div id="webview" class="webview-enter-done"><div class="webview-container tall webview-content-enter-done animated fadeInUp"><div id="webview-header"><div class="header-content">${csat.title||'Rating'}</div><div class="close-handle"><i class="fa fa-times"></i></div></div><div id="webview-iframe-container" class=""><iframe id="webview-iframe" name="web_messenger_ref" src="${csathost}/webview/csat?conversation=${conversationID}&amp;sendbutton=${csat.sendbutton || 'Send'}&amp;title=${csat.title|| 'Please%20Rate'}&amp;header=${csat.header || 'Please%20rate%20the%20conversation'}&amp;remarkheader=${csat.remarkheader || ''}&amp;instant=${instant}&amp;googleReviewURL=${googleReviewURL || ''}&amp;googleReviewButton=${googleReviewButton || ''}&amp;type=${csat.type || 'fa-star'}" sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox"></iframe></div></div></div></div>`); // eslint-disable-line
                        if (this.wrapper) {
                            this.wrapper.appendChild(node);
                        }
                        // setup the message receiver
                        window.addEventListener('message', this.receiveWebviewMessage, false);
                        const webviewiframe = this.frame.getElementById('webview-iframe');
                        setTimeout(() => {
                            // tell the webview to let ius know when it is ready
                            webviewiframe.addEventListener('load', this.tellWebviewIAmReady);
                        }, 100);
                    }
                }
            } else {
                log('csat disabled: no conversation id yet');
            }
        } catch (err) {
            log('error enabling csat', err);
        }
    }
}

export default CustomerSatisfactionHandler;
