/* eslint max-len:0 */
const Portuguese = {
    _language: 'portuguese',
    _language_short: 'pt',
    agentWelkomMessage: 'Bem-vindo. Faça a sua pergunta aqui.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Não consegui connectar. Pode {retry}',
    couldNotConnectInner: 'Tente conectar-se de novo',
    couldNotConnectWithRetry: 'Conexão impossível. Continuaremons tentando, ou você pode {retry}',
    couldNotConnectWithRetryInner: 'Tente agora',
    headerText: 'Precisa de ajuda? Estamos à sua disposição.',
    imageClickToReload: 'Clique para recarregar imagem.',
    imageClickToView: 'Clique para ver o {size} da imagem.',
    imagePreviewNotAvailable: 'Pré-visualização indisponível.',
    inputPlaceholder: 'Escreva aqui e prima <enter> para enviar.',
    inputPlaceholderBlocked: 'Preenche o formulário acima...',
    introAppText: 'Envie-nos uma mensagem através do campo abaixo ou a partir da sua aplicação preferida.',
    introductionText: 'Faça a sua pergunta aqui.',
    actionPaymentCompleted: 'Pagamento recebido.',
    actionPaymentError: 'Ocorreu um erro ao processar o pagamento. <br> Tente novamente ou use um cartão diferente.',
    actionPostbackError: 'Ocorreu um erro ao processar a sua ação. Tente novamente.',
    clickToRetry: 'Mensagem não entregue. Tente novamente.',
    clickToRetryForm: 'Formulário não foi enviado. Clique em qualquer lugar para tentar novamente.',
    connectNotificationText: 'Aguarde a resposta ou escolha a aplicação através da qual quer continuar a conversa.',
    connectNotificationSingleText: 'Aguarde a resposta ou escolha a aplicação através da qual quer continuar a conversa.',
    connectNotificationSingleButtonText: 'Ativar as notificações <nome>',
    connectNotificationOthersText: 'outras',
    emailChangeAddress: 'Alterar o meu email',
    emailDescription: 'Indique o seu endereço de email para ser notificado por email quando receber uma resposta.',
    emailFieldLabel: 'O seu email',
    emailFieldPlaceholder: 'O seu endereço de email',
    emailFormButton: 'Continuar',
    fetchHistory: 'Carregar mais',
    fetchingHistory: 'A recuperar histórico...',
    frontendEmailChannelDescription: 'Envie-nos um email e responder-lhe-emos com a maior brevidade possível.',
    invalidFileError: 'Apenas são suportadas imagens. Escolha um ficheiro com uma extensão compatível (jpg, jpeg, png, gif ou bmp).',
    lineChannelDescription: 'Leia o código QR para falar connosco através da LINE.',
    locationNotSupported: 'O seu navegador não é compatível com serviços de localização ou estes estão desativados. Insira a sua localização.',
    locationSecurityRestriction: 'Este sítio Web não consegue aceder à sua localização. Insira a sua localização.',
    locationSendingFailed: 'Não foi possível enviar a localização',
    locationServicesDenied: 'Este sítio Web não consegue aceder à sua localização. Autorize o acesso nas suas definições ou insira a sua localização.',
    messageError: 'Ocorreu um erro ao enviar a sua mensagem. Tente novamente.',
    messageIndicatorTitlePlural: '({count}) Novas mensagens',
    messageIndicatorTitleSingular: '({count}) Nova mensagem',
    messageRelativeTimeDay: 'Há {value} dias',
    messageRelativeTimeHour: 'Há {value} horas',
    messageRelativeTimeJustNow: 'Agora mesmo',
    messageSeen: 'Visto',
    messageRelativeTimeMinute: 'entregue há {value} minuto',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'A enviar...',
    messageDelivered: 'Entregue',
    messengerChannelDescription: 'Associe a sua conta do Facebook para continuar através do Facebook Messenger.',
    whatsappChannelDescriptionDesktop: 'Começar ou continuar a sua conversa por WhatsApp? Clique na ligação abaixo.',
    whatsappChannelDescriptionMobile: 'Começar ou continuar a sua conversa por WhatsApp? Clique na ligação abaixo.',
    whatsappLinkingError: 'Ocorreu um erro ao estabelecer a ligação. Tente novamente.',
    notificationSettingsChannelsDescription: 'Pode falar connosco a partir da sua aplicação ou serviço preferido.',
    notificationSettingsChannelsTitle: 'Outras aplicações',
    notificationSettingsConnected: 'Ligado',
    notificationSettingsConnectedAs: 'Ligado como {username}',
    prechatCaptureGreetingText: 'Oi 👋\nPara começar, gostaríamos de saber um pouco mais sobre si.  ',
    prechatCaptureNameLabel: 'O seu nome',
    prechatCaptureNamePlaceholder: 'Escreva o seu nome e sobrenome...',
    prechatCaptureEmailLabel: 'Email',
    prechatCaptureEmailPlaceholder: 'nome@firma.pt',
    prechatCaptureConfirmationText: 'Obrigado por isso ! Como podemos ajudá-lo?',
    prechatCaptureMailgunLinkingConfirmation: 'Você será notificado aqui e por e-mail {email} quando respondemos.',
    sendButtonText: 'Enviar',
    settingsHeaderText: 'Definições',
    smsBadRequestError: 'Não foi possível estabelecer uma ligação para este número. Tente novamente ou use um número diferente.',
    smsCancel: 'Cancelar',
    smsChangeNumber: 'Alterar o meu número',
    smsChannelDescription: 'Associe o seu número de telemóvel para continuar a conversa por SMS.',
    smsChannelPendingDescription: 'Consulte as suas mensagens no número {number} para confirmar o seu número de telefone.',
    smsContinue: 'Continuar',
    smsInvalidNumberError: 'O seu número de telefone não é válido. Tente novamente.',
    smsLinkCancelled: 'A ligação ao número {appUserNumber} foi cancelada.',
    smsLinkPending: 'Pendente',
    smsPingChannelError: 'Ocorreu um erro ao enviar a mensagem para o seu número.',
    smsSendText: 'Enviar-me uma mensagem de texto',
    smsStartTexting: 'Começar a escrever',
    smsTooManyRequestsError: 'Recentemente, foi solicitada uma ligação para esse número. Tente novamente dentro de {minutes} minutos.',
    smsTooManyRequestsOneMinuteError: 'Recentemente, foi solicitada uma ligação para esse número. Tente novamente dentro de 1 minuto.',
    smsUnhandledError: 'Ocorreu um erro. Tente novamente.',
    tapToRetry: 'Mensagem não entregue. Prima Enter para tentar novamente.',
    tapToRetryForm: 'Formulário não foi enviado. Prima em qualquer lugar para tentar novamente.',
    telegramChannelDescription: 'Ligue a sua conta do Telegram para continuar através do Telegram.',
    unsupportedMessageType: 'Tipo de mensagem não compatível.',
    unsupportedActionType: 'Tipo de ação não compatível.',
    linkError: 'Ocorreu um erro ao tentar gerar uma ligação para este canal. Tente novamente.',
    viberChannelDescription: 'Ligue a sua conta do Viber para continuar através do Viber',
    viberChannelDescriptionMobile: 'Ligue a sua conta do Viber para ser notificado quando receber uma resposta e continuar a conversa no Viber. Para começar, instale a aplicação Viber e toque em Ligar.',
    viberQRCodeError: 'Ocorreu um erro ao obter o seu código QR para o Viber. Tente novamente.',
    wechatChannelDescription: 'Ligue a sua conta do WeChat para ser notificado quando receber uma resposta e continuar a conversa no WeChat. Para começar, leia este código QR usando a aplicação WeChat.',
    wechatChannelDescriptionMobile: 'Ligue a sua conta do WeChat para ser notificado quando receber uma resposta e continuar a conversa no WeChat. Para começar, guarde esta imagem de código QR e carregue-a <a href="weixin://dl/scan">QR code scanner</a>.',
    wechatQRCodeError: 'Ocorreu um erro procurando o seu código QR WeChat. Por favor, tente novamente.',
    fileTooLargeError: 'Limite de tamanho máximo de ficheiro excedido ({size})',
    shareLocation: 'Partilhar localização',
    uploadDocument: 'Carregar documento',
    uploadInvalidError: 'Ficheiro inválido',
    fileTypeError: 'Tipo de ficheiro não compatível',
    formErrorInvalidEmail: 'E-mail inválido',
    formErrorNoLongerThan: 'Não pode conter mais de ({characters}) dígitos  ',
    formErrorNoShorterThan: 'Deve conter ({characters}) dígitos ao minimo.',
    formErrorUnknown: 'Não parece completamente correto. ',
    formFieldSelectPlaceholderFallback: 'Escolha um...',
    uploadPhoto: 'Carregar fotografia',
    uploadVirusError: 'Foi detetado um vírus no seu ficheiro pelo que este foi rejeitado',
    shoutoutText: 'Começar a conversar'
};
export { Portuguese };
