/* eslint max-len:0 */
const Dutch = {
    _language: 'dutch',
    _language_short: 'nl',
    agentWelkomMessage: 'Welkom. Als u een vraag heeft, dan kunt u hier een chat starten.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Verbinden niet gelukt. Je kan het {retry}',
    couldNotConnectInner: 'probeer nu opnieuw te verbinden',
    couldNotConnectWithRetry: 'Verbinden niet gelukt. We blijven het proberen, of je kan het {retry}',
    couldNotConnectWithRetryInner: 'probeer nu',
    headerText: 'Hulp nodig? Ik help u graag.',
    imageClickToReload: 'Klik om afbeelding te herladen.',
    imageClickToView: 'Klik om {size} afbeelding te zien.',
    imagePreviewNotAvailable: 'Voorbeeld is niet beschikbaar.',
    inputPlaceholder: 'Typ hier, druk op <enter> om te versturen.',
    inputPlaceholderBlocked: 'Vul het bovenstaande formulier in... ',
    introAppText: 'Verstuur uw bericht hieronder of vanuit uw favoriete kanaal.',
    introductionText: 'Stel uw vraag.',
    actionPaymentCompleted: 'U heeft betaald.',
    actionPaymentError: 'Er is iets misgegaan met de betaling<br>Probeer het opnieuw of gebruik een andere pas.',
    actionPostbackError: 'Er is iets misgegaan bij het uitvoeren van uw actie. Probeer het A.U.B nog eens',
    clickToRetry: 'Bericht is niet afgeleverd, Probeer het A.U.B opnieuw',
    clickToRetryForm: 'Formulier niet verzonden. Klik ergens op het formulier om het opnieuw te proberen. ',
    connectNotificationText: 'Wacht op uw antwoord of geef hieronder aan via welke app u verder wilt chatten.',
    connectNotificationSingleText: 'Wacht op uw antwoord of geef hieronder aan via welke app u verder wilt chatten.',
    connectNotificationSingleButtonText: 'Zet <name> notificaties aan',
    connectNotificationOthersText: 'anderen',
    emailChangeAddress: 'Pas mijn email aan',
    emailDescription: 'Vul uw email in als u een reactie per email wilt ontvangen.',
    emailFieldLabel: 'Uw email',
    emailFieldPlaceholder: 'Uw emailadres',
    emailFormButton: 'Verder',
    fetchHistory: 'Haal meer op',
    fetchingHistory: 'Haal berichten historie op...',
    frontendEmailChannelDescription: 'Stuur ons een email en we nemen zo snel mogelijk contact met u op:',
    invalidFileError: 'Alleen plaatjes worden ondersteund. Kies een bestand van een van de volgende formaten (jpg, jpeg, png, gif, or bmp).',
    lineChannelDescription: 'Om met ons via LINE te praten, scant u deze QR code vanuit de LINE app. Daarna kunt ons een bericht sturen.',
    locationNotSupported: 'Uw Browser ondersteund geen locatie services of ze staan uit. Vul A.U.B zelf in.',
    locationSecurityRestriction: 'Deze webstie kan niet bij uw locatie gegevens. Vul A.U.B zelf in.',
    locationSendingFailed: 'Kon de locatie niet versturen',
    locationServicesDenied: 'Deze webstie kan niet bij uw locatie gegevens. Sta locatie gegevens toe of vul uw locatie zelf in.',
    messageError: 'Er is iets mis gegaan bij het versturen van uw bericht. Probeer het nog eens.',
    messageIndicatorTitlePlural: '({count}) Nieuwe berichten',
    messageIndicatorTitleSingular: '({count}) Nieuw bericht',
    messageRelativeTimeDay: '{value}d geleden',
    messageRelativeTimeHour: '{value}h geleden',
    messageRelativeTimeJustNow: 'Zojuist',
    messageSeen: 'Gezien',
    messageRelativeTimeMinute: '{value}m geleden',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'wordt verzonden...',
    messageDelivered: 'Afgeleverd',
    messengerChannelDescription: 'Verbindt uw Facebook account om de conversatie voort te zetten in Facebook Messenger.',
    whatsappChannelDescriptionDesktop: 'Gesprek starten of voortzetten via WhatsApp? Klik op onderstaande link.',
    whatsappChannelDescriptionMobile: 'Gesprek starten of voortzetten via WhatsApp? Klik op onderstaande link.',
    whatsappLinkingError: 'Er ging iets mis bij het koppelen. Probeer a.u.b. opnieuw.',
    notificationSettingsChannelsDescription: 'U kunt ook met ons communiceren vanuit uw favoriete app of dienst',
    notificationSettingsChannelsTitle: 'Andere apps',
    notificationSettingsConnected: 'Verbonden',
    notificationSettingsConnectedAs: 'Verbonden als {username}',
    prechatCaptureGreetingText: 'Hallo 👋\nOm te beginnen, willen we graag wat meer over u weten:',
    prechatCaptureNameLabel: 'Uw naam',
    prechatCaptureNamePlaceholder: 'Voer uw naam in...',
    prechatCaptureEmailLabel: 'E-mail',
    prechatCaptureEmailPlaceholder: 'name@company.com',
    prechatCaptureConfirmationText: 'Dank daarvoor! Waar kunnen we u mee helpen?',
    prechatCaptureMailgunLinkingConfirmation: 'U ontvangt hier een melding en per e-mail op {email} zodra we antwoorden.',
    sendButtonText: 'Stuur',
    settingsHeaderText: 'Instellingen',
    smsBadRequestError: 'We zijn niet in staat met dit nummer te verbinden. Probeer het nog eens of gebruik een ander nummer.',
    smsCancel: 'Annuleer',
    smsChangeNumber: 'Verander mijn nummer',
    smsChannelDescription: 'Verbindt uw SMS nummer om de conversatie voort te zetten via SMS.',
    smsChannelPendingDescription: 'Check uw berichten op {number} om uw telefoonnummer te bevestigen.',
    smsContinue: 'Doorgaan',
    smsInvalidNumberError: 'Uw telefoon nr is niet geldig. Probeer het A.U.B. opnieuw.',
    smsLinkCancelled: 'Verbinding met {appUserNumber} is geannuleerd.',
    smsLinkPending: 'In afwachting',
    smsPingChannelError: 'Er is een probleem opgetreden bij het versturen van een bericht naar uw nummer',
    smsSendText: 'Stuur mij een bericht',
    smsStartTexting: 'Begin met SMSen',
    smsTooManyRequestsError: 'Een koppeling met dit nummer is recent aangevraagd. Probeer het A.U.B. opnieuw over {minutes} minuten.',
    smsTooManyRequestsOneMinuteError: 'Een koppeling met dit nummer is recent al aangevraagd. Probeer het A.U.B. opnieuw over 1 minuut.',
    smsUnhandledError: 'Er is iets misgegaan. Probeer het A.U.B. opnieuw.',
    tapToRetry: 'Bericht niet afgeleverd. Enter om opnieuw te proberen',
    tapToRetryForm: 'Formulier niet verzonden. Klik ergens op het formulier om het opnieuw te proberen. ',
    telegramChannelDescription: 'Verbindt uw Telegram account om de conversatie voort te zetten in Telegram.',
    unsupportedMessageType: 'Bericht type niet ondersteund.',
    unsupportedActionType: 'Actie niet ondersteund.',
    linkError: 'Er is een fout opgetreden bij het genereren van een link voor dit kanaal. Probeer A.U.B. opnieuw.',
    viberChannelDescription: 'Verbindt uw Viber account om de conversatie voort te zetten in Viber. Scan de QR code met de Viber app om te beginnen.',
    viberChannelDescriptionMobile: 'Verbindt uw Viber account om de conversatie voort te zetten in Viber. Installeer de Viber app en druk op Verbinden.',
    viberQRCodeError: 'Er is een fout opgetreden bij het ophalen van uw Viber QR Code. Probeer het A.U.B. opnieuw.',
    wechatChannelDescription: 'Verbindt uw WeChat account om de conversatie voort te zetten in WeChat. Scan deze QR code met de WeChat app om te beginnen.',
    wechatChannelDescriptionMobile: 'Verbindt uw WeChat account om de conversatie voort te zetten in WeChat. Sla dit QR code plaatje op en upload het in deze <a href="weixin://dl/scan">QR code scanner</a>.',
    wechatQRCodeError: 'Er is een fout opgetreden bij het ophalen van uw WeChat QR-code. Probeer het opnieuw.',
    fileTooLargeError: 'Bestand is te groot',
    shareLocation: 'Deel locatie',
    uploadDocument: 'Upload document',
    uploadInvalidError: 'Invalide bestand',
    fileTypeError: 'Bestand wordt niet ondersteund',
    formErrorInvalidEmail: 'E-mail is ongeldig',
    formErrorNoLongerThan: 'Mag niet meer dan ({characters}) tekens bevatten',
    formErrorNoShorterThan: 'Moet ten minste ({characters}) tekens bevatten',
    formErrorUnknown: 'Dit ziet er niet helemaal goed uit',
    formFieldSelectPlaceholderFallback: 'Kies er één...',
    uploadPhoto: 'Upload foto',
    uploadVirusError: 'Een virus is gedetecteerd in uw bestand en is geweigerd',
    shoutoutText: 'Start een chat'
};
export { Dutch };
