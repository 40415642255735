import { Helpers } from "../helpers";
import DialogHandler from "./dialog-handler";
import { createElementFromHTML, after } from './utils';

class PostBackHandler {
    constructor({ sunshine }) {
        this.conversation = DialogHandler.conversation;
        this.sunshine = sunshine;
        this.isWebview = this.isWebview.bind(this);
        this.addPostbackLoadingIndicatorClass = this.addPostbackLoadingIndicatorClass.bind(this);
        this.clearLastPostbackLoadingFeedback = this.clearLastPostbackLoadingFeedback.bind(this);
        this.run = this.run.bind(this);
        this.showFeedBackPostback = this.showFeedBackPostback.bind(this);
        this.addFeedBackOnPostBackButtons = this.addFeedBackOnPostBackButtons.bind(this);
    }

    isWebview(row) {
        const message = row.getElementsByClassName('msg-image')[0];
        const id = message.getAttribute('data-for').split('-')[1];
        const sunshineMessage = Array.csFrom(this.sunshine.getConversation().messages).csFind(m => m._id === id);
        if (sunshineMessage
            && sunshineMessage.actions
            && Array.csFrom(sunshineMessage.actions).csFind(a => a.type === 'webview')) {
            return true;
        }
        return false;
    }

    addPostbackLoadingIndicatorClass(event) {
        const row = Helpers.findParentClass(event.target, 'row');
        if (Helpers.hasClass(row, 'send') && !this.isWebview(row)) {
            const sendAt = parseInt(row.getAttribute('data-send-at'), 10);
            const now = new Date().getTime();
            if (now - sendAt < 5000) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                row.setAttribute('data-send-at', new Date().getTime());
            }
        } else {
            row.setAttribute('data-send-at', new Date().getTime());
            Helpers.addClass(row, 'send');
        }
        // remove animations from buttons
        const animations = Array.csFrom(this.conversation.getElementsByClassName('animated'));
        animations.forEach(ani => {
            Helpers.removeClass(ani, 'animated pulse infinite');
        });
        Helpers.addClass(event.target, 'animated pulse infinite');
        // if interface does not change for 10 sec, remove class again
        setTimeout(() => {
            Helpers.removeClass(event.target, 'animated pulse infinite');
        }, 6000);
    }

    clearLastPostbackLoadingFeedback() {
        const animations = Array.csFrom(this.conversation.getElementsByClassName('animated'));
        const msgs = Array.csFrom(this.conversation.getElementsByClassName('row'));
        animations.forEach(ani => {
            const row = Helpers.findParentClass(ani, 'row');
            if (row && msgs.indexOf(row) !== msgs.length - 1) {
                Helpers.removeClass(ani, 'animated pulse infinite');
            }
        });
        const actions = Array.csFrom(this.conversation.getElementsByClassName('action'));
        if (actions.length) {
            actions.forEach(action => {
                action.removeEventListener('click', this.addPostbackLoadingIndicatorClass);
                action.addEventListener('click', this.addPostbackLoadingIndicatorClass);
            });
        }
    }

    showFeedBackPostback(event) {
        const row = Helpers.findParentClass(event.target, 'row');
        if (this.isWebview(row)) return;
        const feedbacks = this.conversation.getElementsByClassName('postbackfeedback');
        const lastFeedback = feedbacks[feedbacks.length - 1];
        const feedbackNode = createElementFromHTML(`
            <div class="row right-row row-appuser-first row-appuser-last postbackfeedback">
                <div class="msg-wrapper">
                    <div class="msg" data-tip="10:44 AM" data-for="tooltip-0.32767012273164453" data-iscapture="true" currentitem="true" style="">
                        <div><div><span><span class="btn">${event.target.innerHTML}</span><br></span></div></div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        `);
        const messages = this.conversation.getElementsByClassName('messages')[0];
        if (messages) {
            if (!lastFeedback || row.nextSibling !== lastFeedback) {
                messages.appendChild(feedbackNode);
                feedbackNode.scrollIntoView();
            }
        }
    }

    addFeedBackOnPostBackButtons() {
        const buttons = Array.csFrom(this.conversation.getElementsByClassName('btn-primary'));
        buttons.forEach(b => {
            b.removeEventListener('click', this.showFeedBackPostback);
            b.addEventListener('click', this.showFeedBackPostback);
        });
    }

    run() {
        after(100, this.clearLastPostbackLoadingFeedback);
        after(100, this.addFeedBackOnPostBackButtons);
    }
}

export default PostBackHandler;
