/* eslint max-len:0 */
const English = {
    _language: 'english',
    _language_short: 'en',
    agentWelkomMessage: 'Welcome. Ask your question here.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Couldn\'t connect. You can {retry}',
    couldNotConnectInner: 'retry connecting now',
    couldNotConnectWithRetry: 'Couldn\'t connect. We\'ll keep retrying, or you can {retry}.',
    couldNotConnectWithRetryInner: 'try now',
    headerText: 'How can we help?',
    imageClickToReload: 'Click to reload image.',
    imageClickToView: 'Click to view {size} image.',
    imagePreviewNotAvailable: 'Preview not available.',
    inputPlaceholder: 'Type here, hit <enter> to send.',
    inputPlaceholderBlocked: 'Complete the form above...',
    introAppText: 'Send your message below or from your favorite channel.',
    introductionText: 'Ask your question here.',
    actionPaymentCompleted: 'Payment received.',
    actionPaymentError: 'An error occurred while processing payment. <br> Please try again or use a different card.',
    actionPostbackError: 'An error occurred while processing your action. Please try again.',
    clickToRetry: 'Message not delivered. Please try again.',
    clickToRetryForm: 'Form not submitted. Click anywhere on the form to retry. ',
    connectNotificationText: 'Await reply or choose which app you would like to continue in.',
    connectNotificationSingleText: 'Await reply or choose which app you would like to continue in.',
    connectNotificationSingleButtonText: 'Turn on <name> notifications',
    connectNotificationOthersText: 'others',
    emailChangeAddress: 'Change my email',
    emailDescription: 'To be notified by email when you get a reply, enter your email address.',
    emailFieldLabel: 'Your email',
    emailFieldPlaceholder: 'Your email address',
    emailFormButton: 'Continue',
    fetchHistory: 'Load more',
    fetchingHistory: 'Retrieving history...',
    frontendEmailChannelDescription: 'Send us a mail and we will get back to you as soon as possible.',
    invalidFileError: 'Only images are supported. Choose a file with a supported extension (jpg, jpeg, png, gif, or bmp).',
    lineChannelDescription: 'Scan QR code to talk to us via LINE.',
    locationNotSupported: 'Your browser does not support location services or it’s been disabled. Please type your location instead.',
    locationSecurityRestriction: 'This website cannot access your location. Please type your location instead.',
    locationSendingFailed: 'Could not send location',
    locationServicesDenied: 'This website cannot access your location. Allow access in your settings or type your location instead.',
    messageError: 'An error occured while sending your message. Please try again.',
    messageIndicatorTitlePlural: '({count}) New messages',
    messageIndicatorTitleSingular: '({count}) New message',
    messageRelativeTimeDay: '{value}d ago',
    messageRelativeTimeHour: '{value}h ago',
    messageRelativeTimeJustNow: 'Just now',
    messageRelativeTimeMinute: '{value}m ago',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Sending...',
    messageDelivered: 'Delivered',
    messengerChannelDescription: 'Connect your Facebook account to continue via Facebook Messenger.',
    whatsappChannelDescriptionDesktop: 'Start or continue your conversation via WhatsApp? Click on the link below.',
    whatsappChannelDescriptionMobile: 'Start or continue your conversation via WhatsApp? Click on the link below.',
    whatsappLinkingError: 'Something went wrong while connecting. Please try again.',
    notificationSettingsChannelsDescription: 'You can also talk to us from your favorite app or service.',
    notificationSettingsChannelsTitle: 'Other apps',
    notificationSettingsConnected: 'Connected',
    notificationSettingsConnectedAs: 'Connected as {username}',
    prechatCaptureGreetingText: 'Hi there 👋\nTo start off, we\'d like to know a little bit more about you:',
    prechatCaptureNameLabel: 'Your name',
    prechatCaptureNamePlaceholder: 'Type your name...',
    prechatCaptureEmailLabel: 'Email',
    prechatCaptureEmailPlaceholder: 'name@company.com',
    prechatCaptureConfirmationText: 'Thanks for that! What can we help you with?',
    prechatCaptureMailgunLinkingConfirmation: 'You\'ll be notified here and by email at {email} once we reply. ',
    sendButtonText: 'Send',
    settingsHeaderText: 'Settings',
    smsBadRequestError: 'We were unable to connect to this number. Try again or use a different number. ',
    smsCancel: 'Cancel',
    smsChangeNumber: 'Change my number',
    smsChannelDescription: 'Connect your SMS-number to continue via SMS.',
    smsChannelPendingDescription: 'Check your messages at {number} to confirm your phone number.',
    smsContinue: 'Continue',
    smsInvalidNumberError: 'Your phone number is not valid. Please try again.',
    smsLinkCancelled: 'Link to {appUserNumber} was cancelled.',
    smsLinkPending: 'Pending',
    smsPingChannelError: 'There was an error sending a message to your number.',
    smsSendText: 'Send me a text',
    smsStartTexting: 'Start Texting',
    smsTooManyRequestsError: 'A connection for that number was requested recently. Please try again in {minutes} minutes.',
    smsTooManyRequestsOneMinuteError: 'A connection for that number was requested recently. Please try again in 1 minute.',
    smsUnhandledError: 'Something went wrong. Please try again.',
    tapToRetry: 'Message not delivered. Press enter to retry.',
    tapToRetryForm: 'Form not submitted. Tap anywhere on the form to retry.',
    telegramChannelDescription: 'Connect your Telegram-account to continue via Telegram',
    unsupportedMessageType: 'Unsupported message type.',
    unsupportedActionType: 'Unsupported action type.',
    linkError: 'An error occurred when attempting to generate a link for this channel. Please try again.',
    viberChannelDescription: 'Connect your Viber-account to continue via Viber',
    viberChannelDescriptionMobile: 'Connect your Viber account to be notified when you get a reply and carry the conversation on Viber. To get started, install the Viber app and tap Connect.',
    viberQRCodeError: 'An error occurred while fetching your Viber QR code. Please try again.',
    wechatChannelDescription: 'Connect your WeChat account to be notified when you get a reply and carry the conversation on WeChat. To get started, scan this QR code using the WeChat app.',
    wechatChannelDescriptionMobile: 'Connect your WeChat account to be notified when you get a reply and carry the conversation on WeChat. To get started, save this QR code image and upload it <a href="weixin://dl/scan">QR code scanner</a>.',
    wechatQRCodeError: 'An error occurred while fetching your WeChat QR code. Please try again.',
    fileTooLargeError: 'Max file size limit exceeded ({size})',
    shareLocation: 'Share location',
    uploadDocument: 'Upload document',
    uploadInvalidError: 'Invalid file',
    fileTypeError: 'Unsupported file type',
    formErrorInvalidEmail: 'Email is invalid',
    formErrorNoLongerThan: 'Must contain no more than ({characters}) characters',
    formErrorNoShorterThan: 'Must contain at least ({characters}) characters',
    formErrorUnknown: 'This doesn\'t look quite right',
    formFieldSelectPlaceholderFallback: 'Choose one... ',
    uploadPhoto: 'Upload photo',
    uploadVirusError: 'A virus was detected in your file and it has been rejected',
    shoutoutText: 'Start a chat',
    cardClickText: 'Selected'
};
export { English };
