/* eslint max-len:0 */
const French = {
    _language: 'french',
    _language_short: 'fr',
    agentWelkomMessage: 'Bienvenue. N\'hésitez pas à poser votre question ici.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Impossible de se connecter. Vous pouvez {retry}',
    couldNotConnectInner: 'réessayez de vous connecter maintenant',
    couldNotConnectWithRetry: 'Impossible de se connecter. Nous continuerons de réessayer, ou vous pouvez {retry}.',
    couldNotConnectWithRetryInner: 'essayez maintenant',
    headerText: 'Besoin d\'aide ? Nous sommes là.',
    imageClickToReload: 'Cliquez pour recharger l\'image.',
    imageClickToView: 'Cliquez pour voir la {size} de l\'image.',
    imagePreviewNotAvailable: 'Aperçu non disponible.',
    inputPlaceholder: 'Tapez ici, appuyez sur <entrée> pour envoyer.',
    inputPlaceholderBlocked: 'Remplissez le formulaire ci-dessus...',
    introAppText: 'Envoyez-nous un message ci-dessous ou depuis votre app favorite.',
    introductionText: 'Posez votre question ici.',
    actionPaymentCompleted: 'Paiement reçu.',
    actionPaymentError: 'Une erreur est survenue pendant le paiement. <br> Veuillez réessayer ou utilisez une autre carte de crédit.',
    actionPostbackError: 'Une erreur est survenue. Veuillez réessayer.',
    clickToRetry: 'Le message n\'a pas été envoyé. Veuillez réessayer.',
    clickToRetryForm: 'Formulaire non soumis. Cliquez n\'importe où sur le formulaire pour réessayer.',
    connectNotificationText: 'Veuillez attendre la réponse ou indiquer avec app vous souhaiter continuer.',
    connectNotificationSingleText: 'Veuillez attendre la réponse ou indiquer avec app vous souhaiter continuer.',
    connectNotificationSingleButtonText: 'Activez les notifications <nom>',
    connectNotificationOthersText: 'Autres',
    emailChangeAddress: 'Modifier mon e-mail',
    emailDescription: 'Afin d\'être averti(e) par e-mail lorsque vous recevez une réponse, veuillez renseigner votre adresse e-mail.',
    emailFieldLabel: 'Votre e-mail',
    emailFieldPlaceholder: 'Votre adresse e-mail',
    emailFormButton: 'Continuez',
    fetchHistory: 'Chargement',
    fetchingHistory: 'Récupération de l\'historique...',
    frontendEmailChannelDescription: 'Envoyez-nous un (e-)mail et nous reviendrons vers vous le plus vite possible.',
    invalidFileError: 'Seules les images sont supportées. Veuillez choisir un fichier avec l\'une des extensions suivantes: jpg, jpeg, png, gif, or bmp.',
    lineChannelDescription: 'Scannez votre code QR afin de nous parler via LINE.',
    locationNotSupported: 'Votre navigateur ne supporte pas de service de géo-localisation ou bien cela a été désactivé. Veuillez plutôt taper votre localisation.',
    locationSecurityRestriction: 'Ce site ne peut avoir accès à votre géo-localisation. Veuillez plutôt taper votre emplacement.',
    locationSendingFailed: 'Votre localisation n\'a pas pu être envoyée.',
    locationServicesDenied: 'Ce site ne peut avoir accès à votre géo-localisation.Veuillez en autoriser l\'accès dans vos paramètres ou bien entrez votre emplacement.',
    messageError: 'Une erreur est survenue pendant l\'envoi du message. Veuillez réessayer.',
    messageIndicatorTitlePlural: '({count}) Nouveaux messages',
    messageIndicatorTitleSingular: '({count}) Nouveau message',
    messageRelativeTimeDay: 'il y a {value} jour(s)',
    messageRelativeTimeHour: 'il y a {value} heure(s)',
    messageRelativeTimeJustNow: 'A l\'instant',
    messageSeen: 'Vu',
    messageRelativeTimeMinute: 'il y a {value} minute(s)',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Envoi...',
    messageDelivered: 'Transmis',
    messengerChannelDescription: 'Veuillez vous connecter à votre compte Facebook afin de continuer avec Facebook Messenger.',
    whatsappChannelDescriptionDesktop: 'Comment débuter ou continuer une conversation via WhatsApp ? Cliquez sur le lien ci-dessous.',
    whatsappChannelDescriptionMobile: 'Comment débuter ou continuer une conversation via WhatsApp ? Cliquez sur le lien ci-dessous.',
    whatsappLinkingError: 'Quelque chose n\'a pas fonctionné. Veuillez réessayer.',
    notificationSettingsChannelsDescription: 'Vous pouvez également communiquer avec nous via votre app ou service favoris.',
    notificationSettingsChannelsTitle: 'Autres apps',
    notificationSettingsConnected: 'Connecté(e)',
    notificationSettingsConnectedAs: 'Connecté(e) en tant que {username}',
    prechatCaptureGreetingText: 'Salut 👋\nPour commencer, nous aimerions en savoir un peu plus sur vous:',
    prechatCaptureNameLabel: 'Votre nom',
    prechatCaptureNamePlaceholder: 'Tapez votre prénom et nom',
    prechatCaptureEmailLabel: 'Email',
    prechatCaptureEmailPlaceholder: 'nom@entreprise.fr',
    prechatCaptureConfirmationText: 'Merci pour ça ! En quoi pouvons-nous vous aider ?',
    prechatCaptureMailgunLinkingConfirmation: 'Vous serez averti ici et par e-mail à {email} une fois que nous aurons répondu. ',
    sendButtonText: 'Envoyer',
    settingsHeaderText: 'Paramètres',
    smsBadRequestError: 'Nous n\'avons pas pu nous connecter à ce numéro. Veuillez essayer avec un numéro différent.',
    smsCancel: 'Annuler',
    smsChangeNumber: 'Modifier mon numéro',
    smsChannelDescription: 'Connecter mon numéro de téléphone pour continuer via SMS.',
    smsChannelPendingDescription: 'Veuillez consulter vos messages au {number} afin de vérifier votre numéro de téléphone.',
    smsContinue: 'Continuer',
    smsInvalidNumberError: 'Votre numéro n\'est pas valide. Veuillez réessayer.',
    smsLinkCancelled: 'Lien vers {appUserNumber} a été annulé',
    smsLinkPending: 'En attente',
    smsPingChannelError: 'Une erreur est survenue pendant l\'envoi d\'un message à votre numéro de téléphone.',
    smsSendText: 'Envoyez-moi un texto',
    smsStartTexting: 'Commencer les textos',
    smsTooManyRequestsError: 'Une connexion avec ce numéro a déjà été demandée. Veuillez réessayer dans {minutes} minutes.',
    smsTooManyRequestsOneMinuteError: 'Une connexion avec ce numéro a déjà été demandée. Veuillez réessayer dans 1 minute.',
    smsUnhandledError: 'Quelque chose n\'a pas fonctionné. Veuillez réessayer.',
    tapToRetry: 'Message non reçu. Appuyez sur Entrée pour réessayer.',
    tapToRetryForm: 'Formulaire non soumis. Appuyez n\'importe où sur le formulaire pour réessayer.',
    telegramChannelDescription: 'Connectez-vous à votre compte Telegram pour continuer via Telegram.',
    unsupportedMessageType: 'Type de message non supporté.',
    unsupportedActionType: 'Type d\'action non supportée.',
    linkError: 'Une erreur est survenue pendant la création d\'un lien vers ce canal. Veuillez réessayer.',
    viberChannelDescription: 'Veuillez vous connecter à votre compte Viber pour continuer via Viber.',
    viberChannelDescriptionMobile: 'Veuillez vous connecter à votre compte Viber pour être informé(e) quand vous recevez une réponse et pour continuer la conversation sur Viber. Pour commencer, veuillez installer l\'app Viber et cliquez sur Connecter.',
    viberQRCodeError: 'Une erreur est survenue pendant la récupération de votre code QR. Veuillez réessayer.',
    wechatChannelDescription: 'Veuillez vous connecter à votre compte WeChat pour être informé(e) quand vous recevez une réponse et pour continuer la conversation sur WeChat. Pour commencer, veuillez scanner votre code QR en utilisant l\'app WeChat.',
    wechatChannelDescriptionMobile: 'Veuillez vous connecter à votre compte WeChat pour être informé(e) quand vous recevez une réponse et pour continuer la conversation sur WeChat. Pour commencer, veuillez enregistrer votre code QR et le charger avec <a href="weixin://dl/scan">QR code scanner</a>.',
    wechatQRCodeError: 'Une erreur s\'est produite lors de la récupération de votre code QR WeChat. Veuillez réessayer.',
    fileTooLargeError: 'Taille de fichier maximum dépassée',
    shareLocation: 'Partage de localisation',
    uploadDocument: 'Téléchargement du document',
    uploadInvalidError: 'Fichier invalide',
    fileTypeError: 'Type de fichier non supporté',
    formErrorInvalidEmail: 'Le courriel est invalide',
    formErrorNoLongerThan: 'Ne doit pas contenir plus de ({characters}) caractères',
    formErrorNoShorterThan: 'Doit contenir au moins ({characters}) caractères',
    formErrorUnknown: 'Cela ne semble pas tout à fait correct',
    formFieldSelectPlaceholderFallback: 'Choisissez-en un...',
    uploadPhoto: 'Téléchargement de la photo',
    uploadVirusError: 'Un virus a été détecté dans votre fichier. Il sera donc sera rejeté',
    shoutoutText: 'Démarrez le Chat'
};
export { French };
