/* eslint max-len:0 */
const German = {
    _language: 'german',
    _language_short: 'de',
    agentWelkomMessage: 'Willkommen! Wenn Sie eine Frage haben, helfen wir Ihnen im Live Chat gerne weiter.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Verbindung konnte nicht hergestellt worden. Sie können es {retry}',
    couldNotConnectInner: 'Nochmals versuchen die Verbindung herzustellen',
    couldNotConnectWithRetry: 'Verbindung konnte nicht hergestellt worden. Wir versuchen es erneut für Sie, oder Sie können es {retry}',
    couldNotConnectWithRetryInner: 'Jetzt versuchen',
    headerText: 'Brauchen Sie Hilfe? Wir sind für Sie da.',
    imageClickToReload: 'Klicken Sie auf das Bild, um es neu zu laden.',
    imageClickToView: 'Hier klicken um das Bild anzuzeigen. ',
    imagePreviewNotAvailable: 'Vorschau nicht verfügbar',
    inputPlaceholder: 'Bitte schreiben Sie hier.',
    inputPlaceholderBlocked: 'Füllen Sie das oben stehende Formular aus...',
    introAppText: 'Senden Sie unten Ihre Nachricht oder wählen Sie Ihren bevorzugten Kanal.',
    introductionText: 'Stellen Sie hier Ihre Frage.',
    actionPaymentCompleted: 'Sie haben bezahlt.',
    actionPaymentError: 'Bei der Bezahlung ist ein Fehler aufgetreten <br> Bitte versuchen Sie es erneut',
    actionPostbackError: 'Es ist ein Fehler aufgetreten <br> Bitte versuchen Sie es erneut.',
    clickToRetry: 'Nachricht nicht versendet. Bitte versuchen Sie es erneut.',
    clickToRetryForm: '"Formular nicht übermittelt. Klicken Sie auf eine beliebige Stelle des Formulars, um es erneut zu versuchen.',
    connectNotificationText: 'Warten Sie auf Ihre Antwort oder geben Sie an, mit welcher App Sie weiter chatten möchten.',
    connectNotificationSingleText: 'Warten Sie auf Ihre Antwort oder geben Sie an, mit welcher App Sie weiter chatten möchten.',
    connectNotificationSingleButtonText: 'Einstellungen <name> Benachrichtigungen',
    connectNotificationOthersText: 'andere',
    emailChangeAddress: 'Meine E-Mail ändern',
    emailDescription: 'Um per E-Mail benachrichtigt zu werden, wenn Sie eine Antwort erhalten, geben Sie Ihre E-Mail-Adresse ein.',
    emailFieldLabel: 'Ihre E-Mail',
    emailFieldPlaceholder: 'Ihre Email-Adresse',
    emailFormButton: 'Weiter',
    fetchHistory: 'Mehr laden',
    fetchingHistory: 'Rufen Sie den Nachrichtenverlauf auf',
    frontendEmailChannelDescription: 'Schicken Sie uns eine Nachricht und wir nehmen so schnell wie möglich Kontakt mit Ihnen auf',
    invalidFileError: 'Nur Bilder werden unterstützt. Wählen Sie eine Datei mit einer unterstützten Erweiterung (jpg, jpeg, png, gif oder bmp).',
    lineChannelDescription: 'Scannen Sie den QR Code um die LINE App zu verwenden.',
    locationNotSupported: 'Ihr GPS ist ausgeschaltet. Geben Sie Ihren Standort an.',
    locationSecurityRestriction: 'Ihr GPS ist ausgeschaltet. Geben Sie Ihren Standort an.',
    locationSendingFailed: 'Ihr Standort konnte nicht gefunden werden.',
    locationServicesDenied: 'Die Website kann Ihren Standort nicht erkennen. Geben Sie usn den Zugang oder geben Sie Ihren Standort ein.',
    messageError: 'Beim Versenden der Nachricht ist ein Fehler aufgetreten, bitte versuchen Sie es erneut',
    messageIndicatorTitlePlural: '({count}) neue Nachrichten',
    messageIndicatorTitleSingular: '({count}) neue Nachrichten',
    messageRelativeTimeDay: 'vor {value} Tag(en)',
    messageRelativeTimeHour: 'vor {value} Stunden',
    messageRelativeTimeJustNow: 'Gerade eben',
    messageSeen: 'Gesehen',
    messageRelativeTimeMinute: 'vor {value} Minuten',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Wird verschickt...',
    messageDelivered: 'Gesendet',
    messengerChannelDescription: 'Verbinden Sie sich mit Ihrem Facebook-Konto ein, um mit dem Facebook Messenger fortzufahren.',
    whatsappChannelDescriptionDesktop: 'Gespräch über WhatsApp starten oder fortsetzen? Klicken Sie auf den untenstehenden Link',
    whatsappChannelDescriptionMobile: 'Gespräch über WhatsApp starten oder fortsetzen? Klicken Sie auf den untenstehenden Link',
    whatsappLinkingError: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    notificationSettingsChannelsDescription: 'Sie können uns auch mit Ihrer bevorzugten App erreichen.',
    notificationSettingsChannelsTitle: 'Andere Apps',
    notificationSettingsConnected: 'Verbunden',
    notificationSettingsConnectedAs: 'Verbunden als {username}',
    prechatCaptureGreetingText: 'Hallo 👋\nZu Beginn möchten wir eine Dinge über Sie erfahren:',
    prechatCaptureNameLabel: 'Ihren Namen',
    prechatCaptureNamePlaceholder: 'Geben Sie Ihren Namen ein...',
    prechatCaptureEmailLabel: 'E-Mail Adresse',
    prechatCaptureEmailPlaceholder: 'name@firma.com',
    prechatCaptureConfirmationText: 'Vielen Dank! Womit können wir Ihnen helfen? ',
    prechatCaptureMailgunLinkingConfirmation: 'Sie werden hier und per E-Mail {email} benachrichtigt, sobald wir antworten',
    sendButtonText: 'Senden',
    settingsHeaderText: 'Einstellungen',
    smsBadRequestError: 'Keine Verbindung. Versuchen Sie es erneut oder benutzen Sie eine andere Telefonnummer. ',
    smsCancel: 'Abbrechen',
    smsChangeNumber: 'Meine Nummer ändern.',
    smsChannelDescription: 'Verbinden Sie Ihre SMS-Nummer um mit SMS fortzufahren.',
    smsChannelPendingDescription: 'Überprüfen Sie Ihre Nachrichten {number}, um Ihre Telefonnummer zu bestätigen. ',
    smsContinue: 'Weiter',
    smsInvalidNumberError: 'Ihre Telefonnummer ist ungültig. Versuchen Sie erneut.',
    smsLinkCancelled: 'Die Verbindung {appUserNumber} ist ungültig. Versuchen Sie es erneut. ',
    smsLinkPending: 'Warten...',
    smsPingChannelError: 'Beim Senden der Nachricht ist ein Fehler aufgetreten.',
    smsSendText: 'Senden Sie mir eine Nachricht.',
    smsStartTexting: 'Beginnen Sie zu schreiben.',
    smsTooManyRequestsError: ' Eine Verbindung für diese Nummer wurde kürzlich angefordert. Bitte versuchen Sie es erneut in {Minuten} Minuten.',
    smsTooManyRequestsOneMinuteError: ' Eine Verbindung für diese Nummer wurde kürzlich angefordert. Bitte versuchen Sie es erneut in 1 Minute.',
    smsUnhandledError: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    tapToRetry: 'Nachricht nicht zugestellt. Versuchen Sie es erneut. ',
    tapToRetryForm: 'Formular nicht übermittelt. Klicken Sie auf eine beliebige Stelle des Formulars, um es erneut zu versuchen.',
    telegramChannelDescription: 'Verbinden Sie Ihr Telegram-Konto um mit Telegram fortzufahren.',
    unsupportedMessageType: ' Nicht unterstützter Nachrichtentyp',
    unsupportedActionType: 'Nicht unterstützte Aktion',
    linkError: 'Beim Versuch, eine Verknüpfung für diesen Kanal zu erstellen, ist ein Fehler aufgetreten. Bitte versuche es erneut.',
    viberChannelDescription: 'Verbinden Sie Ihr Viber-Konto, um über die App fortzufahren.',
    viberChannelDescriptionMobile: 'Verbinden Sie Ihr Viber-Konto, um benachrichtigt zu werden, wenn Sie eine Antwort erhalten und führen Sie die Unterhaltung in der App fort. Installieren Sie zunächst die Viber App und tippen Sie auf Verbinden.',
    viberQRCodeError: 'Beim Abrufen Ihres Viber-QR-Codes ist ein Fehler aufgetreten. Bitte versuche es erneut.',
    wechatChannelDescription: 'Verbinden Sie Ihr WeChat-Konto, um benachrichtigt zu werden, wenn Sie eine Antwort erhalten und führen Sie die Unterhaltung in der App fort. Scannen Sie zunächst diesen QR-Code, um eine Verbindung mit der WeChat App herzustellen.',
    wechatChannelDescriptionMobile: 'Verbinden Sie Ihr WeChat-Konto, um benachrichtigt zu werden, wenn Sie eine Antwort erhalten und führen Sie die Unterhaltung in der App fort. Speichern Sie dieses QR-Code-Bild und laden Sie es <a href="weixin: //dl/scan"> QR-Code-Scanner </a> hoch.',
    wechatQRCodeError: 'Beim Abrufen Ihres WeChat-QR-Codes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    fileTooLargeError: 'Die maximale Dateigröße wurde überschritten.',
    shareLocation: 'Standortteilen ',
    uploadDocument: 'Dokument hochladen',
    uploadInvalidError: 'Ungültiges Format',
    fileTypeError: 'Nicht unterstütztes Dateiformat',
    formErrorInvalidEmail: 'E-Mail Adresse ungültig',
    formErrorNoLongerThan: 'Darf nicht mehr als ({characters}) Zeichen enthalten',
    formErrorNoShorterThan: 'Muss mindestens ({characters}) Zeichen enthalten',
    formErrorUnknown: 'Das sieht nicht ganz richtig aus',
    formFieldSelectPlaceholderFallback: 'Wählen Sie ein{{SLASH}}eine{{SLASH}}einen  ... ',
    uploadPhoto: 'Foto hochladen',
    uploadVirusError: 'In Ihrer Datei wurde ein Virus gefunden, deswegen wurde Sie abgelehnt.',
    shoutoutText: 'Beginnen Sie einen Chat'
};
export { German };
