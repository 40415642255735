/* eslint max-len:0 */
const Arabic = {
    _language: 'arabic',
    _language_short: 'ar',
    agentWelkomMessage: 'مرحبًا. اطرح سؤالك هنا.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'تعذر الاتصال. يمكنك {retry}',
    couldNotConnectInner: 'أعد المحاولة للاتصال الآن',
    couldNotConnectWithRetry: 'تعذر الاتصال. سنواصل المحاولة، أو يمكنك {retry}.',
    couldNotConnectWithRetryInner: 'حاول الآن',
    headerText: 'كيف يمكننا المساعدة؟',
    imageClickToReload: 'انقر لإعادة تحميل الصورة.',
    imageClickToView: 'انقر لعرض الصورة {size}.',
    imagePreviewNotAvailable: 'معاينة غير متاحة.',
    inputPlaceholder: 'اكتب هنا، اضغط <enter> للإرسال.',
    inputPlaceholderBlocked: 'أكمل النموذج أعلاه...',
    introAppText: 'أرسل رسالتك أدناه أو من القناة المفضلة لديك.',
    introductionText: 'اسأل سؤالك هنا.',
    actionPaymentCompleted: 'تم استلام الدفعة.',
    actionPaymentError: 'حدث خطأ أثناء معالجة الدفعة. <br> يرجى المحاولة مرة أخرى أو استخدام بطاقة مختلفة.',
    actionPostbackError: 'حدث خطأ أثناء معالجة الإجراء الخاص بك. يرجى المحاولة مرة أخرى.',
    clickToRetry: 'لم يتم تسليم الرسالة. يرجى المحاولة مرة أخرى.',
    clickToRetryForm: 'لم يتم إرسال النموذج. انقر في أي مكان على النموذج لإعادة المحاولة. ',
    connectNotificationText: 'انتظر الرد أو اختر التطبيق الذي ترغب في الاستمرار فيه.',
    connectNotificationSingleText: 'انتظر الرد أو اختر التطبيق الذي ترغب في الاستمرار فيه.',
    connectNotificationSingleButtonText: 'تشغيل إشعارات <name>',
    connectNotificationOthersText: 'آخرين',
    emailChangeAddress: 'تغيير بريدي الإلكتروني',
    emailDescription: 'ليتم إشعارك عبر البريد الإلكتروني عندما تحصل على رد، أدخل عنوان بريدك الإلكتروني.',
    emailFieldLabel: 'بريدك الإلكتروني',
    emailFieldPlaceholder: 'عنوان بريدك الإلكتروني',
    emailFormButton: 'متابعة',
    fetchHistory: 'تحميل المزيد',
    fetchingHistory: 'جاري استرجاع السجل...',
    frontendEmailChannelDescription: 'أرسل لنا بريدًا وسنعاود الاتصال بك في أقرب وقت ممكن.',
    invalidFileError: 'يتم دعم الصور فقط. اختر ملفًا بامتداد مدعوم (jpg، jpeg، png، gif، أو bmp).',
    lineChannelDescription: 'مسح رمز الاستجابة السريعة للتحدث إلينا عبر LINE.',
    locationNotSupported: 'متصفحك لا يدعم خدمات الموقع أو تم تعطيلها. يرجى كتابة موقعك بدلاً من ذلك.',
    locationSecurityRestriction: 'لا يمكن لهذا الموقع الوصول إلى موقعك. يرجى كتابة موقعك بدلاً من ذلك.',
    locationSendingFailed: 'تعذر إرسال الموقع',
    locationServicesDenied: 'لا يمكن لهذا الموقع الوصول إلى موقعك. السماح بالوصول في الإعدادات الخاصة بك أو كتابة موقعك بدلاً من ذلك.',
    messageError: 'حدث خطأ أثناء إرسال رسالتك. يرجى المحاولة مرة أخرى.',
    messageIndicatorTitlePlural: '({count}) رسائل جديدة',
    messageIndicatorTitleSingular: '({count}) رسالة جديدة',
    messageRelativeTimeDay: 'قبل {value} يوم',
    messageRelativeTimeHour: 'قبل {value} ساعة',
    messageRelativeTimeJustNow: 'الآن',
    messageRelativeTimeMinute: 'قبل {value} دقيقة',
    messageTimestampFormat: 'h:mm ص',
    messageSending: 'جاري الإرسال...',
    messageDelivered: 'تم التوصيل',
    messengerChannelDescription: 'قم بتوصيل حسابك على فيسبوك للمتابعة عبر فيسبوك مسنجر.',
    whatsappChannelDescriptionDesktop: 'هل تريد بدء أو متابعة محادثتك عبر WhatsApp؟ انقر على الرابط أدناه.',
    whatsappChannelDescriptionMobile: 'هل تريد بدء أو متابعة محادثتك عبر WhatsApp؟ انقر على الرابط أدناه.',
    whatsappLinkingError: 'حدث خطأ أثناء الاتصال. يرجى المحاولة مرة أخرى.',
    notificationSettingsChannelsDescription: 'يمكنك أيضًا التحدث معنا من تطبيقك المفضل أو الخدمة.',
    notificationSettingsChannelsTitle: 'تطبيقات أخرى',
    notificationSettingsConnected: 'متصل',
    notificationSettingsConnectedAs: 'متصل كـ {username}',
    prechatCaptureGreetingText: 'مرحبًا 👋\nللبدء، نود أن نعرف القليل عنك:',
    prechatCaptureNameLabel: 'اسمك',
    prechatCaptureNamePlaceholder: 'اكتب اسمك...',
    prechatCaptureEmailLabel: 'البريد الإلكتروني',
    prechatCaptureEmailPlaceholder: 'name@company.com',
    prechatCaptureConfirmationText: 'شكرًا لذلك! كيف يمكننا مساعدتك؟',
    prechatCaptureMailgunLinkingConfirmation: 'سيتم إعلامك هنا وعبر البريد الإلكتروني على {email} بمجرد الرد.',
    sendButtonText: 'إرسال',
    settingsHeaderText: 'الإعدادات',
    smsBadRequestError: 'تعذر الاتصال بهذا الرقم. حاول مرة أخرى أو استخدم رقمًا مختلفًا. ',
    smsCancel: 'إلغاء',
    smsChangeNumber: 'تغيير رقمي',
    smsChannelDescription: 'قم بتوصيل رقم الـ SMS الخاص بك للمتابعة عبر الرسائل النصية.',
    smsChannelPendingDescription: 'تحقق من رسائلك على {number} لتأكيد رقم هاتفك.',
    smsContinue: 'متابعة',
    smsInvalidNumberError: 'رقم هاتفك غير صالح. يرجى المحاولة مرة أخرى.',
    smsLinkCancelled: 'تم إلغاء الرابط إلى {appUserNumber}.',
    smsLinkPending: 'قيد الانتظار',
    smsPingChannelError: 'حدث خطأ أثناء إرسال رسالة إلى رقمك.',
    smsSendText: 'أرسل لي رسالة نصية',
    smsStartTexting: 'بدء الرسائل النصية',
    smsTooManyRequestsError: 'تم طلب الاتصال بذلك الرقم مؤخرًا. يرجى المحاولة مرة أخرى في {minutes} دقائق.',
    smsTooManyRequestsOneMinuteError: 'تم طلب الاتصال بذلك الرقم مؤخرًا. يرجى المحاولة مرة أخرى في دقيقة واحدة.',
    smsUnhandledError: 'حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى.',
    tapToRetry: 'لم يتم تسليم الرسالة. اضغط على enter لإعادة المحاولة.',
    tapToRetryForm: 'لم يتم إرسال النموذج. اضغط في أي مكان على النموذج لإعادة المحاولة.',
    telegramChannelDescription: 'قم بتوصيل حسابك على تيليجرام للمتابعة عبر تيليجرام',
    unsupportedMessageType: 'نوع الرسالة غير مدعوم.',
    unsupportedActionType: 'نوع الإجراء غير مدعوم.',
    linkError: 'حدث خطأ أثناء محاولة إنشاء رابط لهذه القناة. يرجى المحاولة مرة أخرى.',
    viberChannelDescription: 'قم بتوصيل حسابك على فيبر للمتابعة عبر فيبر',
    viberChannelDescriptionMobile: 'قم بتوصيل حساب فيبر الخاص بك لتلقي الردود ومتابعة المحادثة على فيبر. للبدء، قم بتثبيت تطبيق فيبر واضغط على توصيل.',
    viberQRCodeError: 'حدث خطأ أثناء جلب رمز الاستجابة السريعة لفيبر الخاص بك. يرجى المحاولة مرة أخرى.',
    wechatChannelDescription: 'قم بتوصيل حسابك على WeChat لتلقي الردود ومتابعة المحادثة على WeChat. للبدء، قم بمسح رمز الاستجابة السريعة هذا باستخدام تطبيق WeChat.',
    wechatChannelDescriptionMobile: 'قم بتوصيل حسابك على WeChat لتلقي الردود ومتابعة المحادثة على WeChat. للبدء، احفظ صورة رمز الاستجابة السريعة هذه وقم بتحميلها <a href="weixin://dl/scan">ماسح الرموز الاستجابة السريعة</a>.',
    wechatQRCodeError: 'حدث خطأ أثناء جلب رمز الاستجابة السريعة لـ WeChat الخاص بك. يرجى المحاولة مرة أخرى.',
    fileTooLargeError: 'تم تجاوز الحد الأقصى لحجم الملف ({size})',
    shareLocation: 'مشاركة الموقع',
    uploadDocument: 'تحميل مستند',
    uploadInvalidError: 'ملف غير صالح',
    fileTypeError: 'نوع الملف غير مدعوم',
    formErrorInvalidEmail: 'البريد الإلكتروني غير صالح',
    formErrorNoLongerThan: 'يجب ألا يحتوي على أكثر من ({characters}) أحرف',
    formErrorNoShorterThan: 'يجب أن يحتوي على الأقل ({characters}) أحرف',
    formErrorUnknown: 'هذا لا يبدو صحيحًا تمامًا',
    formFieldSelectPlaceholderFallback: 'اختر واحدًا... ',
    uploadPhoto: 'تحميل صورة',
    uploadVirusError: 'تم الكشف عن فيروس في ملفك وتم رفضه',
    shoutoutText: 'ابدأ محادثة'
};
export { Arabic };
