/*global TARGET_DOMAIN*/
/* eslint max-len:0 */
import 'regenerator-runtime/runtime';
import '../polyfills';
import get from 'lodash/get';

import {
    Helpers,
    Platform,
    ErrorHandler,
    SmoochLoader
} from '../helpers';
import { I18N } from '../languages';
import PostBackHandler from './postback-handler';
import { after, log, randomAgents, hashFromDocumentUrl } from './utils';
import OpenByHandler from './openby-handler';
import PreChatHandler from './prechat-handler';
import CustomerSatisfactionHandler from './csat-handler';
import EyeCatcherHandler from './eyecatcher-handler';
import ShoutoutHandler from './shoutout-handler';
import AnalyticsHandler from './analytics-handler';
import SettingsHandler from './settings-handler';
import StyleHandler from './style-handler';
import MessageHandler from './message-handler';
import DialogHandler from './dialog-handler';
import SunshineHandler from './sunshine-handler';


// all functions of sunshine to map to window.web1on1 after loading
const functionsToMapAfterInit = [
    'on', 'render', 'open', 'close', 'isOpened', 'login', 'logout', 'sendMessage', 'startTyping', 'stopTyping',
    'triggerPostback', 'updateUser', 'getUser', 'getConversation', 'loadConversation', 'markAllAsRead',
    'showNotificationChannelPrompt', 'setPredefinedMessage', 'setDelegate'
];


const Base = (sunshine, customerFactory) => {
    SettingsHandler.setSunshine(sunshine);
    // helper domnodes
    let frame;
    let header;
    let footer;
    let button;
    let wrapper;
    let container;
    let conversation;
    // parameters passed from url
    const language = SettingsHandler.settings.customText;

    // the api that will be accesible by the customer script
    let exposedAPI = {};

    const { getSetting } = SettingsHandler;


    // default intopane with agent icons
    const modifyIntroPaneToShowAgents = () => {
        const introPane = wrapper.getElementsByClassName('intro-pane')[0];
        introPane.innerHTML = randomAgents();
    };

    // used to send info about website to CS
    const echo = path => get(window, path);

    // we start when web1on1.init is ready with promise as on('ready') is only working after init is ready.
    const init = callback => {
        log('widget init');
        return new Promise((resolve, reject) => {
            try {
                ({
                    frame, header, footer, wrapper, container, conversation, button
                } = DialogHandler);
                // we find the frame that holds the web-messenger
                frame = DialogHandler.getWebmessengerFrameDocument();
                const title = getSetting('title');
                if (title) {
                    DialogHandler.getWebmessengerFrame().setAttribute('title', title);
                }

                // and expose it to the customer script
                exposedAPI.frame = frame;

                // and some important dom nodes that we will work with later
                exposedAPI.header = header;
                exposedAPI.footer = footer;
                exposedAPI.container = container;
                exposedAPI.conversation = conversation;
                exposedAPI.button = button;

                window.addEventListener('destroyWidget', ShoutoutHandler.closeShoutout);

                const postBackHandler = new PostBackHandler({ sunshine });
                const preChatHandler = new PreChatHandler({
                    getSetting, sunshine, Storage: SettingsHandler.Storage, language
                });
                const customerSatisfactionHandler = new CustomerSatisfactionHandler({
                    getSetting, sunshine, Storage: SettingsHandler.Storage
                });

                window.addEventListener('destroyWidgetLeave', (event) => {
                    window.removeEventListener(
                        'beforeunload',
                        AnalyticsHandler.feedbackAnalytics(event, 'page_leave', {})
                    );
                });
                if (SettingsHandler.getSetting('googleAnalyticsMeasurementId')) {
                    AnalyticsHandler.initGoogleAnalytics();
                }

                window.addEventListener('openWidget', DialogHandler.openWidget);

                const styleHandler = new StyleHandler({
                    getSetting, Platform
                });
                styleHandler.init();

                // register if user opened or closed mannually
                [header, button].forEach(clickable => {
                    if (clickable) { // button only available when selected
                        clickable.addEventListener('click', () => {
                            SettingsHandler.Storage.set(
                                'widget_customer_clicked_header',
                                true,
                                SettingsHandler.cookieLivetime
                            );
                        });
                    }
                });

                // set language on widget
                DialogHandler.setLanguageOnWidget();
                // run customer script initialisation
                if (customerFactory && getSetting('customerLoaded')) {
                    const customer = customerFactory(exposedAPI, getSetting);
                    customer.init(SettingsHandler.settings);
                    exposedAPI.customer = customer;
                }


                if (getSetting('fakeAgents')) modifyIntroPaneToShowAgents();

                // add link to web1on1 on 'powered by'
                DialogHandler.addLinkToLogo();

                // add a disclaimer link under 'powered by'
                DialogHandler.addDisclaimerIfConfigured();


                // add the eye catcher if configured
                EyeCatcherHandler.addEyeCatherIfConfigured();

                // remove link text as is confusing (patchWhatsAppConnect is deprecated)
                if (getSetting('patchWhatsAppConnect', getSetting('patchChannels', true))) DialogHandler.patchChannelConnectLink();

                if (getSetting('spa')) DialogHandler.enableSPAWidgetReloading();
                // by closing (it is hidden, so the cloing is not shown)
                // sunshine updates the styles correctly for bar,
                // button does not have this problem
                if (StyleHandler.displayStyle === 'bar'
                    && sunshine
                    && sunshine.close
                    && typeof sunshine.close === 'function'
                ) sunshine.close();

                OpenByHandler.addOpenByIdEvents();
                OpenByHandler.addOpenByClassEvents();
                DialogHandler.addCustomerDefinedTriggers();

                const sunshineHandler = new SunshineHandler({
                    sunshine, postBackHandler, styleHandler, preChatHandler, customerSatisfactionHandler, Platform
                });
                sunshineHandler.init();

                if (getSetting('embedded')) {
                    // fix position
                    const fr = DialogHandler.getWebmessengerFrame();
                    Helpers.addClass(fr, 'embedded');
                    // fullscreen embedded has
                    // green checkbox on and a textarea with rows=5
                    // this should be fixed
                    DialogHandler.fixEmbeddedStartScreen();
                    preChatHandler.sendToPrechatCaptureBotIfConfiguredAndReturningCustomer();

                    // Fullscreen embedded on mobile needs
                    // .web1on1_close to be removed
                    DialogHandler.showWidget();
                }
                after(5000, preChatHandler.removeAbdundantPrechatCaptureCheckIcon);
                const isClosedByCustomer = !!SettingsHandler.Storage.get('widget_closed_by_customer');
                if (isClosedByCustomer && getSetting('rehide')) {
                    DialogHandler.hideWidget();
                // } else if (!isClosedByCustomer && getSetting('hide')) {
                } else if (getSetting('hide')) {
                    DialogHandler.hideWidget();
                }
                // ready with all widget changes, we can show the widget by
                // unhiding the widget div inside the iframe
                DialogHandler.showWidgetAfterLoading();

                // if the passed params contain a text field, we will send this as a contact message
                // this way you can send bot start command via an url param.
                AnalyticsHandler.checkAnalytics();
                DialogHandler.checkOpenState();
                Object.assign(exposedAPI, {
                    addStyleText: StyleHandler.addStyleText,
                    addStyle: StyleHandler.addStyle,
                    showWidget: DialogHandler.showWidget,
                    openWidget: DialogHandler.openWidget,
                    openEyeCatcher: EyeCatcherHandler.addEyeCatherIfConfigured.bind(this, true),
                    hideWidget: DialogHandler.hideWidget,
                    openAfterIfNoConversationYet: DialogHandler.openAfterIfNoConversationYet,
                    sunshine,
                    setUploadButtonColor: StyleHandler.setUploadButtonColor,
                    feedbackAnalytics: AnalyticsHandler.feedbackAnalytics,
                    processServerCommand: MessageHandler.processServerCommand,
                    closeShoutout: ShoutoutHandler.closeShoutout,
                    sendClientMessage: MessageHandler.sendClientMessage,
                    showFakeAgentMessage: MessageHandler.showFakeAgentMessage,
                    platform: Platform.platform,
                    setCookie: SettingsHandler.Storage.set,
                    getCookie: SettingsHandler.Storage.get,
                    eraseCookie: SettingsHandler.Storage.delete,
                    openShoutout: ShoutoutHandler.openShoutout,
                    errorHandler: ErrorHandler,
                    hashFromDocumentUrl,
                    echo
                });
                if (callback && typeof callback === 'function') {
                    callback(exposedAPI);
                } else {
                    resolve(exposedAPI);
                }
            } catch (e) {
                ErrorHandler.handleError(e);
                reject(e);
            }
        });
    };

    // only return your exposed functions
    // and some DOM nodes will be added later by init.
    exposedAPI = {
        // initialisation
        init,
        addStyleText: StyleHandler.addStyleText,
        addStyle: StyleHandler.addStyle,
        showWidget: DialogHandler.showWidget,
        openWidget: DialogHandler.openWidget,
        openEyeCatcher: EyeCatcherHandler.addEyeCatherIfConfigured.bind(this, true),
        hideWidget: DialogHandler.hideWidget,
        openAfterIfNoConversationYet: DialogHandler.openAfterIfNoConversationYet,
        sunshine,
        setUploadButtonColor: StyleHandler.setUploadButtonColor,
        feedbackAnalytics: AnalyticsHandler.feedbackAnalytics,
        processServerCommand: MessageHandler.processServerCommand,
        closeShoutout: ShoutoutHandler.closeShoutout,
        sendClientMessage: MessageHandler.sendClientMessage,
        showFakeAgentMessage: MessageHandler.showFakeAgentMessage,
        platform: Platform.platform,
        setCookie: SettingsHandler.Storage.set,
        getCookie: SettingsHandler.Storage.get,
        eraseCookie: SettingsHandler.Storage.delete,
        openShoutout: ShoutoutHandler.openShoutout,
        errorHandler: ErrorHandler,
        hashFromDocumentUrl,
        echo,
        frame: DialogHandler.frame
        // available helpers
        // some DOM nodes for easy reference are added later by init like
        // frame, header, wrapper, conversation
    };
    return exposedAPI;
};


const getLanguage = language => {
    try {
        return I18N.setLanguage(language);
    } catch (e) {
        return {};
    }
};


const initZendesk = async () => {
    const { settings } = SettingsHandler;
    log('initZendesk', settings);
    let initializationError;
    // error is happening in a callback in smooch codebase it's not catchable normally.
    // error thrown from body of initZendesk to make it catchable.
    await window[`${TARGET_DOMAIN}Loader`].init(settings).catch(error => {
        initializationError = error;
    });
    if (initializationError) {
        throw initializationError;
    }

    if (settings.noWrapper) {
        log('no wrapper');
        return Promise.resolve();
    }
    // map sunshine functions to window.web1on1 in load
    functionsToMapAfterInit.forEach(f => {
        if (window[`${TARGET_DOMAIN}Loader`] && window[`${TARGET_DOMAIN}Loader`][f]) {
            window[TARGET_DOMAIN][f] = window[`${TARGET_DOMAIN}Loader`][f];
        }
    });
    window[TARGET_DOMAIN].settings = settings;
    const id = (document.location.href.match(/id=([^$&]+)/) || [])[1];
    const token = (document.location.href.match(/token=([^$&]+)/) || [])[1];
    if (id && token) {
        // existing smooch contact. try to login
        // eslint-disable-next-line
            let decodedId;
        try {
            decodedId = atob(decodeURIComponent(id));
            log('init: trying to login with', decodedId, token);
            await window[TARGET_DOMAIN].login(decodedId, decodeURIComponent(token));
            log('init: Contact conversation loaded:', window[TARGET_DOMAIN].getUser());
        } catch (e) {
            log('init: could not log in, continue as new contact', e);
        }
    }

    log('init: now lets initialize our own wrapper...');
    if (settings.noWrapper) {
        log('no wrapper');
        return null;
    }
    const api = await Base(window[`${TARGET_DOMAIN}Loader`], window[TARGET_DOMAIN].customer).init();

    // send platform to conversation.meta via smooch
    if (window[TARGET_DOMAIN].updateUser && typeof window[TARGET_DOMAIN].updateUser === 'function') {
        window[TARGET_DOMAIN].updateUser({ properties: { platform: Platform.platform() } });
    }
    window[TARGET_DOMAIN].api = api;
    if (settings.callback && typeof settings.callback === 'function') {
        try {
            settings.callback(api);
        } catch (custError) {
            log('init: customer callback failed', custError);
        }
    }
    return api;


};

const init = async initConfig => {
    log('init', initConfig);
    // init can only be called once;
    if (window[TARGET_DOMAIN].initPromise) {
        // eslint-disable-next-line
        if (!PRODUCTION) log('init already called, returning old promise');
        return window[TARGET_DOMAIN].initPromise;
    }
    // patch mercedes appid;
    if (initConfig.appId === '5db21472842c7a0010dc5dea') {
        log('patching app id');
        initConfig.appId = '5edf6aee27b3c2000f7d340e'; // eslint-disable-line
    }

    // patch fiat appid;
    if (initConfig.appId === '5db20a14f74afc001080e520') {
        log('patching app id');
        initConfig.appId = '651bea5161359388d0aebe7d'; // eslint-disable-line
    }

    window[TARGET_DOMAIN].initPromise = (async () => {
        try {
            // load errorHandling
            !ErrorHandler.load(initConfig.appId); // eslint-disable-line
            // this will load the default sunshine web-widget
            let smoochError;
            await SmoochLoader.load(window, document, `${TARGET_DOMAIN}Loader`, initConfig.appId).catch(e=>smoochError = e); // eslint-disable-line
            if (!smoochError) {
                await SettingsHandler.init({
                    Platform, initConfig
                });
                const { settings: mergedSettings } = SettingsHandler;

                initZendesk().catch(error => {
                    if (
                        (error.code && error.code === 'app_not_found') ||
                        (
                            error.message && (
                                error.message === 'Must provide an appId.' ||
                                error.message === 'Failed to fetch'
                            )
                        )
                    ) {
                        log('init: trying to swap region. (please update region in adv config editor)');
                        // maybe it is or is not running in eu-1?
                        if (mergedSettings.region) {
                            delete mergedSettings.region;
                        } else {
                            mergedSettings.region = 'eu-1';
                        }
                        // ok, lets try again
                        initZendesk().catch(error2 => {
                            if ((error2.code && error2.code === 'app_not_found') || (error2.message && error2.message === 'Must provide an appId.')) {
                                console.error(`init: could not load appid ${mergedSettings.appId}.` +
                                ` please contact ${TARGET_DOMAIN} at info@${TARGET_DOMAIN === 'web1on1' ? 'web1on1.chat' : 'chatshipper.com'}`);
                            } else {
                                log('init: rejecting error2', error2);
                                ErrorHandler.handleError(error2);
                                throw error2;
                            }
                        });

                    } else {
                        log('init: rejecting error', error);
                        ErrorHandler.handleError(error);
                        throw error;
                    }
                });

                if (mergedSettings.embedded) {
                    const target = document.getElementById(mergedSettings.embedded);
                    // The init().then() defined above will be fired after the render
                    window[`${TARGET_DOMAIN}Loader`].render(target);
                }
            }
        } catch (error) {
            log('init: rejecting error', error);
            ErrorHandler.handleError(error);
            throw error;
        }
        return null;
    })();

    return window[TARGET_DOMAIN].initPromise;
};
const { destroy } = DialogHandler;

export { init, destroy, getLanguage, Platform };
