/*global TARGET_DOMAIN*/
import { ErrorHandler } from "../helpers";
import DialogHandler from "./dialog-handler";
import SettingsHandler from "./settings-handler";
import { log } from "./utils";

let openByClass;
let openByIds;

class OpenByHandler {

    static addOpenByClassEvents() {
        openByClass = SettingsHandler.getSetting('openByClass');
        if (openByClass) {
            const classes = Object.keys(openByClass);
            classes.forEach(classname => {
                log(`addOpenByClassEvents: searching for ${classname}: .${classname.replace(/\s+/g, '.')}`);
                const items = Array.csFrom(document.querySelectorAll(`.${classname.replace(/\s+/g, '.')}`));
                log(`addOpenByClassEvents: found ${items.length} elements of ${classname}`);
                items.forEach(item => {
                    let preventDefault = false;
                    if (typeof openByClass[classname] === 'object') {
                        const alreadyPatched = item.getAttribute(`data-${TARGET_DOMAIN}-patched`);
                        if (!alreadyPatched) {
                            log(`addOpenByClassEvents: patching ${classname} item`, item);
                            item.setAttribute(`data-${TARGET_DOMAIN}-patched`, 'true');
                            item.removeAttribute('target');
                            Object.keys(openByClass[classname]).forEach(attr => { // eslint-disable-line
                                const found = item.getAttribute(attr);
                                if (!found && openByClass[classname][attr] !== '') {
                                    item.setAttribute(attr, openByClass[classname][attr]);
                                    if (attr === `data-${TARGET_DOMAIN}-preventdefault`) {
                                        if (openByClass[classname][attr] === true) {
                                            preventDefault = true;
                                        }
                                    }
                                }
                            });
                        } else {
                            log(`addOpenByClassEvents: already patched ${classname} item`, item);
                        }
                        const text = item.innerHTML;
                        // translate text if found in translation files
                        if (SettingsHandler.language[text]) {
                            const translatedText = SettingsHandler.language[text];
                            if (translatedText !== '') {
                                item.innerHTML = text; // eslint-disable-line
                            }
                        }
                        OpenByHandler.addOpenByClickEvent(
                            item,
                            preventDefault,
                            Array.csFrom(['true', true])
                                .includes(openByClass[classname][`data-${TARGET_DOMAIN}-startbot`])
                        );
                    } else {
                        log(`not adding click for class ${classname},` +
                            ' is already configured by openByIds');
                    }
                });
            });
        }
    }

    static addOpenByIdEvents() {
        log('addOpenByIdEvents');
        openByIds = SettingsHandler.getSetting('openByIds');
        if (openByIds) {
            let ids;
            if (typeof openByIds === 'string') {
                ids = openByIds.split(',').map(e => e.trim()).filter(e => e !== '');
            } else if (Array.isArray(openByIds)) {
                ids = openByIds.map(e => e.trim()).filter(e => e !== '');
            } else if (typeof openByIds === 'object') {
                ids = Object.keys(openByIds);
            }
            if (ids) {
                ids.forEach(id => {
                    const item = document.getElementById(id);
                    let preventDefault = false;
                    if (item) {
                        const text = item.innerHTML;
                        // some links have a target. remove it
                        item.removeAttribute('target');
                        // translate text if found in translation files
                        if (SettingsHandler.language[text]) {
                            const translatedText = SettingsHandler.language[text];
                            if (translatedText !== '') {
                                item.innerHTML = text;
                            }
                        }
                        if (typeof openByIds === 'object' && typeof openByIds[id] === 'object') {
                            const alreadyPatched = item.getAttribute(`data-${TARGET_DOMAIN}-patched`);
                            if (!alreadyPatched) {
                                item.setAttribute(`data-${TARGET_DOMAIN}-patched`, 'true');
                                Object.keys(openByIds[id]).forEach(attr => {
                                    const found = item.getAttribute(attr);
                                    if (!found && openByIds[id][attr] !== '') {
                                        item.setAttribute(attr, openByIds[id][attr]);
                                        item.setAttribute(`data-${TARGET_DOMAIN}-patched`, 'true');
                                        if (attr === `data-${TARGET_DOMAIN}-preventdefault`
                                            && openByIds[id][attr] === true) {
                                            preventDefault = true;
                                        }
                                    }
                                });
                            }
                        }
                        const start = Array.csFrom([true, 'true'])
                            .includes(openByIds[id][`data-${TARGET_DOMAIN}-startbot`]);
                        const options = {
                            domNode: item,
                            preventDefault,
                            label: 'widget_opened_by_id',
                            openById: id,
                            from: 'button'
                        };
                        if (start) {
                            DialogHandler.openWidget(options);
                        } else {
                            log('addOpenByIdEvents: adding click event');
                            item.addEventListener('click', DialogHandler.openWidget.bind(
                                this,
                                options
                            ));
                        }
                    }
                });
            }
        }
    }

    static addOpenByClickEvent(item, preventDefault, start) {
        const options = {
            domNode: item, label: 'widget_opened_by_class', from: 'button', preventDefault, openByClass
        };
        if (start) {
            DialogHandler.openWidget(options);
        } else {
            item.addEventListener(
                'click',
                DialogHandler.openWidget.bind(this, options)
            );
        }
    }

    static removeOpenByIdEvents() {
        try {
            if (openByIds) {
                let ids;
                if (typeof openByIds === 'string') {
                    ids = openByIds.split(',').map(e => e.trim()).filter(e => e !== '');
                } else if (Array.isArray(openByIds)) {
                    ids = openByIds.map(e => e.trim()).filter(e => e !== '');
                } else if (typeof openByIds === 'object') {
                    ids = Object.keys(openByIds);
                }
                if (ids) {
                    ids.forEach(id => {
                        const buttonOrLink = document.getElementById(id);
                        if (buttonOrLink && window[TARGET_DOMAIN] && window[TARGET_DOMAIN].api && window[TARGET_DOMAIN].api.showWidget) {
                            buttonOrLink.removeEventListener('click', window[TARGET_DOMAIN].api.showWidget);
                        }
                    });
                }
            }
        } catch (e) {
            ErrorHandler.handleError(e);
        }
    }

    static removeOpenByClassEvents() {
        try {
            if (openByClass) {
                const classes = Object.keys(openByClass);
                classes.forEach(classname => {
                    const items = Array.csFrom(document
                        .querySelectorAll(`.${classname.replace(/\s+/g, '.')}`));
                    items.forEach(item => {
                        if (window[TARGET_DOMAIN] && window[TARGET_DOMAIN].api && window[TARGET_DOMAIN].api.showWidget) {
                            item.removeEventListener('click', window[TARGET_DOMAIN].api.showWidget);
                        }
                    });
                });
            }
        } catch (e) {
            ErrorHandler.handleError(e);
        }
    }

}

export default OpenByHandler;
