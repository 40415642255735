/* eslint max-len:0 */
const Swedish = {
    _language: 'swedish',
    _language_short: 'sv',
    agentWelkomMessage: 'Välkommen. Ställ din fråga här.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Kunde inte ansluta. Du kan {retry}',
    couldNotConnectInner: 'försök att ansluta igen nu',
    couldNotConnectWithRetry: 'Kunde inte ansluta. Vi kommer att fortsätta försöka, eller så kan du {retry}.',
    couldNotConnectWithRetryInner: 'försök nu',
    headerText: 'Hur kan vi hjälpa dig?',
    imageClickToReload: 'Klicka för att ladda om bilden.',
    imageClickToView: 'Klicka för att visa bild {size}.',
    imagePreviewNotAvailable: 'Förhandsgranskning är inte tillgänglig.',
    inputPlaceholder: 'Skriv här och tryck på <enter> för att skicka.',
    inputPlaceholderBlocked: 'Fyll i formuläret nedan...',
    introAppText: 'Skicka ditt meddelande nedan eller från din favoritkanal.',
    introductionText: 'Ställ din fråga här.',
    actionPaymentCompleted: 'Betalningen har mottagits.',
    actionPaymentError: 'Ett fel uppstod vid bearbetning av betalningen. <br> Vänligen försök igen eller använd ett annat kort.',
    actionPostbackError: 'Ett fel uppstod vid bearbetning av din åtgärd. Vänligen försök igen.',
    clickToRetry: 'Meddelandet kunde inte levereras. Försök igen.',
    clickToRetryForm: 'Formuläret skickades inte. Klicka var som helst på formuläret för att försöka igen.',
    connectNotificationText: 'Vänta på svar eller välj vilken app du vill fortsätta med.',
    connectNotificationSingleText: 'Vänta på svar eller välj vilken app du vill fortsätta med.',
    connectNotificationSingleButtonText: 'Slå på aviseringar från <name>',
    connectNotificationOthersText: 'andra',
    emailChangeAddress: 'Ändra min e-postadress',
    emailDescription: 'För att få e-postmeddelanden om svar, ange din e-postadress.',
    emailFieldLabel: 'Din e-postadress',
    emailFieldPlaceholder: 'Din e-postadress',
    emailFormButton: 'Fortsätt',
    fetchHistory: 'Hämta mer',
    fetchingHistory: 'Laddar historik...',
    frontendEmailChannelDescription: 'Skicka oss ett e-postmeddelande, och vi kommer att svara så snart som möjligt.',
    invalidFileError: 'Endast bilder stöds. Välj en fil med ett stött format (jpg, jpeg, png, gif eller bmp).',
    lineChannelDescription: 'För att kommunicera med oss, skanna QR-koden med LINE-appen.',
    locationNotSupported: 'Din webbläsare stöder inte platsidentifieringstjänster eller så är de avstängda. Ange din plats manuellt.',
    locationSecurityRestriction: 'Denna webbplats har ingen åtkomst till din plats. Ange din plats manuellt.',
    locationSendingFailed: 'Platsen kunde inte skickas',
    locationServicesDenied: 'Denna webbplats har ingen åtkomst till din plats. Tillåt åtkomst i dina inställningar eller ange din plats manuellt.',
    messageError: 'Ett fel uppstod vid sändningen av ditt meddelande. Försök igen.',
    messageIndicatorTitlePlural: '({count}) Nya meddelanden',
    messageIndicatorTitleSingular: '({count}) Nytt meddelande',
    messageRelativeTimeDay: 'För {value} dagar sedan',
    messageRelativeTimeHour: 'För {value} timmar sedan',
    messageRelativeTimeJustNow: 'Just nu',
    messageRelativeTimeMinute: 'För {value} minuter sedan',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Skickar...',
    messageDelivered: 'Levererat',
    messengerChannelDescription: 'För att fortsätta med Facebook Messenger, anslut ditt konto till Facebook.',
    whatsappChannelDescriptionDesktop: 'Börja eller fortsätt din konversation via WhatsApp. Klicka på länken nedan.',
    whatsappChannelDescriptionMobile: 'Börja eller fortsätt din konversation via WhatsApp. Klicka på länken nedan.',
    whatsappLinkingError: 'Ett fel uppstod vid anslutningen. Försök igen.',
    notificationSettingsChannelsDescription: 'Du kan också chatta från din favoritapp eller tjänst.',
    notificationSettingsChannelsTitle: 'Andra appar',
    notificationSettingsConnected: 'Ansluten',
    notificationSettingsConnectedAs: 'Ansluten som {username}',
    prechatCaptureGreetingText: 'Hej 👋\nVi skulle vilja veta lite mer om dig för att komma igång:',
    prechatCaptureNameLabel: 'Ditt namn',
    prechatCaptureNamePlaceholder: 'Ange ditt namn...',
    prechatCaptureEmailLabel: 'E-post',
    prechatCaptureEmailPlaceholder: 'name@company.com',
    prechatCaptureConfirmationText: 'Tack! Hur kan vi hjälpa dig?',
    prechatCaptureMailgunLinkingConfirmation: 'Du kommer att bli informerad här och via e-post på {email} när vi svarar. ',
    sendButtonText: 'Skicka',
    settingsHeaderText: 'Inställningar',
    smsBadRequestError: 'Det gick inte att ansluta till detta nummer. Försök igen eller använd ett annat nummer. ',
    smsCancel: 'Avbryt',
    smsChangeNumber: 'Ändra mitt nummer',
    smsChannelDescription: 'För att fortsätta via SMS, anslut ditt SMS-nummer.',
    smsChannelPendingDescription: 'Kontrollera dina meddelanden på {number} för att bekräfta ditt telefonnummer.',
    smsContinue: 'Fortsätt',
    smsInvalidNumberError: 'Ditt telefonnummer är ogiltigt. Försök igen.',
    smsLinkCancelled: 'Anslutningen till {appUserNumber} avbröts.',
    smsLinkPending: 'Väntar',
    smsPingChannelError: 'Ett fel uppstod vid sändning av meddelande till ditt nummer.',
    smsSendText: 'Skicka mig ett SMS',
    smsStartTexting: 'Börja skicka SMS',
    smsTooManyRequestsError: 'En förfrågan om anslutning till detta nummer gjordes nyligen. Försök igen om {minutes} minuter.',
    smsTooManyRequestsOneMinuteError: 'En förfrågan om anslutning till detta nummer gjordes nyligen. Försök igen om 1 minut.',
    smsUnhandledError: 'Något gick fel. Försök igen.',
    tapToRetry: 'Meddelandet kunde inte levereras. Tryck på enter för att försöka igen.',
    tapToRetryForm: 'Formuläret skickades inte. Tryck på formuläret för att försöka igen.',
    telegramChannelDescription: 'För att fortsätta via Telegram, anslut ditt konto till Telegram.',
    unsupportedMessageType: 'Ej stödd meddelandetyp.',
    unsupportedActionType: 'Ej stödd aktionstyp.',
    linkError: 'Ett fel uppstod vid försöket att skapa en länk för denna kanal. Försök igen.',
    viberChannelDescription: 'För att fortsätta via Viber, anslut ditt Viber-konto.',
    viberChannelDescriptionMobile: 'För att fortsätta via Viber, anslut ditt Viber-konto och du kommer att meddelas när vi svarar. Installera Viber-appen och tryck på Anslut.',
    viberQRCodeError: 'Ett fel uppstod vid hämtning av din Viber QR-kod. Försök igen.',
    wechatChannelDescription: 'För att fortsätta via WeChat, anslut ditt WeChat-konto och du kommer att meddelas när vi svarar. Skanna QR-koden med WeChat-appen för att komma igång.',
    wechatChannelDescriptionMobile: 'För att fortsätta via WeChat, anslut ditt WeChat-konto och du kommer att meddelas när vi svarar. Spara QR-koden och ladda upp den i <a href="weixin://dl/scan">QR-kodsskannern</a>.',
    wechatQRCodeError: 'Ett fel uppstod vid hämtning av din WeChat QR-kod. Försök igen.',
    fileTooLargeError: 'Filen överstiger maximal storleksgräns ({size})',
    shareLocation: 'Dela plats',
    uploadDocument: 'Ladda upp dokument',
    uploadInvalidError: 'Ogiltig fil',
    fileTypeError: 'Ej stödd filtyp',
    formErrorInvalidEmail: 'E-postadressen är ogiltig',
    formErrorNoLongerThan: 'Får vara högst ({characters}) tecken',
    formErrorNoShorterThan: 'Måste vara minst ({characters}) tecken',
    formErrorUnknown: 'Det ser inte helt korrekt ut',
    formFieldSelectPlaceholderFallback: 'Välj...',
    uploadPhoto: 'Ladda upp foto',
    uploadVirusError: 'Ett virus har upptäckts i din fil och den har avvisats',
    shoutoutText: 'Starta chatt'
};
export { Swedish };
