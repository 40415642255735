import DialogHandler from "./dialog-handler";
import MessageHandler from "./message-handler";
import { log } from "./utils";

const PRECHATCAPTUREBOTRESETTIME = 30 * 60; // 30 mins
class PreChatHandler {
    constructor({
        getSetting, sunshine, Storage, language
    }) {
        this.conversation = DialogHandler.conversation;
        this.footer = DialogHandler.footer;
        this.getSetting = getSetting;
        this.sunshine = sunshine;
        this.Storage = Storage;
        this.language = language;
        this.fixPreChatCaptureLinks = this.fixPreChatCaptureLinks.bind(this);
        this.removeAbdundantPrechatCaptureCheckIcon = this.removeAbdundantPrechatCaptureCheckIcon.bind(this);
        this.sendToPrechatCaptureBotIfConfiguredAndReturningCustomer = this.sendToPrechatCaptureBotIfConfiguredAndReturningCustomer.bind(this);
        this.init = this.init.bind(this);
    }

    fixPreChatCaptureLinks() {
        const prechatMessages = Array.csFrom(this.conversation.querySelectorAll('[data-iscapture]'));
        prechatMessages.forEach(message => {
            const spans = Array.csFrom(message.getElementsByTagName('span'));
            if (spans.length) {
                const lastSpan = spans[spans.length - 1];
                const text = lastSpan.innerHTML; // last span has the text
                let modifiedText = text.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'); // eslint-disable-line
                if (text !== modifiedText) {
                    lastSpan.innerHTML = modifiedText;
                }
            }
        });
    }

    // prechatCapture with no selection yet has
    // green checkbox this should be removed
    removeAbdundantPrechatCaptureCheckIcon() {
        const prechatMessages = Array.csFrom(this.conversation.querySelectorAll('[data-iscapture]'));
        const textarea = Array.csFrom(this.footer.getElementsByTagName('textarea'))[0];
        if (textarea && textarea.hasAttribute('disabled') && prechatMessages.length) {
            const icons = Array.csFrom(this.conversation.getElementsByClassName('select-dropdown-icon--success'));
            if (icons.length) {
                icons[0].parentNode.removeChild(icons[0]);
            }
        }
    }

    // new prechat capture bot
    // it is enabled by enabling the bot in an org
    // the bot is triggered by sending >cs-meta=bot:... message
    // which is picked up in the lvl1 org (web1on1/polivalor/sandbox)
    // and assigned to prechatCaptureViaForms bot on lvl0
    // if enabled in the org of the widget it will push a form to contact
    // this form is either the default lvl0 form (name, email, question)
    // or a form configured in the org and set with formId in the bot instance.
    sendToPrechatCaptureBotIfConfiguredAndReturningCustomer() {
        const prechatCapture = this.getSetting('prechatCapture');
        const messages = Array.csFrom(this.sunshine.getConversation().messages);
        const now = Math.round(new Date().getTime() / 1000);
        if (prechatCapture && prechatCapture.enabled) {
            const lastUserMessage = messages.filter(m => m.role === 'appUser' &&
                Array.csFrom(['text', 'formResponse']).includes(m.type) &&
                (!m.text || !m.text.match(/^>cs/))).pop();
            log('lastUserMessage', lastUserMessage);
            const lastClientReturnedMessage = messages.filter(m => m.role === 'appUser' && m.type === 'text' && m.text === '>cs-meta=bot:60eec3476ace19001fefc84c').pop();
            if (lastUserMessage) {
                log('lastClientReturnedMessage', lastClientReturnedMessage, now - lastUserMessage.received, (now - lastUserMessage.received) > PRECHATCAPTUREBOTRESETTIME);
            }
            if (lastUserMessage && (now - lastUserMessage.received) > PRECHATCAPTUREBOTRESETTIME) {
                if (!lastClientReturnedMessage || lastUserMessage.received > lastClientReturnedMessage.received) {
                    log('customer returned on ', this.getSetting('url'));
                    MessageHandler.sendClientMessage('>cs-meta=bot:60eec3476ace19001fefc84c');
                }
            }
        }
    }

    init() {
        // check if prechatCapture is enabled
        // and prechatCapture has a bot configured
        // and the bot has spoken before
        // and last message of bot was longer then 30 minutes ago
        // then send bot restart command with meta=prechatreset:true
        const prechatCapture = this.getSetting('prechatCapture');
        const messages = Array.csFrom(this.sunshine.getConversation().messages);
        if (this.language.agentMessage && this.Storage.get('widget_show_first_message')) {
            MessageHandler.showFakeAgentMessage(this.language.agentMessage, null, { onTop: true, uniqueId: 'firstAgentMessage', noTime: true });
        }
        const now = Math.round(new Date().getTime() / 1000);
        if (prechatCapture && prechatCapture.enabled) {
            const bots = prechatCapture.fields.filter(f => f.name === 'bot');
            if (bots.length) {
                                for (const bot of bots) { //eslint-disable-line
                    if (bot.options && Array.isArray(bot.options) &&
                                        bot.options[0] && bot.options[0].name) {
                        const botName = bot.options[0].name.split('_').shift();
                        const lastBotMessage = messages.filter(m => m.role === 'appMaker' && m.name === botName).pop();
                        const lastUserMessage = messages.filter(m => m.role === 'appUser' && m.name === botName).pop();
                        if (lastBotMessage) {
                            if ((now - lastBotMessage.received) >
                                                PRECHATCAPTUREBOTRESETTIME) {
                                if (!lastUserMessage ||
                                                    // last user message can be a bot reset command. do not reset twice
                                                    (lastUserMessage && !lastUserMessage.text.match('reset'))) {
                                    log(`bot ${botName} inactive for 30 minutes, resetting bot`);
                                    MessageHandler.sendClientMessage(`>cs ${botName} reset meta=prechatreset:true`);
                                }
                                break; // only restart 1 bot
                            } else {
                                const inactive = Math.round((now
                                                    - lastBotMessage.received) / 60);
                                log(`bot ${botName} inactive for ${inactive} minutes,` +
                                                    `reset in ${PRECHATCAPTUREBOTRESETTIME / 60 - inactive} minutes`);
                            }
                        }
                    }
                }
            }
            this.sendToPrechatCaptureBotIfConfiguredAndReturningCustomer();
        }
    }
}

export default PreChatHandler;
