/*global TARGET_DOMAIN*/
import { Helpers } from "../helpers";
import DialogHandler from "./dialog-handler";
import SettingsHandler from "./settings-handler";
import { log, createElementFromHTML, after } from "./utils";

class ShoutoutHandler {

    // resize shoutout to bar width
    static resizeShoutout() {
        const shoutout = document.getElementById('web-messenger-shoutout');
        if (!shoutout || !DialogHandler.header || !DialogHandler.frame) return; // return when called to early
        const widget = DialogHandler.getWidget(); // eslint-disable-line
        const fr = DialogHandler.getWebmessengerFrame(); // eslint-disable-line
        if (Helpers.hasClass(fr, `${TARGET_DOMAIN}_appear`)) return;
        const isFullscreenBar = Helpers.hasClass(widget, 'widget-xs');
        const computerStyleShoutout = window.getComputedStyle(shoutout, null);
        const computerStyleHeader = window.getComputedStyle(DialogHandler.header, null);
        const computerStyleContainer = window.getComputedStyle(DialogHandler.container, null);
        const computerStyleFrame = window.getComputedStyle(fr, null);
        let right = parseInt(computerStyleFrame.right, 10) + parseInt(computerStyleContainer.paddingRight, 10);
        let height = parseInt(SettingsHandler.getSetting('barHeight', computerStyleFrame.height), 10);
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        let widthHeader = parseInt(computerStyleHeader.width, 10);
        const padding = parseInt(
            computerStyleShoutout.paddingLeft || computerStyleShoutout.paddingRight,
            10
        );
        if (isIE11) {
            widthHeader += 30;
        }
        if (isFullscreenBar) {
            widthHeader -= 65;
            height += 10;
            right += 15;
        }
        const border = parseInt(
            computerStyleShoutout.borderLeft || computerStyleShoutout.borderRight || 0,
            10
        );
        // eslint-disable-next-line
        const bottom = `bottom: ${height}px;`;
        const rightcss = `right: ${right}px;`;
        const width = widthHeader - padding * 2 - border * 2;
        // log(width, widthHeader, padding, border, right);
        // change to max-width if we want shout to grow until bar width
        shoutout.setAttribute('style', `width: ${width}px; ${rightcss} ${bottom}`);
        Helpers.removeClass(shoutout, 'hide');
        Helpers.addClass(shoutout, 'animated zoomIn faster');
    }

    // close shoutout when close icon is clicked
    static closeShoutout(e) {
        const shoutout = document.getElementById('web-messenger-shoutout');
        if (shoutout) {
            const remove = () => {
                if (shoutout.parentNode) shoutout.parentNode.removeChild(shoutout);
                if (DialogHandler.button) {
                    const number = DialogHandler.button.getElementsByClassName('cs-number')[0];
                    if (number) {
                        number.parentNode.removeChild(number);
                    }
                }
            };
            const close = shoutout.getElementsByClassName('cs-close')[0];
            if (e && e.target === close) {
                // remove with animation
                e.preventDefault();
                e.stopPropagation();
                shoutout.addEventListener('animationend', () => {
                    remove();
                });
                if (DialogHandler.button) {
                    const number = DialogHandler.button.getElementsByClassName('cs-number')[0];
                    Helpers.addClass(number, 'animated zoomOut');
                }
                Helpers.removeClass(shoutout, 'animated zoomIn faster');
                Helpers.addClass(shoutout, 'animated zoomOut faster');
                SettingsHandler.Storage.set('widget_shoutout_closed', true);
            } else {
                remove();
            }
            if (close) close.removeEventListener('click', ShoutoutHandler.closeShoutout);
            window.removeEventListener('resize', after.bind(this, 300, ShoutoutHandler.resizeShoutout)); // eslint-disable-line
            // shoutout.removeEventListener('click', closeShoutout);
        }
    }

    // create a nr of messages marker on top right of button
    static addMessageMarkToButton(nr) {
        if (DialogHandler.button && nr !== '-1') {
            if (!DialogHandler.button.getElementsByClassName('cs-number')[0] && !SettingsHandler.Storage.get('widget_shoutout_closed')) {
                const number = `
                    <div class="cs-number animated zoomIn">
                        <div class="cs-outercircle">
                            <div class="cs-innercircle">${nr}</div>
                        </div>
                    </div>`;
                DialogHandler.button.appendChild(createElementFromHTML(number));
            }
        }
    }

    // default button shoutout
    static openShoutout(force = false) {
        const isClosedByCustomer = !!SettingsHandler.Storage.get('widget_closed_by_customer');
        if (
            force
            || (!SettingsHandler.Storage.get('widget_shoutout_closed')
            && !Helpers.hasClass(DialogHandler.container, 'appear')
            && ((!SettingsHandler.getSetting('hide') && !(isClosedByCustomer && SettingsHandler.getSetting('rehide')))
            || (SettingsHandler.getSetting('hide') && !SettingsHandler.getSetting('rehide') && isClosedByCustomer)))) {
            // ^ explained: to hide or not to hide :)
            // or hide was true, the widget got opened by some trigger and
            // while rehide = false the customer closed the widget
            ShoutoutHandler.addMessageMarkToButton(SettingsHandler.getSetting('shoutoutNumber', '1'));

            const txt = SettingsHandler.getSetting('customText.shoutoutText');
            if (!txt) {
                log('shoutoutText text now found');
                return;
            }
            const theme = SettingsHandler.getSetting('theme', 'dark');
            const agent = SettingsHandler.getSetting('shoutoutAgent', 'female smiling-33');
            const buttonOrBar = DialogHandler.button ? 'smooch_button' : 'smooch_bar';
            const shoutoutHTML = `
                <div id="web-messenger-shoutout" class="hide cs-open cs-${theme} ${buttonOrBar} ${SettingsHandler.orientation.split(' ').map(o => `cs-${o}`).join(' ')}">
                ${txt}
                <div class="cs-agenticon ${agent.split(/\s|,/).map(cls => `cs-${cls}`).join(' ')}"></div>
                <div class="cs-close"></div>
            </div>`;
            const newElement = createElementFromHTML(shoutoutHTML);
            if (newElement) {
                document.body.appendChild(newElement);
            }
            setTimeout(() => {
                const shoutout = document.getElementById('web-messenger-shoutout');
                if (shoutout) {
                    if (!DialogHandler.button) {
                        after(100, ShoutoutHandler.resizeShoutout);
                        window.addEventListener('resize', ShoutoutHandler.closeShoutout);
                        window.addEventListener('resize', after.bind(this, 300, ShoutoutHandler.openShoutout, force));
                    } else {
                        Helpers.removeClass(shoutout, 'hide');
                    }
                    const close = shoutout.getElementsByClassName('cs-close')[0];
                    close.addEventListener('click', ShoutoutHandler.closeShoutout);
                    if (DialogHandler.button) DialogHandler.button.addEventListener('click', ShoutoutHandler.closeShoutout);
                    shoutout.addEventListener('click', DialogHandler.openWidget.bind(this, {}));// eslint-disable-line
                    // shoutout.addEventListener('click', closeShoutout);
                }
            }, 100);
        }
    }

}

export default ShoutoutHandler;
